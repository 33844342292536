<template>
  <Header />

  <main>
    <div class="main-content home-content becomme-merchant-content">
      <section class="main-banner-area">
        <div class="container">
          <div class="grid w-full m-0">
            <div class="col-12 md:col-6 lg:col-6 banner-left-column">
              <h1 class="section-title">Your Business Growth Starts here!</h1>
              <p class="section-desc">Now you can empower Your Customers with Unmatched Loyalty Rewards and grow your business!</p>

              <button-prime label="Register Now" class="join-wafa-btn" />
            </div>

            <div class="col-12 md:col-6 lg:col-6 banner-right-column">
              <img src="../assets/images/banner-img.png" />
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding app-info-area-section">
        <div class="container">
          <div class="grid w-full m-0">
            <div class="col-12 md:col-4 lg:col-4 app-info-column">
              <h2>10+</h2>
              <p>Registered Merchants in UAE offering variety of offers and rewards</p>
            </div>

            <div class="col-12 md:col-4 lg:col-4 app-info-column">
              <h2>2000+</h2>
              <p>UAE residents and non-UAE residents loyal Customers</p>
            </div>

            <div class="col-12 md:col-4 lg:col-4 app-info-column">
              <h2>100+</h2>
              <p>Successfully managed programs and offers</p>
            </div>
          </div>
        </div> 
      </section>

      <section class="section-padding">
        <div class="container">
            <h1 class="section-title">
              <img src="../assets/images/wafa-logo-icon.svg">
              About Loyalty Program App
            </h1>

            <div class="grid w-full m-0 loyalty-program-app-inner-grid">
              <div class="col-12 md:col-8 lg:col-8">
                <div class="col-12 md:col-12 lg:col-12 p-0">
                  <div class="grid m-0 p-0">
                    <div class="col-12 md:col-6 lg:col-6 p-0 info-box-small-left">
                      <div class="info-box-column">
                        <h4 class="info-box-title">
                          Helps grow business <br /> digitally
                        </h4>
                        <p class="info-box-description">
                          Attracts and encourages customers to make more frequent purchases by Offering rewards and offers
                        </p>
                      </div>
                    </div>

                    <div class="col-12 md:col-6 lg:col-6 p-0 info-box-small-right">
                      <div class="info-box-column">
                        <h4 class="info-box-title">
                          Increased Customer <br /> Retention
                        </h4>
                        <p class="info-box-description">
                          Loyalty programs incentivize customers to return to the merchant's store or service repeatedly.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 md:col-12 lg:col-12 p-0 info-box-h">
                  <div class="info-box-column">
                    <div class="info-box-column-inner">
                      <h4 class="info-box-title">
                        Cost Effective <br /> Marketing
                      </h4>
                      <p class="info-box-description">
                        Instead of spending large sums on traditional advertising, merchants can use their loyalty program to promote their brand
                      </p>
                    </div>

                    <button-prime label="Join Wafa" class="join-wafa-btn" icon="pi pi-arrow-up-right" iconPos="right" />
                  </div>
                </div>
              </div>

              <div class="col-12 md:col-4 lg:col-4 info-box-v">
                <div class="info-box-column">
                  <h4 class="info-box-title">
                    Data Collection and Analysis
                  </h4>
                  <p class="info-box-description">
                    Digital loyalty programs can collect valuable data on customer preferences and purchasing. Merchants can use this data to tailor their marketing strategies, improve their products, and offer personalized experiences to their customers.
                  </p>
                </div>
              </div>
            </div>
        </div>
      </section>

      <section class="section-padding key-features-section pt-2">
        <div class="container">
          <h1 class="section-title">
            Wafa Key Features
            <img src="../assets/images/wafa-logo-icon.svg">
          </h1>
            <div class="grid w-full m-0 mt-5">
              <swiper :slides-per-view="3" :space-between="0" setWrapperSize="ture" :navigation="false" :loop="true"
                  :pagination="true" :modules="modules" :autoplay="{
                    delay: 3500,
                    disableOnInteraction: false,
                  }" :breakpoints="{
                          '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                          '@0.75': { slidesPerView: 1, spaceBetween: 0 },
                          '@1.00': { slidesPerView: 2, spaceBetween: 0 },
                          '@1.50': { slidesPerView: 3, spaceBetween: 0 },
                  }" @swiper="onSwiper" @slideChange="onSlideChange">
                  <swiper-slide>
                    <div class="col-12">
                      <div class="info-box-column slider-feature-column">
                        <div>
                          <div class="icon-box">
                            <img src="../assets/images/key-feature-icon.svg">
                          </div>
                          <h4 class="info-box-title">Analytical Dashboard</h4>
                          <p class="info-box-description">
                            Analytical Dashboard to display recent rewards claim activities, your sales, your loyal customers, your new customers, and filtered results of your loyalty program sales based on dates
                          </p>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>

                  <swiper-slide>
                    <div class="col-12">
                      <div class="info-box-column slider-feature-column">
                        <div>
                          <div class="icon-box">
                            <img src="../assets/images/key-feature-icon1.svg">
                          </div>
                          <h4 class="info-box-title">Customers Data Platform</h4>
                          <p class="info-box-description">
                            At Wafa you can get a complete comprehensive platform to view customer’s recent activity, their purchases from your program and their current profile status 
                          </p>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>

                  <swiper-slide>
                    <div class="col-12">
                      <div class="info-box-column slider-feature-column">
                        <div>
                          <div class="icon-box">
                            <img src="../assets/images/key-feature-icon2.svg">
                          </div>
                          <h4 class="info-box-title">Customizable Rewards and Offers</h4>
                          <p class="info-box-description">
                            At Wafa you get the opportunity to customize rewards and offers according to the need of your business. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>

                  <swiper-slide>
                    <div class="col-12">
                      <div class="info-box-column slider-feature-column">
                        <div>
                          <div class="icon-box">
                            <img src="../assets/images/key-feature-icon.svg">
                          </div>
                          <h4 class="info-box-title">Analytical Dashboard</h4>
                          <p class="info-box-description">
                            Analytical Dashboard to display recent rewards claim activities, your sales, your loyal customers, your new customers, and filtered results of your loyalty program sales based on dates
                          </p>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
              </swiper>
            </div>
        </div>
      </section>

      <section class="section-padding how-it-works-section">
        <div class="container-fluid p-0">
          <h1 class="section-title">How it works</h1>
            <div class="grid w-full m-0">
              <swiper :slides-per-view="1" :space-between="0" setWrapperSize="ture" :navigation="false"  :loop="true"
                  :pagination="true" :effect="'fade'" :modules="modules" :autoplay="{
                    delay: 3500,
                    disableOnInteraction: false,
                  }" 
                  :breakpoints="{
                          '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                          '@0.75': { slidesPerView: 1, spaceBetween: 0 },
                          '@1.00': { slidesPerView: 1, spaceBetween: 0 },
                          '@1.50': { slidesPerView: 1, spaceBetween: 0 },
                  }" @swiper="onSwiper" @slideChange="onSlideChange">
                  <swiper-slide>
                    <div class="grid w-full m-0">
                      <div class="col-12 md:col-6 lg:col-6 how-it-works-column-info">
                        <div class="info-box-column how-works-column">
                            <h4 class="info-box-title">Register in Minutes</h4>
                            <p class="info-box-description">
                              If you have a new small business and seeking to become a merchant at some online platform, Wafa is the best choice for you. 
                            </p>

                            <ul>
                              <li>
                                <div class="icon">
                                  <img src="../assets/images/wafa-logo-icon.svg">
                                </div>
                                <p>Follow simple steps</p>
                              </li>
                              <li>
                                <div class="icon">
                                  <img src="../assets/images/wafa-logo-icon.svg">
                                </div>
                                <p>Provide required information</p>
                              </li>
                              <li>
                                <div class="icon">
                                  <img src="../assets/images/wafa-logo-icon.svg">
                                </div>
                                <p>Get registered successfully</p>
                              </li>
                            </ul>

                            <button-prime label="Register Now" class="join-wafa-btn" icon="pi pi-arrow-up-right" iconPos="right" />
                        </div>
                      </div>

                      <div class="col-12 md:col-6 lg:col-6 p-0 how-it-works-column-info">
                        <div class="image-box-area how-it-works-image1">
                          <img src="../assets/images/register.png">
                        </div>
                      </div>
                    </div>
                  </swiper-slide>

                  <swiper-slide>
                    <div class="grid w-full">
                      <div class="col-12 md:col-6 lg:col-6 how-it-works-column-info">
                        <div class="info-box-column how-works-column">
                            <h4 class="info-box-title">Buy a Plan with 3 Months free trial</h4>
                            <p class="info-box-description">
                              You can either buy a plan that suits or needs or either benefit from Wafa freemium package which can fulfill your needs as a new merchant.
                            </p>

                            <ul>
                              <li>
                                <div class="icon">
                                  <img src="../assets/images/wafa-logo-icon.svg">
                                </div>
                                <p>Offer multiple Rewards</p>
                              </li>
                              <li>
                                <div class="icon">
                                  <img src="../assets/images/wafa-logo-icon.svg">
                                </div>
                                <p>Offer exciting offers</p>
                              </li>
                              <li>
                                <div class="icon">
                                  <img src="../assets/images/wafa-logo-icon.svg">
                                </div>
                                <p>Get benefits from customers 
                                  analytics</p>
                              </li>
                            </ul>

                            <button-prime label="Register Now" class="join-wafa-btn" icon="pi pi-arrow-up-right" iconPos="right" />
                        </div>
                      </div>

                      <div class="col-12 md:col-6 lg:col-6 p-0 how-it-works-column-info">
                        <div class="image-box-area how-it-works-image2">
                          <img src="../assets/images/subscription.png">
                        </div>
                      </div>
                    </div>
                  </swiper-slide>

                  <swiper-slide>
                    <div class="grid w-full">
                      <div class="col-12 md:col-6 lg:col-6 how-it-works-column-info">
                        <div class="info-box-column how-works-column">
                            <h4 class="info-box-title">Add new Programs and Rewards</h4>
                            <p class="info-box-description">
                              Add new exciting programs and rewards and attract new customers to benefit your business.
                            </p>

                            <ul>
                              <li>
                                <div class="icon">
                                  <img src="../assets/images/wafa-logo-icon.svg">
                                </div>
                                <p>Add a new program</p>
                              </li>
                              <li>
                                <div class="icon">
                                  <img src="../assets/images/wafa-logo-icon.svg">
                                </div>
                                <p>Add multiple rewards under that program</p>
                              </li>
                            </ul>

                            <button-prime label="Register Now" class="join-wafa-btn" icon="pi pi-arrow-up-right" iconPos="right" />
                        </div>
                      </div>

                      <div class="col-12 md:col-6 lg:col-6 p-0 how-it-works-column-info">
                        <div class="image-box-area how-it-works-image3">
                          <img src="../assets/images/add-programs.png">
                        </div>
                      </div>
                    </div>
                  </swiper-slide>

                  <swiper-slide>
                    <div class="grid w-full">
                      <div class="col-12 md:col-6 lg:col-6 how-it-works-column-info">
                        <div class="info-box-column how-works-column">
                            <h4 class="info-box-title">Scan and Reward</h4>
                            <p class="info-box-description">
                              Scan customer’s QR code or enter code to reward them with points or stamps and to redeem any reward or offer
                            </p>

                            <ul>
                              <li>
                                <div class="icon">
                                  <img src="../assets/images/wafa-logo-icon.svg">
                                </div>
                                <p>Get Customer’s code</p>
                              </li>
                              <li>
                                <div class="icon">
                                  <img src="../assets/images/wafa-logo-icon.svg">
                                </div>
                                <p>Reward stamps or points</p>
                              </li>
                              <li>
                                <div class="icon">
                                  <img src="../assets/images/wafa-logo-icon.svg">
                                </div>
                                <p>Redeem offer or reward</p>
                              </li>
                            </ul>

                            <button-prime label="Register Now" class="join-wafa-btn" icon="pi pi-arrow-up-right" iconPos="right" />
                        </div>
                      </div>

                      <div class="col-12 md:col-6 lg:col-6 p-0 how-it-works-column-info">
                        <div class="image-box-area how-it-works-image4">
                          <img src="../assets/images/mobile-group.png">
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
              </swiper>
            </div>
        </div>
      </section>

      <section class="section-padding why-join-wafa">
        <div class="container">
          <h1 class="section-title">
            Why Join Wafa
            <img src="../assets/images/wafa-logo-icon.svg">
          </h1>

          <div class="col-12 md:col-12 lg:col-12 why-join-bg-img">
            <img src="../assets/images/why-join-wafa-bg.png">
          </div>

          <div class="col-12 p-0">
            <swiper :slides-per-view="3" :space-between="0" setWrapperSize="ture" :navigation="false"  :loop="true"
                :pagination="pagination" :modules="modules" :autoplay="{
                  delay: 3500,
                  disableOnInteraction: false,
                }" :breakpoints="{
                        '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                        '@0.75': { slidesPerView: 1, spaceBetween: 0 },
                        '@1.00': { slidesPerView: 2, spaceBetween: 0 },
                        '@1.50': { slidesPerView: 3, spaceBetween: 0 },
                }" @swiper="onSwiper" @slideChange="onSlideChange">
                <swiper-slide>
                  <div class="col-12">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">Customizable Reward Options</h4>
                        <p class="info-box-description">
                          Merchants can add, delete or edit any reward, based on their business modal, their need and their customers preferences
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">Analytical Data Platform</h4>
                        <p class="info-box-description">
                          Merchants can get detailed analytics on their reward programs, discounts given and rewards or offers redeemed by their customers
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">Attract more customers</h4>
                        <p class="info-box-description">
                          Special promotions and rewards can drive more customers to a merchant's physical store or website which will ultimately benefit merchant.
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">Increased Loyal Customers</h4>
                        <p class="info-box-description">
                          Consistent rewards and personalized offers can transform satisfied customers into loyal customers which will visit the merchant often again, benefiting merchant.
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>

      <section class="section-padding top-rated-merchants">
        <div class="container">
          <h1 class="section-title">Here are some of our Top <br /> Rated Merchants</h1>
          <div class="grid w-full m-0">
            <swiper :slides-per-view="4" :space-between="0" setWrapperSize="ture" :navigation="false" :loop="true"
                  :pagination="true" :modules="modules" :autoplay="{
                    delay: 3500,
                    disableOnInteraction: false,
                  }" :breakpoints="{
                          '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                          '@0.75': { slidesPerView: 1, spaceBetween: 0 },
                          '@1.00': { slidesPerView: 2, spaceBetween: 0 },
                          '@1.50': { slidesPerView: 4, spaceBetween: 0 },
                  }" @swiper="onSwiper" @slideChange="onSlideChange">
                  <swiper-slide>
                    <div class="col-12">
                      <div class="info-box-column slider-feature-column">
                        <div>
                          <div class="icon-box">
                            <img src="../assets/images/azkamaza.png">
                          </div>
                          <h4 class="info-box-title">Azka Mazza</h4>
                          <button-prime label="5000 Loyal Customers" class="wafa-green-btn" />
                        </div>
                      </div>
                    </div>
                  </swiper-slide>

                  <swiper-slide>
                    <div class="col-12">
                      <div class="info-box-column slider-feature-column">
                        <div>
                          <div class="icon-box">
                            <img src="../assets/images/azkamaza.png">
                          </div>
                          <h4 class="info-box-title">Azka Mazza</h4>
                          <button-prime label="5000 Loyal Customers" class="wafa-green-btn" />
                        </div>
                      </div>
                    </div>
                  </swiper-slide>

                  <swiper-slide>
                    <div class="col-12">
                      <div class="info-box-column slider-feature-column">
                        <div>
                          <div class="icon-box">
                            <img src="../assets/images/fitness.png">
                          </div>
                          <h4 class="info-box-title">Fitness and Co</h4>
                          <button-prime label="5000 Loyal Customers" class="wafa-green-btn" />
                        </div>
                      </div>
                    </div>
                  </swiper-slide>

                  <swiper-slide>
                    <div class="col-12">
                      <div class="info-box-column slider-feature-column">
                        <div>
                          <div class="icon-box">
                            <img src="../assets/images/fitness.png">
                          </div>
                          <h4 class="info-box-title">Fitness and Co</h4>
                          <button-prime label="5000 Loyal Customers" class="wafa-green-btn" />
                        </div>
                      </div>
                    </div>
                  </swiper-slide>

                  <swiper-slide>
                    <div class="col-12">
                      <div class="info-box-column slider-feature-column">
                        <div>
                          <div class="icon-box">
                            <img src="../assets/images/fitness.png">
                          </div>
                          <h4 class="info-box-title">Fitness and Co</h4>
                          <button-prime label="5000 Loyal Customers" class="wafa-green-btn" />
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
              </swiper>
          </div>
        </div>
      </section>

      <section class="section-padding start-today-section">
        <div class="container">
          <h1 class="section-title">Start Today!</h1>
          <p class="section-description">Grow your Business</p>
          <button-prime label="Apply Now" class="join-wafa-btn" />
          <p class="action-download-text">Download our Customer Mobile App <a href="/">here</a></p>
        </div>
      </section>
    </div>
  </main>

  <Footer />
</template>

<script>
import Footer from './Footer/FooterView.vue'
import Header from './Header/HeaderView.vue'

// import Swiper core and required modules
import { EffectFade, Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    components: {
      Swiper,
      SwiperSlide,
      Footer,
      Header
    },
    data() {
      return {
        modules: [EffectFade, Navigation, Pagination, Autoplay, Scrollbar, A11y],
        pagination: {
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        },
      }
    },
    methods : {
      
    }
}
</script>

<script setup>
// import { ref } from "vue";
</script>

<style scoped src="../assets/css/home.css">

</style>