<template>
    <div class="main-sidebar">
        <div class="navbar-header">
            <ul class="nav navbar-nav flex-row">
                <li class="nav-item">
                    <a class="navbar-brand" href="/dashboard">
                        <span class="brand-logo">
                            <img class="site-logo-spacedesign" src="../../../assets/images/logo/sidebar-logo.svg">
                        </span>
                    </a>
                </li>
            </ul>
        </div>

        <div class="main-menu">
            <ul class="navigation navigation-main mt-0">
                <li class="active">
                    <router-link to="/dashboard" target="_self">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.08301 12.7124C2.08301 10.3287 2.08301 9.13679 2.62384 8.14873C3.16467 7.16068 4.15275 6.54745 6.12887 5.32101L8.21221 4.02803C10.3011 2.73159 11.3456 2.08337 12.4997 2.08337C13.6537 2.08337 14.6982 2.73159 16.7872 4.02803L18.8705 5.321C20.8467 6.54745 21.8347 7.16068 22.3755 8.14873C22.9163 9.13679 22.9163 10.3287 22.9163 12.7124V14.2969C22.9163 18.3603 22.9163 20.392 21.6959 21.6543C20.4756 22.9167 18.5113 22.9167 14.583 22.9167H10.4163C6.48797 22.9167 4.52379 22.9167 3.30339 21.6543C2.08301 20.392 2.08301 18.3603 2.08301 14.2969V12.7124Z" stroke="#8A8D90" stroke-width="2"/>
                        <path d="M9.375 16.6666C10.2608 17.3232 11.3381 17.7083 12.5 17.7083C13.6619 17.7083 14.7392 17.3232 15.625 16.6666" stroke="#8A8D90" stroke-width="2" stroke-linecap="round"/>
                        </svg>

                        <span class="menu-title">Dashboard</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/programs-rewards" target="_self" :class="{ 'router-link-active' : isProgramsRewardsActive }">
                        <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.6562 11.388C23.6562 11.388 24.4375 13.9677 24.4375 18.2854C24.4375 22.603 23.8299 26.3809 23.2222 26.9206C22.6146 27.4603 18.3611 28 13.5 28C8.63889 28 4.38542 27.4603 3.77778 26.9206C3.17014 26.3809 2.5625 22.603 2.5625 18.2854C2.5625 13.9677 3.34375 11.388 3.34375 11.388M23.6562 11.388C24.1365 11.363 24.4655 11.3368 24.6111 11.3107C25.3056 11.1862 26 10.3143 26 9.31797C26 8.32159 25.3056 7.44977 24.6111 7.32522C23.9167 7.20067 19.0556 7.07613 13.5 7.07613C7.94445 7.07613 3.08333 7.20067 2.38889 7.32522C1.69444 7.44977 1 8.32159 1 9.31797C1 10.3143 1.69444 11.1862 2.38889 11.3107C2.53455 11.3368 2.86351 11.363 3.34375 11.388M23.6562 11.388C21.8469 11.482 17.8903 11.5598 13.5 11.5598C9.10971 11.5598 5.1531 11.482 3.34375 11.388M13.2905 6.8519C14.1485 3.78968 19.0657 -1.51483 21.4095 2.36821C23.7534 6.25144 16.4922 7.67266 13.2905 6.8519ZM13.5974 6.8519C12.7394 3.78968 7.82217 -1.51483 5.47839 2.36821C3.13448 6.25144 10.3956 7.67266 13.5974 6.8519Z" stroke="#8A8D90" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <span class="menu-title">Programs</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/offers" target="_self" :class="{ 'router-link-active' : isOffersActive }" >
                        <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.4196 20.9087C16.8545 21.7371 18.6893 21.2455 19.5177 19.8106L24.1816 11.7325C25.01 10.2977 24.5184 8.4629 23.0835 7.63447L12.3773 1.45326C11.6883 1.05543 10.8694 0.947628 10.1009 1.15356L3.7299 2.86065C2.12951 3.28948 1.17976 4.93449 1.60858 6.53489L3.31568 12.9059C3.52161 13.6744 4.02441 14.3297 4.71346 14.7275L15.4196 20.9087Z" stroke="#8A8D90" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.894 10.0237C11.1885 11.2455 9.62615 11.6642 8.40429 10.9587C7.18244 10.2533 6.7638 8.69091 7.46924 7.46905C8.17468 6.24719 9.73706 5.82855 10.9589 6.53399C12.1808 7.23943 12.5994 8.80181 11.894 10.0237Z" stroke="#8A8D90" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        
                        <span class="menu-title">Offers</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/reports" target="_self" :class="{ 'router-link-active' : isReportsActive }">
                        <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.90903 0.465206C1.22063 0.625027 0.554222 1.1867 0.22373 1.87509L0.0199533 2.30462L0.0034016 13.5408C-0.00772335 21.0646 0.00882844 24.8649 0.0473588 25.0413C0.20148 25.7682 0.746874 26.4349 1.46837 26.7763L1.89247 26.98L10.7327 26.9966C16.6317 27.0077 19.6609 26.9912 19.8373 26.9526C20.724 26.7654 21.5391 25.9612 21.7432 25.0744C21.7817 24.9146 21.8037 22.2267 21.8037 17.4514C21.8037 10.3518 21.7983 10.0598 21.699 9.8506C21.5668 9.57519 12.545 0.547695 12.3136 0.45978C12.1206 0.388145 2.21211 0.393574 1.90903 0.465206ZM10.5124 5.6261C10.5124 9.51468 10.5178 9.56433 10.8264 10.0986C11.0687 10.5116 11.5147 10.9083 11.9831 11.134L12.4126 11.3378L16.2846 11.3544L20.1512 11.3709V18.14C20.1512 23.2735 20.1347 24.9423 20.085 25.0524C19.9418 25.3718 20.4872 25.3553 10.898 25.3553C1.30882 25.3553 1.85394 25.3718 1.71095 25.0524C1.60621 24.8321 1.61733 2.52522 1.72207 2.338C1.88189 2.05146 1.75517 2.06259 6.28819 2.05716H10.5127V5.6261H10.5124ZM15.9653 9.70191C12.297 9.71303 12.3355 9.71846 12.22 9.40452C12.1869 9.32176 12.1649 7.90645 12.1649 5.89609V2.52522L15.7449 6.10529L19.325 9.68535L15.9653 9.70191Z" fill="#8A8D90"/>
                            <path d="M5.44434 14.8901V15.7163H10.8972H16.35V14.8901V14.0638H10.8972H5.44434V14.8901Z" fill="#8A8D90"/>
                            <path d="M5.44434 18.4702V19.2964H10.8972H16.35V18.4702V17.6439H10.8972H5.44434V18.4702Z" fill="#8A8D90"/>
                            <path d="M5.44434 21.9404V22.7666H8.03319H10.6218V21.9404V21.1141H8.03319H5.44434V21.9404Z" fill="#8A8D90"/>
                        </svg>

                        <span class="menu-title">Reports</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/user-activity" target="_self">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.37467 10.4167C11.6759 10.4167 13.5413 8.55123 13.5413 6.25004C13.5413 3.94885 11.6759 2.08337 9.37467 2.08337C7.07349 2.08337 5.20801 3.94885 5.20801 6.25004C5.20801 8.55123 7.07349 10.4167 9.37467 10.4167Z" stroke="#8A8D90" stroke-width="2"/>
                            <path d="M15.625 9.375C17.3509 9.375 18.75 7.97589 18.75 6.25C18.75 4.52411 17.3509 3.125 15.625 3.125" stroke="#8A8D90" stroke-width="2" stroke-linecap="round"/>
                            <path d="M9.37467 21.875C13.4018 21.875 16.6663 20.0095 16.6663 17.7083C16.6663 15.4071 13.4018 13.5416 9.37467 13.5416C5.3476 13.5416 2.08301 15.4071 2.08301 17.7083C2.08301 20.0095 5.3476 21.875 9.37467 21.875Z" stroke="#8A8D90" stroke-width="2"/>
                            <path d="M18.75 14.5834C20.5773 14.9841 21.875 15.9989 21.875 17.1875C21.875 18.2597 20.8191 19.1906 19.2708 19.6567" stroke="#8A8D90" stroke-width="2" stroke-linecap="round"/>
                        </svg>

                        <span class="menu-title">Users</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/settings" target="_self">
                        <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1111 1H14.8889C15.6559 1 16.2778 1.6467 16.2778 2.44444V3.26603C16.2778 3.88396 16.6765 4.4208 17.2253 4.65774C17.7743 4.89477 18.4134 4.80444 18.8337 4.36733L19.3925 3.7862C19.9349 3.22211 20.8143 3.22211 21.3567 3.7862L23.3209 5.82895C23.8633 6.39305 23.8633 7.30762 23.3209 7.87171L22.7622 8.45275C22.3418 8.88989 22.255 9.55468 22.4829 10.1256C22.7108 10.6964 23.227 11.1111 23.8212 11.1111L24.6111 11.1111C25.3782 11.1111 26 11.7578 26 12.5556V15.4444C26 16.2422 25.3782 16.8889 24.6111 16.8889H23.8212C23.227 16.8889 22.7108 17.3036 22.4829 17.8743C22.255 18.4453 22.3419 19.11 22.7622 19.5472L23.3209 20.1282C23.8633 20.6923 23.8633 21.6069 23.3209 22.171L21.3567 24.2137C20.8143 24.7778 19.9349 24.7778 19.3925 24.2137L18.8338 23.6327C18.4135 23.1955 17.7743 23.1052 17.2253 23.3422C16.6766 23.5792 16.2778 24.1161 16.2778 24.734V25.5556C16.2778 26.3533 15.6559 27 14.8889 27H12.1111C11.344 27 10.7222 26.3533 10.7222 25.5556V24.734C10.7222 24.1161 10.3234 23.5792 9.77464 23.3422C9.22565 23.1052 8.58643 23.1955 8.16611 23.6327L7.60739 24.2137C7.06499 24.7778 6.1856 24.7778 5.6432 24.2137L3.67902 22.171C3.13662 21.6069 3.13662 20.6923 3.67902 20.1282L4.23782 19.5471C4.65811 19.11 4.74498 18.4452 4.51706 17.8743C4.28923 17.3036 3.77304 16.8889 3.17887 16.8889H2.38889C1.62183 16.8889 1 16.2422 1 15.4444V12.5556C1 11.7578 1.62183 11.1111 2.38889 11.1111L3.17884 11.1111C3.77303 11.1111 4.28924 10.6964 4.51707 10.1257C4.745 9.55473 4.65813 8.88997 4.23782 8.45284L3.67904 7.87171C3.13664 7.30762 3.13665 6.39305 3.67904 5.82896L5.64323 3.78621C6.18562 3.22212 7.06502 3.22212 7.60741 3.78621L8.16619 4.36734C8.5865 4.80446 9.22569 4.8948 9.77467 4.65776C10.3234 4.42081 10.7222 3.88394 10.7222 3.26599V2.44444C10.7222 1.6467 11.344 1 12.1111 1Z" stroke="#8A8D90" stroke-width="2"/>
                            <path d="M16.2778 14C16.2778 15.5955 15.0341 16.8889 13.5 16.8889C11.9659 16.8889 10.7222 15.5955 10.7222 14C10.7222 12.4045 11.9659 11.1111 13.5 11.1111C15.0341 11.1111 16.2778 12.4045 16.2778 14Z" stroke="#8A8D90" stroke-width="2"/>
                        </svg>

                        <span class="menu-title">Settings</span>
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="main-menu logout-menu">
            <ul class="navigation navigation-main mt-0">
                <li class="">
                    <router-link to="/signin" target="_self">
                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.1176 24H3.82353C2.26414 24 1 22.7129 1 21.125V3.875C1 2.28718 2.26414 1 3.82353 1H15.1176M9.47059 12.5H25M25 12.5L20.7647 16.8125M25 12.5L20.7647 8.1875" stroke="#F7F7F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <span class="menu-title text-white">Log Out</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      isOffersActive: false,
      isProgramsRewardsActive: false,
      isReportsActive: false,
    };
  },
  watch: {
    $route(to) {
      this.setActiveMenu(to.path);
    },
  },
  mounted() {
    this.setActiveMenu(this.$route.path); 
  },
  methods: {
    setActiveMenu(path) {
        
      this.isOffersActive = path === '/offers' || path === '/offers/add' || this.isEditOffer(path);
      this.isProgramsRewardsActive = path === '/programs-rewards' || this.isAddProgramReward(path) || this.isEditProgramReward(path);
      this.isReportsActive = path === '/reports' || path === '/reward-analysis-report' || path === '/offers-analysis-report' || path === '/user-activity-analysis';
      
    },
    isEditOffer(path) {
      return /^\/offers\/edit\/\d+$/.test(path);
    },
    isAddProgramReward(path) {
      return /^\/add-program-reward\/\d+$/.test(path);
    },
    isEditProgramReward(path) {
        return /^\/edit-program-reward\/\d+$/.test(path);
    },
  },
};
</script>