<template>

  <sidebarNav />

  <div class="page-header">
    <div class="header-left-area">
      <h1 class="page-title m-0">Offers</h1>
    </div>
    
    <div class="header-right-area">
        <router-link to="offers/add">
            <button-prime class="new-program" label="New Offer" icon="pi pi-plus" />
        </router-link>
    </div>
  </div>

  <div class="app-content content">
    <div class="container-fuild listing-container offer-listing-container" v-if="showOfferlistingScreens == 0">
      <div class="offer-listing-inner mt-1">
          <Tabs-prime value="0">
              <TabList-prime>
                  <Tab-prime value="0" v-if="!offers">All offers (0)</Tab-prime>
                  <Tab-prime value="0" v-else>All offers ({{ offers.length }})</Tab-prime>
                  <Tab-prime value="1" v-if="!drafted_offers">Drafts (0)</Tab-prime>
                  <Tab-prime value="1" v-else>Drafts ({{ drafted_offers.length }})</Tab-prime>
                  <Tab-prime value="2" v-if="!expired_offers">Expired (0)</Tab-prime>
                  <Tab-prime value="2" v-else>Expired ({{ expired_offers.length }})</Tab-prime>

                  <span class="offer-header-filters">
                      <button-prime class="offer-filter-btn" label="Filter by" icon="pi pi-sliders-h" />
                      <button-prime class="offer-filter-btn" label="Sort by" icon="pi pi-sort-amount-down-alt" />
                  </span>
              </TabList-prime>

              <TabPanels-prime>
                  <TabPanel-prime value="0">
                    <div class="listing-item-container listing-item-all-container" v-if="!offers">
                      <div class="listing-box-loaders" v-for="index in 3" :key="index">
                            <Skeleton-prime width="100%" height="180px"></Skeleton-prime>
                            <div class="flex justify-between mt-3 mb-3">
                                <Skeleton-prime class="border-1000 mr-2" width="6rem" height="2rem"></Skeleton-prime>
                                <Skeleton-prime class="border-1000" width="6rem" height="2rem"></Skeleton-prime>
                            </div>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000" width="100%" height="1rem"></Skeleton-prime>
                        </div>
                    </div>

                    <div class="listing-item-container listing-item-all-container" v-else-if="offers.length==0">
                      <div class="not-found-data">
                          <img src="../../assets/images/dummy-data-img.svg">
                          <h2>No offer available</h2>
                      </div>
                    </div>

                    <template v-else>
                    <div class="listing-item-container listing-item-all-container">
                        <div class="listing-item-box" v-for="(item, index) in paginated_offers" :key="index">
                            <div class="flex-list-item offer-img-area" v-bind:style="{'background-image': 'url('+item.offer_image+')'}">
                                <div class="action-btn-dots">
                                    <button-prime type="button" icon="pi pi-ellipsis-v" @click="toggle(index, $event)" aria-haspopup="true" aria-controls="overlay_menu" />
                                    <menu-prime ref="menu" id="overlay_menu" class="action-menu-list" :model="item.menuItems" :popup="true" />
                                </div>
                            </div>

                            <div class="flex-list-item">
                                <span class="offer-title-name">
                                  {{ item.name }}
                                </span>
                            </div>
                            
                            <div class="flex-list-item justify-content-start gap-2">
                                <span class="type-badge-program offer-badge-green">
                                  {{ item.offer_days[0].start_time }} to {{ item.offer_days[0].end_time }}
                                </span>
                            </div>

                            <div class="hr-line">
                                <hr />
                            </div>

                            <div class="flex-list-item">
                                <span class="flex-list-text flex-text-grey">
                                  Redeems per user
                                </span>

                                <span class="flex-list-text">
                                    {{ item.allow_per_user }}
                                </span>
                            </div>

                            <div class="flex-list-item">
                                <span class="flex-list-text flex-text-grey">
                                  Total Redeems
                                </span>

                                <span class="flex-list-text">
                                    -
                                </span>
                            </div>
                            
                            <div class="flex-list-item">
                                <span class="flex-list-text flex-text-grey">
                                    Expires
                                </span>

                                <span class="flex-list-text">
                                    {{ item.expiry_date }}
                                </span>
                            </div>
                        </div>
                    </div>

                      <div class="pagination-container">
                          <!-- <paginator-prime :rows="5" :totalRecords="25"></paginator-prime> -->
                          <paginator-prime v-if="offers.length>3" :rows="offers_rows_per_page" :totalRecords="offers.length" :page="offers_current_page" @page="on_offers_page_change"></paginator-prime>
                          
                      </div>
                    </template>
                  </TabPanel-prime>

                  <TabPanel-prime value="1">
                    <div class="listing-item-container listing-item-all-container" v-if="!drafted_offers">
                      <div class="listing-box-loaders" v-for="index in 3" :key="index">
                            <Skeleton-prime width="100%" height="180px"></Skeleton-prime>
                            <div class="flex justify-between mt-3 mb-3">
                                <Skeleton-prime class="border-1000 mr-2" width="6rem" height="2rem"></Skeleton-prime>
                                <Skeleton-prime class="border-1000" width="6rem" height="2rem"></Skeleton-prime>
                            </div>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000" width="100%" height="1rem"></Skeleton-prime>
                        </div>
                    </div>

                    <div class="listing-item-container listing-item-all-container" v-else-if="drafted_offers.length==0">
                      <div class="not-found-data">
                          <img src="../../assets/images/dummy-data-img.svg">
                          <h2>No offer available</h2>
                      </div>
                    </div>

                    <template v-else>
                      <div class="listing-item-container listing-item-draft-container">
                          <div class="listing-item-box" v-for="item in paginated_drafted_offers" :key="item">
                              <div class="flex-list-item offer-img-area" v-bind:style="{'background-image': 'url('+item.offer_image+')'}">
                                  <div class="delete-item" @click="showDeleteOffer(item.id)">
                                    <img class="delete-icon" src="../../assets/images/bin.svg">
                                  </div>
                              </div>

                              <div class="flex-list-item">
                                  <span class="offer-title-name">
                                    {{ item.name }}
                                  </span>
                              </div>
                              
                              <div class="flex-list-item justify-content-start gap-2">
                                  <span class="type-badge-program offer-badge-green">
                                    {{ item.offer_days[0].start_time }} to {{ item.offer_days[0].end_time }}
                                  </span>
                              </div>

                              <div class="hr-line">
                                  <hr />
                              </div>

                              <div class="flex-list-item">
                                  <span class="flex-list-text flex-text-grey">
                                    Redeems per user
                                  </span>

                                  <span class="flex-list-text">
                                    {{ item.allow_per_user }}
                                  </span>
                              </div>

                              <div class="flex-list-item">
                                  <span class="flex-list-text flex-text-grey">
                                    Total Redeems
                                  </span>

                                  <span class="flex-list-text">
                                    -
                                  </span>
                              </div>
                              
                              <div class="flex-list-item">
                                  <span class="flex-list-text flex-text-grey">
                                      Expires
                                  </span>

                                  <span class="flex-list-text">
                                    {{ item.expiry_date }}
                                  </span>
                              </div>

                              <div class="flex-list-item justify-content-end gap-2">
                                <router-link :to="'offers/edit/'+item.id">
                                  <button-prime class="edit-offer-btn" label="Edit" />
                                </router-link>
                                <button-prime class="publish-btn" label="Publish" :loading="publish_offer_loader" @click="publish_offer(item.id)"/>
                              </div>
                          </div>
                      </div>
                  
                      <div class="pagination-container">
                        <paginator-prime v-if="drafted_offers.length>3" :rows="drafted_offers_rows_per_page" :totalRecords="drafted_offers.length" :page="drafted_offers_current_page" @page="on_drafted_offers_page_change"></paginator-prime>
                      </div>
                    </template>
                  </TabPanel-prime>

                  <TabPanel-prime value="2">
                    <div class="listing-item-container listing-item-all-container" v-if="!expired_offers">
                      <div class="listing-box-loaders" v-for="index in 3" :key="index">
                            <Skeleton-prime width="100%" height="180px"></Skeleton-prime>
                            <div class="flex justify-between mt-3 mb-3">
                                <Skeleton-prime class="border-1000 mr-2" width="6rem" height="2rem"></Skeleton-prime>
                                <Skeleton-prime class="border-1000" width="6rem" height="2rem"></Skeleton-prime>
                            </div>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000" width="100%" height="1rem"></Skeleton-prime>
                        </div>
                    </div>

                    <div class="listing-item-container listing-item-all-container" v-else-if="expired_offers.length==0">
                      <div class="not-found-data">
                          <img src="../../assets/images/dummy-data-img.svg">
                          <h2>No offer available</h2>
                      </div>
                    </div>

                    <template v-else>
                      <div class="listing-item-container listing-item-expired-container">
                          <div class="listing-item-box" v-for="item in paginated_expired_offers" :key="item">
                            <div class="flex-list-item offer-img-area" v-bind:style="{'background-image': 'url('+item.offer_image+')'}">
                                  <div class="delete-item" @click="showDeleteOffer(item.id)">
                                      <img class="delete-icon" src="../../assets/images/bin.svg">
                                  </div>
                              </div>

                              <div class="flex-list-item">
                                  <span class="offer-title-name">
                                    {{ item.name }}
                                  </span>
                              </div>
                              
                              <div class="flex-list-item justify-content-start gap-2">
                                  <span class="type-badge-program offer-badge-green">
                                    {{ item.offer_days[0].start_time }} to {{ item.offer_days[0].end_time }}
                                  </span>
                              </div>

                              <div class="hr-line">
                                  <hr />
                              </div>

                              <div class="flex-list-item">
                                  <span class="flex-list-text flex-text-grey">
                                    Redeems per user
                                  </span>

                                  <span class="flex-list-text">
                                    {{ item.allow_per_user }}
                                  </span>
                              </div>

                              <div class="flex-list-item">
                                  <span class="flex-list-text flex-text-grey">
                                    Total Redeems
                                  </span>

                                  <span class="flex-list-text">
                                    -
                                  </span>
                              </div>
                              
                              <div class="flex-list-item">
                                  <span class="flex-list-text flex-text-grey">
                                    Expires
                                  </span>

                                  <span class="flex-list-text">
                                    {{ item.expiry_date }}
                                  </span>
                              </div>

                              <div class="flex-list-item justify-content-end gap-2">
                                  <button-prime class="republish-offer-btn" label="Republish" :loading=republish_offer_loader @click="republish_offer_modal_show(item)" />
                              </div>
                          </div>
                      </div>

                      <div class="pagination-container">
                        <paginator-prime v-if="expired_offers.length>3" :rows="expired_offers_rows_per_page" :totalRecords="expired_offers.length" :page="expired_offers_current_page" @page="on_expired_offers_page_change"></paginator-prime>
                      </div>
                    </template>
                  </TabPanel-prime>
              </TabPanels-prime>
          </Tabs-prime>
      </div>
    </div>

    <div class="container-fuild add-reward-container" v-if="showAddOfferScreens == 1">
        <!-- Add offer -->
        <addOffer @hide_add_offer="hideAddOfferScreen" />
        <!-- / Add offer -->
    </div>

    <div class="container-fuild add-reward-container" v-if="showOfferDetailScreen == 1">
        <!-- Add offer -->
        <offerDetail @hide_offer_detail="hideOfferDetailScreen" :offer_details="view_current_offer"/>
        <!-- / Add offer -->
    </div>

    <div class="upgrade-plan-message-box" @click="subscriptionVisible = true">
        <h2>Upgrade your Plan for more benefits</h2>
        <p>You can only upload 1 offer per month, upgrade your plan to add more offers</p>

        <i class="pi pi-times"></i>
    </div>
  </div>

  <!-- Republish Modal -->
    <dialog-prime class="fields-option-modal" v-model:visible="republish_modal" modal header="Republish offer" :style="{ width: '25rem' }">
      <div class="form-group">
            <label for="username" class="form-label">offer Title</label>
            <inputText-prime type="text" placeholder="Enter offer Title" v-model="republish.name"/>
        </div>

        <div class="form-group">
            <label for="email" class="form-label">Expiry Date</label>
            <DatePicker-prime v-model="republish.expiry_date" :minDate="new(Date)" dateFormat="dd MM yy" placeholder="Enter Expiry Date" />
        </div>

        <div class="form-group flex justify-content-center w-full mt-4 mb-2">
            <button-prime type="button" class="published-offer-popup" label="Publish offer" :loading="republish_offer_loader" @click="republish_offer"></button-prime>
        </div>
    </dialog-prime>
    <!-- / Republish Modal -->

    

      <!-- Subscription Plan Modal -->
    <!-- <dialog-prime class="subscription-plan-modal" v-model:visible="subscriptionVisible" modal header="New Program" :style="{ width: '36rem' }">
        
        <div class="modal-body-container">
          <div class="subscription-icon">
            <img src="../../assets/images/subscriptionIcon.svg">
          </div>
          <div class="subscription-info">
             <h2>Upgrade your Subscription Plan</h2>
             <label>Get 3 months Free Trial</label>
  
             <p>Upgrade your subscription plan to offer more offers and benefits</p>
          </div>
        </div>
  
        <div class="subscription-footer-btn w-full">
            <button-prime type="button" class="wafa-primary-outline-btn" label="Cancel" @click="subscriptionVisible = false"></button-prime>
            <button-prime type="button" class="wafa-primary-btn upgrade-btn" label="Upgrade"></button-prime>
        </div>
      </dialog-prime> -->
      <!-- / Subscription Plan Modal -->
       <!-- Delete Modal -->
    <dialog-prime class="subscription-plan-modal" v-model:visible="delete_modal" modal header="New Program" :style="{ width: '22rem' }">
        
        <div class="modal-body-container justify-content-center">
          <div class="subscription-info text-center w-full">
             <h2>Are You Sure?</h2>
             <p class="mt-1">You want to delete this offer</p>
          </div>
        </div>
  
        <div class="subscription-footer-btn w-full justify-content-center">
            <button-prime type="button" class="wafa-primary-outline-btn" label="Cancel" @click="delete_modal = false"></button-prime>
            <button-prime type="button" class="wafa-primary-btn delete-btn" label="Delete" :loading="delete_offer_loader" @click="delete_offer"></button-prime>
        </div>
      </dialog-prime>
      <!-- / Delete Modal -->
      <toast-prime />
</template>

<script setup>
import { ref } from "vue";

const subscriptionVisible = ref(false);

</script>

<script>
import axios from 'axios'
import moment from 'moment'
import sidebarNav from '../sharedViews/sharedView.vue';
import addOffer from './addOffer.vue'
import offerDetail from './offerDetails.vue'

export default {
  components: {
    sidebarNav,
    addOffer,
    offerDetail
  },
  data() {
    return {
        offers : null,
        drafted_offers : null,
        expired_offers : null,
        offers_rows_per_page : 3,
        offers_current_page : 0,
        drafted_offers_rows_per_page : 3,
        drafted_offers_current_page : 0,
        expired_offers_rows_per_page : 3,
        expired_offers_current_page : 0,
        showOfferlistingScreens: 0,
        showAddOfferScreens: 0,
        showOfferDetailScreen: 0,
        view_current_offer : null,
        delete_modal : false,
        publish_offer_loader : false,
        current_offer_id_to_delete : 0,
        delete_offer_loader : false,
        republish_offer_loader : false,
        republish_modal : false,
        republish : {
          expiry_date : new(Date),
          expiry_date_backend : null,
          name : null,
          id : null
        }
    }
  },
  computed: {
    // Compute the items for the current page
    paginated_offers() {
      if (!this.offers) return [];
      const start = this.offers_current_page * this.offers_rows_per_page;
      const end = start + this.offers_rows_per_page;
      return this.offers.slice(start, end);
    },
    paginated_drafted_offers() {
      if (!this.drafted_offers) return [];
      const start = this.drafted_offers_current_page * this.drafted_offers_rows_per_page;
      const end = start + this.drafted_offers_rows_per_page;
      return this.drafted_offers.slice(start, end);
    },
    paginated_expired_offers() {
      if (!this.expired_offers) return [];
      const start = this.expired_offers_current_page * this.expired_offers_rows_per_page;
      const end = start + this.expired_offers_rows_per_page;
      return this.expired_offers.slice(start, end);
    },
  },

  mounted() {
    this.get_offers();
  },
  methods: {
    republish_offer_modal_show(data) {
      this.republish_modal = true;
      this.republish.name = data.name;
      this.republish.id = data.id;
    },
    publish_offer(offer_id){
      this.publish_offer_loader = true;
      axios.post(this.api_url+'merchant/update-offer', 
        {
          type : 'published',
          id : offer_id
        },
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
        }).then(response => {
            console.log(response);
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Offer published!', life: 3000 });
            this.get_offers();
            
        }).catch(error => {
            console.log(error);
        }).finally(() => {
          this.publish_offer_loader = false;
        });
    },
    delete_offer(){
      this.delete_offer_loader = true;
      axios.post(this.api_url+'merchant/update-offer', 
        {
          type : 'trashed',
          id : this.current_offer_id_to_delete
        },
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
        }).then(response => {
            console.log(response);
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Offer deleted!', life: 3000 });
            this.get_offers();
            
        }).catch(error => {
            console.log(error);
        }).finally(() => {
          this.delete_offer_loader = false;
          this.delete_modal = false;
        });
    },
    republish_offer(){
      this.republish_offer_loader = true;
      this.republish.expiry_date_backend = moment(this.republish.expiry_date).format('YYYY-MM-DD');
      axios.post(this.api_url+'merchant/update-offer', 
        this.republish,
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
        }).then(response => {
            console.log(response);
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Offer republished!', life: 3000 });
            this.get_offers();
            
        }).catch(error => {
            console.log(error);
        }).finally(() => {
          this.republish_offer_loader = false;
          this.republish_modal = false;
        });
    },
    toggle(index, event) {
      this.$refs.menu[index].toggle(event);
    },
    on_offers_page_change(event) {
      this.offers_current_page = event.page;
    },
    on_drafted_offers_page_change(event) {
      this.drafted_offers_current_page = event.page;
    },
    on_expired_offers_page_change(event) {
      this.expired_offers_current_page = event.page;
    },
    get_offers() {
      axios.post(this.api_url+'merchant/get-offers',
        {},
        {
          headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
          }
        }).then(response => {
          setTimeout(() => {
            this.offers = response.data.data;
            this.drafted_offers = this.offers.filter(item => item.status === 'drafted');
            var current_date = new Date();
            current_date.setHours(0, 0, 0, 0); 
            var is_expired = (expiryDate) => {
              return new Date(expiryDate).setHours(0, 0, 0, 0) < current_date;
            }
            this.expired_offers = this.offers.filter(item => is_expired(item.expiry_date));
            for (let i = 0; i < this.offers.length; i++) {
              this.offers[i].expiry_date = moment(this.offers[i].expiry_date).format("DD MMM YYYY")
              this.offers[i].menuItems = [
                { label: 'Details', icon: 'pi pi-info-circle', offer_id : this.offers[i].id, command: () => this.showOfferDetail(this.offers[i].id)},
                { label: 'Delete', icon: 'pi pi-trash', offer_id : this.offers[i].id, command: () => this.showDeleteOffer(this.offers[i].id)}
              ]
            }
          }, 1000);
        });
    },
    showOfferScreens : function(){
        this.showOfferlistingScreens = 1;
        this.showOfferDetailScreen = 0;
        this.showAddOfferScreens = 1;
    },

    showOfferDetail : function(offer_id){
        this.view_current_offer  = this.offers.find(offer => offer.id === offer_id);
        this.showOfferlistingScreens = 1;
        this.showOfferDetailScreen = 1;
        

    },

    showDeleteOffer : function(offer_id) {
        this.current_offer_id_to_delete = offer_id;
        this.delete_modal = true;
    },

    hideAddOfferScreen : function() {
        this.showAddOfferScreens = 0;
        this.showOfferlistingScreens = 0;
    },
    
    hideOfferDetailScreen : function() {
        this.showOfferDetailScreen = 0;
        this.showOfferlistingScreens = 0;
    }
  } 
}
</script>

<style>

</style>
