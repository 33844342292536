<template>
    <Header />

    <div class="container m-0">
        <div class="terms-conditon-page">
            <div class="container p-0">
                <div class="grid ml-0 mr-0">
                    <div class="col-12 md:col-12 lg:col-12">
                        <h2 class="terms-and-condition-title">Privacy Policy</h2>

                        <h3 class="small-heading">
                            1. Information We Collect:
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>We may collect personal information from you when you use the App, such as your name, email address, and location.</li>
                                
                                <li>We may also collect information about your use of the App, such as your IP address and browsing history.</li>
                            </ul>
                        </div>

                        <h3 class="small-heading">
                            2. Information Use
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>Provide you with the App and its features</li>
                                <li>Personalize your experience</li>
                                <li>Communicate with you</li>
                                <li>Analyze and improve the App</li>
                            </ul>
                        </div>

                        <h3 class="small-heading">
                            3. Information Sharing
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>We may share your information with third-party service providers who assist us in operating the App.</li>
                                
                                <li>We may also share your information with participating merchants if necessary to fulfill your rewards.</li>
                            </ul>
                        </div>

                        <h3 class="small-heading">
                            4. Data Security
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>We implement reasonable security measures to protect your information from unauthorized access or disclosure</li>
                            </ul>
                        </div>

                        <h3 class="small-heading">
                            5. Changes
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>We may update this Privacy Policy from time to time.</li>
                                <li>Any changes will be effective upon posting on the App.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer/>
</template>

<script>
import Header from '../Header/HeaderView.vue'
import Footer from '../Footer/FooterView.vue'

export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {

      }
    },
    mounted() {
    },
    methods : {

    }
}
</script>
