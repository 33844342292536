<template>
    <div class="signin-wrapper">
        <div class="grid m-0">
            <div class="col-12 md:col-6 lg:col-6 signIn-form-rightArea">
                <div class="logo-area">
                    <router-link to="/">
                        <img class="site-logo-spacedesign" src="../../assets/images/logo/wafa-logo.svg">
                    </router-link>
                </div>

                <div class="welcome-text-sign">
                    <h2>Welcome back!</h2>
                    <p>Use your credentials below and login to your account</p>
                </div>

                <!-- Sign In Form -->
                <div class="sign-form-area" id="signIn-from">
                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.30233 22.1814H18.6977C20.7963 22.1814 22.4626 21.5773 23.6043 20.4357C24.7459 19.294 25.35 17.6277 25.35 15.5291V7.55233C25.35 5.45369 24.7459 3.78737 23.6043 2.64571C22.4626 1.50406 20.7963 0.9 18.6977 0.9H7.30233C5.20369 0.9 3.53737 1.50406 2.39571 2.64571C1.25406 3.78737 0.65 5.45369 0.65 7.55233V15.5291C0.65 17.6277 1.25406 19.294 2.39571 20.4357C3.53737 21.5773 5.20369 22.1814 7.30233 22.1814ZM2.5593 7.55233C2.5593 5.93749 2.95172 4.75702 3.72937 3.97937C4.50702 3.20172 5.68749 2.8093 7.30233 2.8093H18.6977C20.3125 2.8093 21.493 3.20172 22.2706 3.97937C23.0483 4.75702 23.4407 5.93749 23.4407 7.55233V15.5291C23.4407 17.1439 23.0483 18.3244 22.2706 19.102C21.493 19.8797 20.3125 20.2721 18.6977 20.2721H7.30233C5.68749 20.2721 4.50702 19.8797 3.72937 19.102C2.95172 18.3244 2.5593 17.1439 2.5593 15.5291V7.55233Z" fill="#8A8D90" stroke="#8A8D90" stroke-width="0.2"/>
                                <path d="M6.70343 8.86058L6.70343 8.86058L6.70408 8.8611L10.27 11.7093C10.2702 11.7094 10.2703 11.7095 10.2704 11.7096C11.0315 12.33 12.0232 12.632 12.9997 12.632C13.976 12.632 14.968 12.3302 15.718 11.7094L19.283 8.86188C19.7042 8.54189 19.7664 7.93152 19.437 7.52472C19.1167 7.10347 18.5058 7.04168 18.0991 7.37186C18.099 7.37194 18.0989 7.37202 18.0988 7.3721L14.5327 10.2204L14.5325 10.2206C14.1204 10.5513 13.5597 10.7199 12.994 10.7199C12.4283 10.7199 11.8677 10.5513 11.4556 10.2206L11.4554 10.2204L7.88933 7.3721C7.88921 7.37201 7.8891 7.37192 7.88899 7.37183C7.47934 7.0393 6.88139 7.11817 6.55214 7.52339C6.21929 7.93306 6.2981 8.53124 6.70343 8.86058Z" fill="#8A8D90" stroke="#8A8D90" stroke-width="0.2"/>
                            </svg>
                            <inputText-prime class="form-input-fields" type="email" v-model="email" placeholder="Enter Your Email" />
                        </span>
                    </div>

                    <div class="form-fields-main mb-0">
                        <span class="p-input-icon-left">
                            <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.4">
                                <path d="M2.05819 23.48C2.34732 25.6374 4.12603 27.3276 6.29057 27.4276C8.11193 27.5117 9.96211 27.5556 11.9996 27.5556C14.037 27.5556 15.8872 27.5117 17.7086 27.4276C19.8731 27.3276 21.6518 25.6374 21.941 23.48C22.1297 22.072 22.2853 20.6292 22.2853 19.1597C22.2853 17.6903 22.1297 16.2474 21.941 14.8395C21.6518 12.6821 19.8731 10.9919 17.7086 10.8919C15.8872 10.8078 14.037 10.7639 11.9996 10.7639C9.96211 10.7639 8.11193 10.8078 6.29057 10.8919C4.12603 10.9919 2.34732 12.6821 2.05819 14.8395C1.8695 16.2474 1.71386 17.6903 1.71386 19.1597C1.71386 20.6292 1.8695 22.072 2.05819 23.48Z" stroke="#8A8D90" stroke-width="1.5"/>
                                <path d="M6.21387 10.7639V7.53473C6.21387 4.32457 8.80422 1.72223 11.9996 1.72223C15.1949 1.72223 17.7853 4.32457 17.7853 7.53473V10.7639" stroke="#8A8D90" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11.9941 19.1597H12.0057" stroke="#8A8D90" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                            </svg>
                            <password-prime class="form-input-fields" v-model="password" toggleMask :feedback="false" placeholder="Enter Your Password" />
                        </span>
                    </div>

                    <div class="form-fields-main remember-field">
                        <div class="flex align-items-center remember-check">
                            <checkbox-prime id="rememberMe" :binary="true" />
                            <label for="rememberMe" class="ml-2"> Remember me </label>
                        </div>
                        <router-link to="/forgot-password">
                            <span class="forgotPassword-btn">
                                Forgot password?
                            </span>
                        </router-link>
                    </div>

                    <div class="form-fields-main text-center mb-0">
                       
                       <button-prime :loading="login_loading" @click="login" class="login-btn mb-2" label="Log into your account" rounded />
                       
                    </div>
                </div>
                <!-- / Sign In Form -->
            </div>

            <div class="col-12 md:col-6 lg:col-6 signIn-Info-leftArea">
                <div class="login-img">
                    <img class="site-logo-wafaloyalty" src="../../assets/images/login-image.png">
                </div>
            </div>
        </div>
        <toast-prime />
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            email : null,
            password : null,
            user_type : 'merchant',
            login_loading : false
        }
    },
    mounted() {
        
    },
    methods : {
        login : function () {
            this.login_loading = true;
            axios.post(this.api_url+'authenticate', { email: this.email, password: this.password , user_type : this.user_type})
            .then(response => {
                if (response.data.data.status && response.data.data.token != null) {
                    localStorage.setItem('wafa_auth_token', response.data.data.token); 
                    this.$router.push('/dashboard'); 
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.data.message, life: 3000 });
                }
            }).catch(error => {
                if (error.response.status == 401) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: error.response.data.errors, life: 3000 });
                } 
            }).finally(() => {
                this.login_loading = false;
            });
        },
        logout : function () {
            localStorage.removeItem('wafa_auth_token'); 
            this.$router.push('/signin'); 
        }
    }
}
</script>