<template>

    <sidebarNav class="main-sidebar" />
  
</template>
  
<script>

import sidebarNav from './sidebar/sidebarNav.vue';
export default {
  components: {
    sidebarNav
  }
}
</script>

<style>

</style>
  