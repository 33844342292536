<template>
    <div class="reward-listing-inner mt-3">
        <Tabs-prime value="0">
            <TabList-prime>
                <Tab-prime value="0" v-if="!rewards">All Rewards (0)</Tab-prime>
                <Tab-prime value="0" v-else>All Rewards ({{ rewards.length }})</Tab-prime>
                <Tab-prime value="1" v-if="!drafted_rewards">Drafts (0)</Tab-prime>
                <Tab-prime value="1" v-else>Drafts ({{ drafted_rewards.length }})</Tab-prime>
                <Tab-prime value="2" v-if="!expired_rewards">Expired (0)</Tab-prime>
                <Tab-prime value="2" v-else>Expired ({{ expired_rewards.length }})</Tab-prime>

                
                <span class="reward-header-filters">
                    <button-prime class="reward-filter-btn" label="Filter by" icon="pi pi-sliders-h" />
                    <button-prime class="reward-filter-btn" label="Sort by" icon="pi pi-sort-amount-down-alt" />
                </span>
            </TabList-prime>

            <TabPanels-prime>
                <TabPanel-prime value="0">
                    <div class="listing-item-container listing-item-all-container" v-if="!rewards">
                        <div class="listing-box-loaders" v-for="index in 3" :key="index">
                            <Skeleton-prime width="100%" height="180px"></Skeleton-prime>
                            <div class="flex justify-between mt-3 mb-3">
                                <Skeleton-prime class="border-1000 mr-2" width="6rem" height="2rem"></Skeleton-prime>
                                <Skeleton-prime class="border-1000" width="6rem" height="2rem"></Skeleton-prime>
                            </div>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000" width="100%" height="1rem"></Skeleton-prime>
                        </div>
                    </div>

                    <div class="listing-item-container listing-item-all-container" v-else-if="rewards.length==0">
                        <div class="not-found-data">
                            <img src="../../assets/images/dummy-data-img.svg">
                            <h2>No rewards available</h2>
                        </div>
                    </div>

                    <template v-else>
                        <div class="listing-item-container listing-item-all-container">
                            <div class="listing-item-box" v-for="(item, index) in paginated_rewards" :key="index">
                                <div class="flex-list-item reward-img-area" v-bind:style="{'background-image': 'url('+item.reward_image+')'}">
                                    <div class="action-btn-dots">
                                        <button-prime type="button" icon="pi pi-ellipsis-v" @click="toggle(index, $event)" aria-haspopup="true" aria-controls="overlay_menu" />
                                        <menu-prime ref="menu" id="overlay_menu" class="action-menu-list" :model="item.menuItems" :popup="true" />
                                    </div>
                                </div>

                                <div class="flex-list-item">
                                    <span class="reward-title-name">
                                        {{ item.name }}
                                    </span>
                                </div>
                                
                                <div class="flex-list-item justify-content-start gap-2">
                                    <span class="type-badge-program reward-badge-yellow">
                                        {{ item.reward_type.name }}
                                    </span>

                                    <span class="type-badge-program reward-badge-green">
                                        {{ item.item_name }}
                                    </span>
                                </div>

                                <div class="hr-line">
                                    <hr />
                                </div>

                                <div class="flex-list-item">
                                    <span class="flex-list-text flex-text-grey">
                                        Value of Item
                                    </span>

                                    <span class="flex-list-text">
                                        {{ item.item_value }} AED
                                    </span>
                                </div>

                                <div class="flex-list-item">
                                    <span class="flex-list-text flex-text-grey">
                                        Stamps Required
                                    </span>

                                    <span class="flex-list-text">
                                        {{ item.no_of_stamps_target }}
                                    </span>
                                </div>
                                
                                <div class="flex-list-item">
                                    <span class="flex-list-text flex-text-grey">
                                        Expires
                                    </span>

                                    <span class="flex-list-text">
                                        {{ item.expiry_date }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="pagination-container">
                            <paginator-prime v-if="rewards.length>3" :rows="rewards_rows_per_page" :totalRecords="rewards.length" :page="rewards_current_page" @page="on_rewards_page_change"></paginator-prime>
                        </div>
                    </template>
                </TabPanel-prime>

                <TabPanel-prime value="1">
                    <div class="listing-item-container listing-item-all-container" v-if="!drafted_rewards">
                        <div class="listing-box-loaders" v-for="index in 3" :key="index">
                            <Skeleton-prime width="100%" height="180px"></Skeleton-prime>
                            <div class="flex justify-between mt-3 mb-3">
                                <Skeleton-prime class="border-1000 mr-2" width="6rem" height="2rem"></Skeleton-prime>
                                <Skeleton-prime class="border-1000" width="6rem" height="2rem"></Skeleton-prime>
                            </div>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000" width="100%" height="1rem"></Skeleton-prime>
                        </div>
                    </div>

                    <div class="listing-item-container listing-item-all-container" v-else-if="drafted_rewards.length==0">
                        <div class="not-found-data">
                            <img src="../../assets/images/dummy-data-img.svg">
                            <h2>No rewards available</h2>
                        </div>
                    </div>

                    <template v-else>
                        <div class="listing-item-container listing-item-draft-container">
                            <div class="listing-item-box" v-for="(item, index) in paginated_drafted_rewards" :key="index">
                                <div class="flex-list-item offer-img-area" v-bind:style="{'background-image': 'url('+item.reward_image+')'}">
                                    <div class="delete-item" @click="showDeleteReward(item.id)">
                                        <img class="delete-icon" src="../../assets/images/bin.svg">
                                    </div>
                                </div>

                                <div class="flex-list-item">
                                    <span class="reward-title-name">
                                        {{ item.name }}
                                    </span>
                                </div>
                                
                                <div class="flex-list-item justify-content-start gap-2">
                                    <span class="type-badge-program reward-badge-yellow">
                                        {{ item.reward_type.name }}
                                    </span>

                                    <span class="type-badge-program reward-badge-green">
                                        {{ item.item_name }}
                                    </span>
                                </div>

                                <div class="hr-line">
                                    <hr />
                                </div>

                                <div class="flex-list-item">
                                    <span class="flex-list-text flex-text-grey">
                                        Value of Item
                                    </span>

                                    <span class="flex-list-text">
                                        {{ item.item_value }} AED
                                    </span>
                                </div>

                                <div class="flex-list-item">
                                    <span class="flex-list-text flex-text-grey">
                                        Stamps Required
                                    </span>

                                    <span class="flex-list-text">
                                        {{ item.no_of_stamps_target }}
                                    </span>
                                </div>
                                
                                <div class="flex-list-item">
                                    <span class="flex-list-text flex-text-grey">
                                        Expires
                                    </span>

                                    <span class="flex-list-text">
                                        {{ item.expiry_date }}
                                    </span>
                                </div>

                                <div class="flex-list-item justify-content-end gap-2">
                                    <router-link :to="'edit-program-reward/'+item.id">
                                    <button-prime class="edit-reward-btn" label="Edit" />
                                    </router-link>
                                    <button-prime class="publish-btn" label="Publish" :loading="publish_reward_loader" @click="publish_reward(item.id)"/>
                                </div>
                            </div>
                        </div>

                        <div class="pagination-container">
                            <paginator-prime v-if="drafted_rewards.length>3" :rows="drafted_rewards_rows_per_page" :totalRecords="drafted_rewards.length" :page="drafted_rewards_current_page" @page="on_drafted_rewards_page_change"></paginator-prime>
                        </div>
                    </template>
                </TabPanel-prime>

                <TabPanel-prime value="2">
                    <div class="listing-item-container listing-item-all-container" v-if="!expired_rewards">
                        <div class="listing-box-loaders" v-for="index in 3" :key="index">
                            <Skeleton-prime width="100%" height="180px"></Skeleton-prime>
                            <div class="flex justify-between mt-3 mb-3">
                                <Skeleton-prime class="border-1000 mr-2" width="6rem" height="2rem"></Skeleton-prime>
                                <Skeleton-prime class="border-1000" width="6rem" height="2rem"></Skeleton-prime>
                            </div>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                            <Skeleton-prime class="border-1000" width="100%" height="1rem"></Skeleton-prime>
                        </div>
                    </div>

                    <div class="listing-item-container listing-item-all-container" v-else-if="expired_rewards.length==0">
                        <div class="not-found-data">
                            <img src="../../assets/images/dummy-data-img.svg">
                            <h2>No rewards available</h2>
                        </div>
                    </div>

                    <template v-else>
                        <div class="listing-item-container listing-item-expired-container">
                            <div class="listing-item-box" v-for="item in paginated_expired_rewards" :key="item">
                                <div class="flex-list-item reward-img-area" v-bind:style="{'background-image': 'url('+item.reward_image+')'}">
                                    <div class="delete-item" @click="showDeleteReward(item.id)">
                                        <img class="delete-icon" src="../../assets/images/bin.svg">
                                    </div>
                                </div>

                                <div class="flex-list-item">
                                    <span class="reward-title-name">
                                        {{ item.name }}
                                    </span>
                                </div>
                                
                                <div class="flex-list-item justify-content-start gap-2">
                                    <span class="type-badge-program reward-badge-yellow">
                                        {{ item.reward_type.name }}
                                    </span>

                                    <span class="type-badge-program reward-badge-green">
                                        {{ item.item_name }}
                                    </span>
                                </div>

                                <div class="hr-line">
                                    <hr />
                                </div>

                                <div class="flex-list-item">
                                    <span class="flex-list-text flex-text-grey">
                                        Value of Item
                                    </span>

                                    <span class="flex-list-text">
                                        {{ item.item_value }} AED
                                    </span>
                                </div>

                                <div class="flex-list-item">
                                    <span class="flex-list-text flex-text-grey">
                                        Stamps Required
                                    </span>

                                    <span class="flex-list-text">
                                        {{ item.no_of_stamps_target }}
                                    </span>
                                </div>
                                
                                <div class="flex-list-item">
                                    <span class="flex-list-text flex-text-grey">
                                        Expires
                                    </span>

                                    <span class="flex-list-text">
                                        {{ item.expiry_date }}
                                    </span>
                                </div>

                                <div class="flex-list-item justify-content-end gap-2">
                                    <button-prime class="republish-reward-btn" label="Republish" :loading=republish_reward_loader @click="republish_reward_modal_show(item)" />
                                </div>
                            </div>
                        </div>

                        <div class="pagination-container">
                            <paginator-prime v-if="expired_rewards.length>3" :rows="expired_rewards_rows_per_page" :totalRecords="expired_rewards.length" :page="expired_rewards_current_page" @page="on_expired_rewards_page_change"></paginator-prime>
                        </div>
                    </template>
                </TabPanel-prime>
            </TabPanels-prime>
        </Tabs-prime>
    </div>

  <!-- Republish Modal -->
  <dialog-prime class="fields-option-modal" v-model:visible="republish_modal" modal header="Republish reward" :style="{ width: '25rem' }">
      <div class="form-group">
            <label for="username" class="form-label">Reward Title</label>
            <inputText-prime type="text" placeholder="Enter reward Title" v-model="republish.title"/>
        </div>

        <div class="form-group">
            <label for="email" class="form-label">Expiry Date</label>
            <DatePicker-prime v-model="republish.expiry_date" :minDate="new(Date)" dateFormat="dd MM yy" placeholder="Enter Expiry Date" />
        </div>

        <div class="form-group flex justify-content-center w-full mt-4 mb-2">
            <button-prime type="button" class="published-reward-popup" label="Publish reward" :loading="republish_reward_loader" @click="republish_reward"></button-prime>
        </div>
    </dialog-prime>
    <!-- / Republish Modal -->
     <!-- Delete Modal -->
    <dialog-prime class="subscription-plan-modal" v-model:visible="delete_modal" modal header="New Program" :style="{ width: '22rem' }">
        
        <div class="modal-body-container justify-content-center">
          <div class="subscription-info text-center w-full">
             <h2>Are You Sure?</h2>
             <p class="mt-1">You want to delete this reward</p>
          </div>
        </div>
  
        <div class="subscription-footer-btn w-full justify-content-center">
            <button-prime type="button" class="wafa-primary-outline-btn" label="Cancel" @click="delete_modal = false"></button-prime>
            <button-prime type="button" class="wafa-primary-btn delete-btn" label="Delete" :loading="delete_reward_loader" @click="delete_reward"></button-prime>
        </div>
      </dialog-prime>
      <!-- / Delete Modal -->
     

</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  data() {
    return {
        rewards : null,
        drafted_rewards : null,
        expired_rewards : null,
        rewards_rows_per_page : 3,
        rewards_current_page : 0,
        drafted_rewards_rows_per_page : 3,
        drafted_rewards_current_page : 0,
        expired_rewards_rows_per_page : 3,
        expired_rewards_current_page : 0,
        view_current_reward : null,
        delete_modal : false,
        publish_reward_loader : false,
        current_reward_id_to_delete : 0,
        delete_reward_loader : false,
        republish_reward_loader : false,
        republish_modal : false,
        republish : {
          expiry_date : new(Date),
          expiry_date_backend : null,
          title : null,
          id : null
        }
    }
  },
  computed: {
    // Compute the items for the current page
    paginated_rewards() {
      if (!this.rewards) return [];
      const start = this.rewards_current_page * this.rewards_rows_per_page;
      const end = start + this.rewards_rows_per_page;
      return this.rewards.slice(start, end);
    },
    paginated_drafted_rewards() {
      if (!this.drafted_rewards) return [];
      const start = this.drafted_rewards_current_page * this.drafted_rewards_rows_per_page;
      const end = start + this.drafted_rewards_rows_per_page;
      return this.drafted_rewards.slice(start, end);
    },
    paginated_expired_rewards() {
      if (!this.expired_rewards) return [];
      const start = this.expired_rewards_current_page * this.expired_rewards_rows_per_page;
      const end = start + this.expired_rewards_rows_per_page;
      return this.expired_rewards.slice(start, end);
    },
  },
  mounted() {
    this.get_rewards();
  },
  methods: {
    republish_reward_modal_show(data) {
      this.republish_modal = true;
      this.republish.title = data.name;
      this.republish.id = data.id;
    },
    publish_reward(reward_id){
      this.publish_reward_loader = true;
      axios.post(this.api_url+'merchant/update-reward', 
        {
          type : 'published',
          id : reward_id
        },
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
        }).then(response => {
            console.log(response);
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Reward published!', life: 3000 });
            this.get_rewards();
            
        }).catch(error => {
            console.log(error);
        }).finally(() => {
          this.publish_reward_loader = false;
        });
    },
    delete_reward(){
      this.delete_reward_loader = true;
      axios.post(this.api_url+'merchant/update-reward', 
        {
          type : 'trashed',
          id : this.current_reward_id_to_delete
        },
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
        }).then(response => {
            console.log(response);
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Reward deleted!', life: 3000 });
            this.get_rewards();
            
        }).catch(error => {
            console.log(error);
        }).finally(() => {
          this.delete_reward_loader = false;
          this.delete_modal = false;
        });
    },
    republish_reward(){
      this.republish_reward_loader = true;
      this.republish.expiry_date_backend = moment(this.republish.expiry_date).format('YYYY-MM-DD');
      axios.post(this.api_url+'merchant/update-reward', 
        this.republish,
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
        }).then(response => {
            console.log(response);
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Reward republished!', life: 3000 });
            this.get_rewards();
            
        }).catch(error => {
            console.log(error);
        }).finally(() => {
          this.republish_reward_loader = false;
          this.republish_modal = false;
        });
    },
    get_rewards() {
      axios.post(this.api_url+'merchant/get-rewards',
        {},
        {
          headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
          }
        }).then(response => {
            setTimeout(() => {
                this.rewards = response.data.data;
                for (let i = 0; i < this.rewards.length; i++) {
                    this.rewards[i].expiry_date = moment(this.rewards[i].expiry_date).format("DD MMM YYYY")
                }
                this.rewards = response.data.data;
                this.drafted_rewards = this.rewards.filter(item => item.status === 'drafted');
                var current_date = new Date();
                current_date.setHours(0, 0, 0, 0); 
                var is_expired = (expiryDate) => {
                    return new Date(expiryDate).setHours(0, 0, 0, 0) < current_date;
                }
                this.expired_rewards = this.rewards.filter(item => is_expired(item.expiry_date));
                for (let i = 0; i < this.rewards.length; i++) {
                    this.rewards[i].expiry_date = moment(this.rewards[i].expiry_date).format("DD MMM YYYY")
                    this.rewards[i].menuItems = [
                        { label: 'Edit', icon: 'pi pi-pencil', offer_id : this.rewards[i].id, command: () => this.edit_reward(this.rewards[i].id)},
                        { label: 'Delete', icon: 'pi pi-trash', offer_id : this.rewards[i].id, command: () => this.showDeleteReward(this.rewards[i].id)}
                    ]
                }
            }, 1000);
        });
    },
    edit_reward(reward_id) {
        this.$router.push({
            path : 'edit-program-reward/'+reward_id
        });
    },
    toggle(index, event) {  
      this.$refs.menu[index].toggle(event);
    },
    on_rewards_page_change(event) {
      this.rewards_current_page = event.page;
    },
    on_drafted_rewards_page_change(event) {
      this.drafted_rewards_current_page = event.page;
    },
    on_expired_rewards_page_change(event) {
      this.expired_rewards_current_page = event.page;
    },
    showDeleteReward : function(reward_id) {
        this.current_reward_id_to_delete = reward_id;
        this.delete_modal = true;
    },
  } 
}
</script>
  

<style>

</style>
  