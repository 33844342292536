<template>
    <div class="dashboard-flex-box">
        <div class="reward-listing-inner active-offer-listing-inner mt-3">
            <div class="listing-item-container listing-item-all-container" v-if="!offers">
                <div class="listing-box-loaders" v-for="index in 1" :key="index">
                    <Skeleton-prime width="100%" height="180px"></Skeleton-prime>
                    <div class="flex justify-between mt-3 mb-3">
                        <Skeleton-prime class="border-1000 mr-2" width="6rem" height="2rem"></Skeleton-prime>
                        <Skeleton-prime class="border-1000" width="6rem" height="2rem"></Skeleton-prime>
                    </div>
                    <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                    <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
                    <Skeleton-prime class="border-1000" width="100%" height="1rem"></Skeleton-prime>
                </div>
            </div>

            <div class="listing-item-container listing-item-all-container" v-else-if="offers.length==0">
                <div class="not-found-data">
                    <img src="../../assets/images/dummy-data-img.svg">
                    <h2>No offer available</h2>
                </div>
            </div>

            <template v-else>
            <div class="listing-item-container listing-item-all-container">
                <div class="listing-item-box" v-for="(item, index) in paginated_offers" :key="index">
                    <div class="flex-list-item offer-img-area" v-bind:style="{'background-image': 'url('+item.offer_image+')'}">
                        <div class="action-btn-dots">
                            <button-prime type="button" icon="pi pi-ellipsis-v" @click="toggle(index, $event)" aria-haspopup="true" aria-controls="overlay_menu" />
                            <menu-prime ref="menu" id="overlay_menu" class="action-menu-list" :model="item.menuItems" :popup="true" />
                        </div>
                    </div>

                    <div class="flex-list-item">
                        <span class="offer-title-name">
                            {{ item.name }}
                        </span>
                    </div>
                    
                    <div class="flex-list-item justify-content-start gap-2">
                        <span class="type-badge-program offer-badge-green">
                            {{ item.offer_days[0].start_time }} to {{ item.offer_days[0].end_time }}
                        </span>
                    </div>

                    <div class="hr-line">
                        <hr />
                    </div>

                    <div class="flex-list-item">
                        <span class="flex-list-text flex-text-grey">
                            Redeems per user
                        </span>

                        <span class="flex-list-text">
                            {{ item.allow_per_user }}
                        </span>
                    </div>

                    <div class="flex-list-item">
                        <span class="flex-list-text flex-text-grey">
                            Total Redeems
                        </span>

                        <span class="flex-list-text">
                            -
                        </span>
                    </div>
                    
                    <div class="flex-list-item">
                        <span class="flex-list-text flex-text-grey">
                            Expires
                        </span>

                        <span class="flex-list-text">
                            {{ item.expiry_date }}
                        </span>
                    </div>
                </div>
            </div>

                <div class="pagination-container">
                    <!-- <paginator-prime :rows="5" :totalRecords="25"></paginator-prime> -->
                    <paginator-prime v-if="offers.length>1" :rows="offers_rows_per_page" :totalRecords="offers.length" :page="offers_current_page" @page="on_offers_page_change"></paginator-prime>
                    
                </div>
            </template>
        </div>

        <div class="upgrade-plan-message-box" @click="subscriptionVisible = true">
            <h2>Upgrade your Plan</h2>
            <p>Upgrade your plan to add more offers</p>

            <i class="pi pi-times"></i>
        </div>
    </div>
    
    <dialog-prime class="subscription-plan-modal" v-model:visible="subscriptionVisible" modal header="New Program" :style="{ width: '36rem' }">
        
        <div class="modal-body-container">
          <div class="subscription-icon">
            <img src="../../assets/images/subscriptionIcon.svg">
          </div>
          <div class="subscription-info">
             <h2>Upgrade your Subscription Plan</h2>
             <label>Get 3 months Free Trial</label>
  
             <p>Upgrade your subscription plan to offer more offers and benefits</p>
          </div>
        </div>
  
        <div class="subscription-footer-btn w-full">
            <button-prime type="button" class="wafa-primary-outline-btn" label="Cancel" @click="subscriptionVisible = false"></button-prime>
            <button-prime type="button" class="wafa-primary-btn upgrade-btn" label="Upgrade"></button-prime>
        </div>
      </dialog-prime>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  
  data() {
    return {
        offers : null,
        offers_rows_per_page : 1,
        offers_current_page : 0,
        current_offer_id_to_delete : 0,
        delete_offer_modal : false,
        subscriptionVisible : false
    }
  },
  computed: {
    // Compute the items for the current page
    paginated_offers() {
      if (!this.offers) return [];
      const start = this.offers_current_page * this.offers_rows_per_page;
      const end = start + this.offers_rows_per_page;
      return this.offers.slice(start, end);
    }
  },
  mounted(){
    this.get_offers();
  },
  methods : {
    get_offers() {
      axios.post(this.api_url+'merchant/get-offers',
        {type : 'active'},
        {
          headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
          }
        }).then(response => {
          setTimeout(() => {
            this.offers = response.data.data;
            for (let i = 0; i < this.offers.length; i++) {
              this.offers[i].expiry_date = moment(this.offers[i].expiry_date).format("DD MMM YYYY")
              this.offers[i].menuItems = [
                { label: 'Edit', icon: 'pi pi-pencil', command: () => this.editOffer(this.offers[i].id)},
                { label: 'Delete', icon: 'pi pi-trash', command: () => this.showDeleteOffer(this.offers[i].id)}
              ]
            }
          }, 1000);
        });
    },
    editOffer : function(offer_id) {
        this.$router.push({
            path : 'offers/edit/'+offer_id
      });
    },
    showDeleteOffer : function(offer_id) {
        this.current_offer_id_to_delete = offer_id;
        this.delete_offer_modal = true;
    },
    on_offers_page_change(event) {
      this.offers_current_page = event.page;
    },
  }
}
</script>

<style>

</style>
