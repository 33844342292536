<template>

    <sidebarNav />

    <div class="page-header">
      <div class="header-left-area">
        <h1 class="page-title m-0">Dashboard</h1>
      </div>
      
      <div class="header-right-area">
        <div class="bell-info-header" @click="visibleNotificationBar = true">
          <i class="pi pi-bell"></i>
          <span class="notification-bell-icon"></span>
        </div>
        <h2 class="haeder-user-name">
          Azka Mazza
        </h2>
        <div class="user-profile-img-circle">
          <img src="../../assets/images/default.png">
        </div>
      </div>
    </div>

    <div class="dashboard-top-info-header">
      <div class="get-acccount-subscription none hidden">
        <i class="pi pi-info-circle"></i>
        <p>You are using Free Subscription right now, upgrade to get access to premium services</p>
      </div>

      <div class="reports-filter-container">
        <button-prime class="offer-filter-btn" label="month" icon="pi pi-calendar">
            <span class="p-button-icon p-button-icon-left pi">
              <img src="../../assets/images/points.svg">
            </span>
            <span class="p-button-label">Plus Member</span>
        </button-prime>

        <button-prime class="offer-filter-btn" label="Filter by date" icon="pi pi-sliders-h" @click="offerReportFilterModalVisible = true" />
      </div>
    </div>

    <div class="app-content dashboard-app-content content">
      <div class="container-fuild">
        <div class="grid m-0">
          <div class="col-12 md:col-3 lg:col-3">
            <div class="dashboard-info-card">
              <div class="card-icons">
                <img src="../../assets/images/dashboard-icons/user.svg">
              </div>
              <p>Loyal Customers this week</p>
              <h2>{{ merchant.current_week_loyal_customers }}</h2>
            </div> 
          </div>

          <div class="col-12 md:col-3 lg:col-3">
            <div class="dashboard-info-card">
              <div class="card-icons">
                <img src="../../assets/images/dashboard-icons/user.svg">
              </div>
              <p>New Customers this week</p>
              <h2>{{ merchant.current_week_customers_created }}</h2>
            </div>
          </div>

          <div class="col-12 md:col-3 lg:col-3">
            <div class="dashboard-info-card">
              <div class="card-icons">
                <img src="../../assets/images/dashboard-icons/gift.svg">
              </div>
              <p>Rewards Redeemed this week</p>
              <h2>{{ merchant.current_week_redeemed_rewards.total_count }}</h2>
              <p class="m-0">{{ merchant.current_week_redeemed_rewards.total_value }} AED of Discounts given</p>
            </div>
          </div>

          <div class="col-12 md:col-3 lg:col-3">
            <div class="dashboard-info-card">
              <div class="card-icons">
                <img src="../../assets/images/dashboard-icons/discount.svg">
              </div>
              <p>Offers Redeemed this week</p>
              <h2>{{ merchant.current_week_redeemed_offers.total_count }}</h2>
              <p class="mb-0">Total investment {{ merchant.current_week_redeemed_offers.total_value }} AED</p>
            </div>
          </div>
        </div>
        
        <div class="grid m-0">
          <div class="col-12 md:col-8 lg:col-8 pt-0 card-column-pr">
            <div class="card-inner-container">
              <h2 class="dashboard-cards-title">Your Active Rewards</h2>
              <yourActiveRewards />
            </div>
          </div>

          <div class="col-12 md:col-4 lg:col-4 pt-0 card-column-pl">
            <div class="card-inner-container">
              <h2 class="dashboard-cards-title">Your Active Offer</h2>
              <yourActiveOffers />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Notification Drawer -->
    <Drawer-prime v-model:visible="visibleNotificationBar" header="Notifications" position="right" class="notification-bar">
        <div class="notification-container">
          <ul>
            <li>
              <div class="notification-icon-box">
                <img src="../../assets/images/offer-expire.svg">
              </div>
              <div class="notification-info">
                <h2>
                  Offer Expiring Soon
                  <label>
                    11:00 pm
                  </label>
                </h2>
                <p>Your Active offer is expiring soon, add new to attract buyers</p>
                <div class="notification-date">
                  <span>Expires 05/06/2024</span>
                </div>
              </div>
            </li>

            <li>
              <div class="notification-icon-box">
                <img src="../../assets/images/offre-redeeme.svg">
              </div>
              <div class="notification-info">
                <h2>
                  Reward Redeemed
                  <label>
                    11:00 pm
                  </label>
                </h2>
                <p>50% Discount on Meal redeemed by mostafa</p>
                <!-- <div class="notification-date">
                  <span>Expires 05/06/2024</span>
                </div> -->
              </div>
            </li>
          </ul>
        </div>
    </Drawer-prime>
    <!-- / Notification Drawer -->
</template>

<script setup>
import { ref } from "vue";
const visibleNotificationBar = ref(false);
</script>
  
<script>
import sidebarNav from '../sharedViews/sharedView.vue';
import yourActiveRewards from '../dashboard/your-active-rewards.vue'
import yourActiveOffers from '../dashboard/your-active-offers.vue'
import axios from 'axios'
// import moment from 'moment'
export default {
  components: {
    sidebarNav,
    yourActiveRewards,
    yourActiveOffers
  },
  data() {
    return {
        merchant : {
          current_week_customers_created : 0,
          current_week_loyal_customers : 0,
          current_week_redeemed_offers : {
            total_count : 0,
            total_value : 0
          },
          current_week_redeemed_rewards : {
            total_count : 0,
            total_value : 0
          }
        }
    }
  },
  computed: {
    
  },
  mounted(){
    this.dashboard_data()
  },
  methods : {
    dashboard_data() {
      axios.post(this.api_url+'merchant/merchant-dashboard',
        {type : 'active'},
        {
          headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
          }
        }).then(response => {
          setTimeout(() => {
            console.log('response',response)
            this.merchant = response.data.data
          }, 1000);
        });
    }
  } 
}
</script>

<style>

</style>
  