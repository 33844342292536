<template>

    <sidebarNav />
  
    <div class="page-header">
      <div class="header-left-area">
        <h1 class="page-title m-0">Users Activity</h1>
      </div>
      
      <div class="header-right-area"></div>
    </div>
  
    <div class="app-content content">
      <div class="container-fuild listing-container users-activity-container">
        <div class="user-activity-inner mt-1">
   
            <Tabs-prime value="all-customers">
                <TabList-prime>
                    <!-- <Tab-prime value="recent-activities" @click="set_tabs('recent-activities')">Recent Activities</Tab-prime> -->
                    <Tab-prime value="all-customers" @click="set_tabs('all-customers')">All Customers</Tab-prime>
                    <Tab-prime value="new-customers" @click="set_tabs('new-customers')">New Customers</Tab-prime>
                </TabList-prime>
  
                <TabPanels-prime>
                    <TabPanel-prime value="recent-activities" v-if="customer_type=='recent-activities'">
                        <div class="grid m-0">
                            <div class="col-12 md:col-6 lg:col-6 ps-0 activity-customer-contanier">
                                <div class="card-border-box">
                                    <span class="customer-header-filter">
                                        <h2 v-if="customers.length>0">Customers</h2>
                                        <h2 v-else>Customers not found!</h2>
                                        <button-prime class="filter-btn" label="Filter" icon="pi pi-sliders-h" />
                                    </span>

                                    <div id="users-list" class="user-list-wrapper list-group ps">
                                        <ul class="users-list list media-list">
                                            <li v-for="item in customers" :key="item" @click="setActiveItem(item)" :class="{ active: item.id == customer.id }" >
                                                <span class="avatar">
                                                    <img height="42" width="42" alt="Generic placeholder image" src="../../assets/images/avatar/avatar-1.png">
                                                </span>
                                                <div class="user-info flex-grow-1">
                                                    <h5 class="mb-0">{{ item.name }}</h5>
                                                    <p class="card-text text-truncate"> {{item.email}} </p>
                                                </div>
                                            </li>
                                           

                                            
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 md:col-6 lg:col-6 pe-0 users-timeline-container" v-if="customer!=null">
                                <div class="card-border-box">
                                    <span class="customer-header-filter">
                                        <h2>Activity Timeline</h2>
                                    </span>

                                    <div class="activity-box-container mb-4">
                                        <swiper 
                                        :slides-per-view="4" 
                                        :space-between="12" 
                                        :navigation="true" 
                                        :loop="true" 
                                        :pagination="pagination" 
                                        :modules="modules" 
                                        :breakpoints="{
                                            320 : { slidesPerView: 1, spaceBetween: 12 },
                                            767 : { slidesPerView: 1, spaceBetween: 12 },
                                            991 : { slidesPerView: 2, spaceBetween: 12 },
                                            1024 : { slidesPerView: 2, spaceBetween: 12 },
                                            1199 : { slidesPerView: 3, spaceBetween: 12 },
                                            1440 : { slidesPerView: 4, spaceBetween: 12 },
                                        }" @swiper="onSwiper" @slideChange="onSlideChange">
                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon1.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{customer.total_rewards_redeemed}}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total Rewards Redeemed</h4>
                                                </div>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon4.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{ customer.total_offers_redeemed }}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total Offers Redeemed</h4>
                                                </div>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon4.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{customer.total_visits_and_stamps.total_visits}}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total Visits</h4>
                                                </div>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon3.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{customer.total_visits_and_stamps.total_collected_stamps}}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total collected stamps</h4>
                                                </div>
                                            </swiper-slide>

                                           
                                        </swiper>
                                    </div>

                                    <Tabs-prime value="0">
                                        <TabList-prime class="activity-timeline-inner-tabs">
                                            <Tab-prime class="timeline-history-tab" value="0">History</Tab-prime>
                                            <Tab-prime value="1">Profile</Tab-prime>

                                            <span class="offer-header-filters timeline-history-filter">
                                                <button-prime class="offer-filter-btn" label="Filter by date" icon="pi pi-sliders-h" />
                                            </span>
                                        </TabList-prime>
                        
                                        <TabPanels-prime>
                                            <TabPanel-prime value="0">
                                                <div class="timeline-history-tab-container">
                                                    <h2 class="timeline-history-tab-title">Redeems History</h2>

                                                    <template v-if="customer!=null">
                                                   
                                                    <div class="redeems-history-listing" v-for="item in customer.redeem_history" :key="item">
                                                        <div class="redeem-history-row">
                                                            <div class="redeem-history-column">
                                                                <div class="redeem-history-column-inner">
                                                                    <div class="redeem-history-items">
                                                                        <p class="redeem-number" v-if="item.merchant_id!=null">{{ item.stamps==null ? 0 : item.stamps }}</p>
                                                                        <p class="redeem-number" v-else>{{ item.no_of_stamps_target }}</p>
                                                                        <label class="redeem-label">
                                                                            stamps
                                                                        </label>
                                                                    </div> 
                                                                    <div class="redeem-history-items">
                                                                        <p class="redeem-number" v-if="item.merchant_id==null">01</p>
                                                                        <p class="redeem-number" v-else>00</p>
                                                                        <label class="redeem-label">
                                                                            reward
                                                                        </label>
                                                                    </div> 
                                                                    <!-- <div class="redeem-history-items">
                                                                        <p class="redeem-number">01</p>
                                                                        <label class="redeem-label">
                                                                            offer
                                                                        </label>
                                                                    </div>  -->
                                                                    <div class="redeem-history-items right">
                                                                        <p class="redeem-number">{{ item.formatted_time }}</p>
                                                                        <label class="redeem-label">
                                                                            visited: {{ item.formatted_date }}
                                                                        </label>
                                                                    </div> 
                                                                </div>
                                                                <div class="redeem-history-column-inner" v-if="item.merchant_id==null">
                                                                    <div class="redeem-history-items redeem-offer-history-row">
                                                                        <p class="redeem-number">Redeemed Offer</p>
                                                                        <label class="redeem-label redeem-label-badge">
                                                                            {{ item.reward_name }}
                                                                        </label>
                                                                    </div> 
                                                                </div>
                                                            </div>

                                                            <div class="redeem-history-column">
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </template>
                                                </div>
                                            </TabPanel-prime>
                        
                                            <TabPanel-prime value="1">
                                                <div class="timeline-profile-tab-container">
                                                    <!-- <div class="progressbar-box">
                                                        <h2>Available Points</h2>
                                                        <progressBar-prime :value="40"> {{ value }} </progressBar-prime>
                                                    </div> -->

                                                    <div class="progressbar-box">
                                                        <h2 class="mb-0"> Stamps</h2>
                                                        <div class="available-stamp-box">
                                                            <div class="icon-number">
                                                                <div class="activity-logo">
                                                                    <img src="../../assets/images/timeline-activity-icons/icon3.svg">
                                                                </div>
                                                                <h4 class="activity-name">{{ customer.available_stamps }}</h4>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <h2 class="timeline-profile-tab-title">Rewards to redeem</h2>

                                                    <div class="listing-item-container rewards-to-redeem-item-container">
                                                        <div class="listing-item-box">
                                                            <div class="flex-list-item offer-img-area">
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="offer-title-name">
                                                                    50% off on Chicken Rice
                                                                </span>
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    All 20 stamps collected
                                                                </span>
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    Expires
                                                                </span>

                                                                <span class="flex-list-text">
                                                                    05/06/2024
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div class="listing-item-box">
                                                            <div class="flex-list-item offer-img-area">
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="offer-title-name">
                                                                    Free Shisha
                                                                </span>
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    All 2000 points collected
                                                                </span>
                                                            </div>
                                                            
                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    Expires
                                                                </span>

                                                                <span class="flex-list-text">
                                                                    01/11/2024
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel-prime>
                                        </TabPanels-prime>
                                    </Tabs-prime>
                                </div>
                            </div>
                        </div>
                    </TabPanel-prime>
                    <TabPanel-prime value="all-customers" v-if="customer_type=='all-customers'">
                        <div class="grid m-0">
                            <div class="col-12 md:col-6 lg:col-6 ps-0 activity-customer-contanier">
                                <div class="card-border-box">
                                    <span class="customer-header-filter">
                                        <h2 v-if="customers.length>0">Customers</h2>
                                        <h2 v-else>Customers not found!</h2>
                                        <button-prime class="filter-btn" label="Filter" icon="pi pi-sliders-h" />
                                    </span>

                                    <div id="users-list" class="user-list-wrapper list-group ps">
                                        <ul class="users-list list media-list">
                                            <li v-for="item in customers" :key="item" @click="setActiveItem(item)" :class="{ active: item.id == customer.id }" >
                                                <span class="avatar">
                                                    <img height="42" width="42" alt="Generic placeholder image" src="../../assets/images/avatar/avatar-1.png">
                                                </span>
                                                <div class="user-info flex-grow-1">
                                                    <h5 class="mb-0">{{ item.name }}</h5>
                                                    <p class="card-text text-truncate"> {{item.email}} </p>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 md:col-6 lg:col-6 pe-0 users-timeline-container" v-if="customer!=null">
                                <div class="card-border-box">
                                    <span class="customer-header-filter">
                                        <h2>Activity Timeline</h2>
                                    </span>

                                    <div class="activity-box-container mb-4">
                                        <swiper 
                                        :slides-per-view="4" 
                                        :space-between="12" 
                                        :navigation="true" 
                                        :loop="true" 
                                        :pagination="pagination" 
                                        :modules="modules" 
                                        :breakpoints="{
                                            320 : { slidesPerView: 1, spaceBetween: 12 },
                                            767 : { slidesPerView: 1, spaceBetween: 12 },
                                            991 : { slidesPerView: 2, spaceBetween: 12 },
                                            1024 : { slidesPerView: 2, spaceBetween: 12 },
                                            1199 : { slidesPerView: 3, spaceBetween: 12 },
                                            1440 : { slidesPerView: 4, spaceBetween: 12 },
                                        }" @swiper="onSwiper" @slideChange="onSlideChange">
                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon1.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{customer.total_rewards_redeemed}}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total Rewards Redeemed</h4>
                                                </div>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon4.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{ customer.total_offers_redeemed }}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total Offers Redeemed</h4>
                                                </div>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon4.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{customer.total_visits_and_stamps.total_visits}}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total Visits</h4>
                                                </div>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon3.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{customer.total_visits_and_stamps.total_collected_stamps}}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total collected stamps</h4>
                                                </div>
                                            </swiper-slide>

                                           
                                        </swiper>
                                    </div>

                                    <Tabs-prime value="0">
                                        <TabList-prime class="activity-timeline-inner-tabs">
                                            <Tab-prime class="timeline-history-tab" value="0">History</Tab-prime>
                                            <Tab-prime value="1">Profile</Tab-prime>

                                            <span class="offer-header-filters timeline-history-filter">
                                                <button-prime class="offer-filter-btn" label="Filter by date" icon="pi pi-sliders-h" />
                                            </span>
                                        </TabList-prime>
                        
                                        <TabPanels-prime>
                                            <TabPanel-prime value="0">
                                                <div class="timeline-history-tab-container">
                                                    <h2 class="timeline-history-tab-title">Redeems History</h2>

                                                    <template v-if="customer!=null">
                                                   
                                                    <div class="redeems-history-listing" v-for="item in customer.redeem_history" :key="item">
                                                        <div class="redeem-history-row">
                                                            <div class="redeem-history-column">
                                                                <div class="redeem-history-column-inner">
                                                                    <div class="redeem-history-items">
                                                                        <p class="redeem-number" v-if="item.merchant_id!=null">{{ item.stamps==null ? 0 : item.stamps }}</p>
                                                                        <p class="redeem-number" v-else>{{ item.no_of_stamps_target }}</p>
                                                                        <label class="redeem-label">
                                                                            stamps
                                                                        </label>
                                                                    </div> 
                                                                    <div class="redeem-history-items">
                                                                        <p class="redeem-number" v-if="item.merchant_id==null">01</p>
                                                                        <p class="redeem-number" v-else>00</p>
                                                                        <label class="redeem-label">
                                                                            reward
                                                                        </label>
                                                                    </div> 
                                                                    <!-- <div class="redeem-history-items">
                                                                        <p class="redeem-number">01</p>
                                                                        <label class="redeem-label">
                                                                            offer
                                                                        </label>
                                                                    </div>  -->
                                                                    <div class="redeem-history-items right">
                                                                        <p class="redeem-number">{{ item.formatted_time }}</p>
                                                                        <label class="redeem-label">
                                                                            visited: {{ item.formatted_date }}
                                                                        </label>
                                                                    </div> 
                                                                </div>
                                                                <div class="redeem-history-column-inner" v-if="item.merchant_id==null">
                                                                    <div class="redeem-history-items redeem-offer-history-row">
                                                                        <p class="redeem-number">Redeemed Offer</p>
                                                                        <label class="redeem-label redeem-label-badge">
                                                                            {{ item.reward_name }}
                                                                        </label>
                                                                    </div> 
                                                                </div>
                                                            </div>

                                                            <div class="redeem-history-column">
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </template>
                                                </div>
                                            </TabPanel-prime>
                        
                                            <TabPanel-prime value="1">
                                                <div class="timeline-profile-tab-container">
                                                    <!-- <div class="progressbar-box">
                                                        <h2>Available Points</h2>
                                                        <progressBar-prime :value="40"> {{ value }} </progressBar-prime>
                                                    </div> -->

                                                    <div class="progressbar-box">
                                                        <h2 class="mb-0"> Stamps</h2>
                                                        <div class="available-stamp-box">
                                                            <div class="icon-number">
                                                                <div class="activity-logo">
                                                                    <img src="../../assets/images/timeline-activity-icons/icon3.svg">
                                                                </div>
                                                                <h4 class="activity-name">{{ customer.available_stamps }}</h4>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <h2 class="timeline-profile-tab-title">Rewards to redeem</h2>

                                                    <div class="listing-item-container rewards-to-redeem-item-container">
                                                        <div class="listing-item-box">
                                                            <div class="flex-list-item offer-img-area">
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="offer-title-name">
                                                                    50% off on Chicken Rice
                                                                </span>
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    All 20 stamps collected
                                                                </span>
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    Expires
                                                                </span>

                                                                <span class="flex-list-text">
                                                                    05/06/2024
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div class="listing-item-box">
                                                            <div class="flex-list-item offer-img-area">
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="offer-title-name">
                                                                    Free Shisha
                                                                </span>
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    All 2000 points collected
                                                                </span>
                                                            </div>
                                                            
                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    Expires
                                                                </span>

                                                                <span class="flex-list-text">
                                                                    01/11/2024
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel-prime>
                                        </TabPanels-prime>
                                    </Tabs-prime>
                                </div>
                            </div>
                        </div>
                    </TabPanel-prime>
                    <TabPanel-prime value="new-customers" v-if="customer_type=='new-customers'">
                        <div class="grid m-0">
                            <div class="col-12 md:col-6 lg:col-6 ps-0 activity-customer-contanier">
                                <div class="card-border-box">
                                    <span class="customer-header-filter">
                                        <h2 v-if="customers.length>0">Customers</h2>
                                        <h2 v-else>Customers not found!</h2>
                                        <button-prime class="filter-btn" label="Filter" icon="pi pi-sliders-h" />
                                    </span>

                                    <div id="users-list" class="user-list-wrapper list-group ps">
                                        <ul class="users-list list media-list">
                                            <li v-for="item in customers" :key="item" @click="setActiveItem(item)" :class="{ active: item.id == customer.id }" >
                                                <span class="avatar">
                                                    <img height="42" width="42" alt="Generic placeholder image" src="../../assets/images/avatar/avatar-1.png">
                                                </span>
                                                <div class="user-info flex-grow-1">
                                                    <h5 class="mb-0">{{ item.name }}</h5>
                                                    <p class="card-text text-truncate"> {{item.email}} </p>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 md:col-6 lg:col-6 pe-0 users-timeline-container" v-if="customer!=null">
                                <div class="card-border-box">
                                    <span class="customer-header-filter">
                                        <h2>Activity Timeline</h2>
                                    </span>

                                    <div class="activity-box-container mb-4">
                                        <swiper 
                                        :slides-per-view="4" 
                                        :space-between="12" 
                                        :navigation="true" 
                                        :loop="true" 
                                        :pagination="pagination" 
                                        :modules="modules" 
                                        :breakpoints="{
                                            320 : { slidesPerView: 1, spaceBetween: 12 },
                                            767 : { slidesPerView: 1, spaceBetween: 12 },
                                            991 : { slidesPerView: 2, spaceBetween: 12 },
                                            1024 : { slidesPerView: 2, spaceBetween: 12 },
                                            1199 : { slidesPerView: 3, spaceBetween: 12 },
                                            1440 : { slidesPerView: 4, spaceBetween: 12 },
                                        }" @swiper="onSwiper" @slideChange="onSlideChange">
                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon1.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{customer.total_rewards_redeemed}}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total Rewards Redeemed</h4>
                                                </div>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon4.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{ customer.total_offers_redeemed }}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total Offers Redeemed</h4>
                                                </div>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon4.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{customer.total_visits_and_stamps.total_visits}}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total Visits</h4>
                                                </div>
                                            </swiper-slide>

                                            <swiper-slide>
                                                <div class="activity-box-item">
                                                    <div class="icon-number">
                                                        <div class="activity-logo">
                                                            <img src="../../assets/images/timeline-activity-icons/icon3.svg">
                                                        </div>
                                                        <h4 class="activity-name">{{customer.total_visits_and_stamps.total_collected_stamps}}</h4>
                                                    </div>
                                                    <h4 class="activity-name">Total collected stamps</h4>
                                                </div>
                                            </swiper-slide>

                                           
                                        </swiper>
                                    </div>

                                    <Tabs-prime value="0">
                                        <TabList-prime class="activity-timeline-inner-tabs">
                                            <Tab-prime class="timeline-history-tab" value="0">History</Tab-prime>
                                            <Tab-prime value="1">Profile</Tab-prime>

                                            <span class="offer-header-filters timeline-history-filter">
                                                <button-prime class="offer-filter-btn" label="Filter by date" icon="pi pi-sliders-h" />
                                            </span>
                                        </TabList-prime>
                        
                                        <TabPanels-prime>
                                            <TabPanel-prime value="0">
                                                <div class="timeline-history-tab-container">
                                                    <h2 class="timeline-history-tab-title">Redeems History</h2>

                                                    <template v-if="customer!=null">
                                                   
                                                    <div class="redeems-history-listing" v-for="item in customer.redeem_history" :key="item">
                                                        <div class="redeem-history-row">
                                                            <div class="redeem-history-column">
                                                                <div class="redeem-history-column-inner">
                                                                    <div class="redeem-history-items">
                                                                        <p class="redeem-number" v-if="item.merchant_id!=null">{{ item.stamps==null ? 0 : item.stamps }}</p>
                                                                        <p class="redeem-number" v-else>{{ item.no_of_stamps_target }}</p>
                                                                        <label class="redeem-label">
                                                                            stamps
                                                                        </label>
                                                                    </div> 
                                                                    <div class="redeem-history-items">
                                                                        <p class="redeem-number" v-if="item.merchant_id==null">01</p>
                                                                        <p class="redeem-number" v-else>00</p>
                                                                        <label class="redeem-label">
                                                                            reward
                                                                        </label>
                                                                    </div> 
                                                                    <!-- <div class="redeem-history-items">
                                                                        <p class="redeem-number">01</p>
                                                                        <label class="redeem-label">
                                                                            offer
                                                                        </label>
                                                                    </div>  -->
                                                                    <div class="redeem-history-items right">
                                                                        <p class="redeem-number">{{ item.formatted_time }}</p>
                                                                        <label class="redeem-label">
                                                                            visited: {{ item.formatted_date }}
                                                                        </label>
                                                                    </div> 
                                                                </div>
                                                                <div class="redeem-history-column-inner" v-if="item.merchant_id==null">
                                                                    <div class="redeem-history-items redeem-offer-history-row">
                                                                        <p class="redeem-number">Redeemed Offer</p>
                                                                        <label class="redeem-label redeem-label-badge">
                                                                            {{ item.reward_name }}
                                                                        </label>
                                                                    </div> 
                                                                </div>
                                                            </div>

                                                            <div class="redeem-history-column">
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </template>
                                                </div>
                                            </TabPanel-prime>
                        
                                            <TabPanel-prime value="1">
                                                <div class="timeline-profile-tab-container">
                                                    <!-- <div class="progressbar-box">
                                                        <h2>Available Points</h2>
                                                        <progressBar-prime :value="40"> {{ value }} </progressBar-prime>
                                                    </div> -->

                                                    <div class="progressbar-box">
                                                        <h2 class="mb-0"> Stamps</h2>
                                                        <div class="available-stamp-box">
                                                            <div class="icon-number">
                                                                <div class="activity-logo">
                                                                    <img src="../../assets/images/timeline-activity-icons/icon3.svg">
                                                                </div>
                                                                <h4 class="activity-name">{{ customer.available_stamps }}</h4>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <h2 class="timeline-profile-tab-title">Rewards to redeem</h2>

                                                    <div class="listing-item-container rewards-to-redeem-item-container">
                                                        <div class="listing-item-box">
                                                            <div class="flex-list-item offer-img-area">
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="offer-title-name">
                                                                    50% off on Chicken Rice
                                                                </span>
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    All 20 stamps collected
                                                                </span>
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    Expires
                                                                </span>

                                                                <span class="flex-list-text">
                                                                    05/06/2024
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div class="listing-item-box">
                                                            <div class="flex-list-item offer-img-area">
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="offer-title-name">
                                                                    Free Shisha
                                                                </span>
                                                            </div>

                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    All 2000 points collected
                                                                </span>
                                                            </div>
                                                            
                                                            <div class="flex-list-item">
                                                                <span class="flex-list-text flex-text-grey">
                                                                    Expires
                                                                </span>

                                                                <span class="flex-list-text">
                                                                    01/11/2024
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel-prime>
                                        </TabPanels-prime>
                                    </Tabs-prime>
                                </div>
                            </div>
                        </div>
                    </TabPanel-prime>
  
                </TabPanels-prime>
            </Tabs-prime>
        </div>
      </div>
    </div>
  
    <!-- Republish Modal -->
    <!-- <dialog-prime class="fields-option-modal" v-model:visible="republishedVisible" modal header="Republish offer" :style="{ width: '25rem' }">
          <div class="form-group">
              <label for="username" class="form-label">offer Title</label>
              <inputText-prime type="text" placeholder="Enter offer Title" />
          </div>
  
          <div class="form-group">
              <label for="email" class="form-label">Expiry Date</label>
              <DatePicker-prime v-model="date" placeholder="DD/MM/YYYY" />
          </div>
  
          <div class="form-group flex justify-content-center w-full mt-4 mb-2">
              <button-prime type="button" class="published-offer-popup" label="Publish offer" @click="republishedVisible = false"></button-prime>
          </div>
      </dialog-prime> -->
      <!-- / Republish Modal -->
  
  </template>
  
  <script setup>
//   import { ref } from "vue";
  
//   const value = ref(10000);
//   const value1 = ref(20);
  </script>
  
<script>
import sidebarNav from '../sharedViews/sharedView.vue';

  // import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import axios from 'axios'
// import moment from 'moment'
  
  export default {
    components: {
        sidebarNav,
        Swiper,
        SwiperSlide,
    },
    mounted() {
        this.get_merchant_customers();
    },
    data() {
      return {
        modules: [Navigation, Pagination, Autoplay, Scrollbar,],
        customers : [],
        customer : null,
        customer_type : 'all-customers'
      }
    },
    methods: {
        set_tabs(data) {
            this.customer_type = data;
            this.customers = [];
            this.customer = null;
            this.get_merchant_customers();
        },
        setActiveItem(data){
            this.customer = data;
        },
        get_merchant_customers() {
         axios.post(this.api_url+'merchant/get-merchant-customers',
            {customer_type : this.customer_type},
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
            }).then(response => {
            
                this.customers = response.data.data;
                if (this.customers.length > 0) {
                    this.customer = this.customers[0];
                }
               
            });
        },
    } 
  }
  </script>
  
  <style>
  
  </style>
  