<template>
    <Header></Header>

    <div class="signin-wrapper register-wrapper">
        <div class="grid mr-0 ml-0 category-wrapper" v-if="step=='categories'">
            <div class="col-12 md:col-12 lg:col-12">

                <div class="welcome-text-sign">
                    <h2>What is your service Category?</h2>
                </div>

                <div class="select-category-container">
                    <div class="select-category-box" v-for="item in merchant_categories" :key="item" @click="select_categories(item)">
                        <span  :class="{
                            'flex': merchant.selected_merchant_categories.includes(item.id), // Condition to apply 'flex' class
                            'selected-check': true, // Always apply this class
                            'align-items-center': true, // Always apply this class
                            'justify-content-center': true // Always apply this class
                            }"><i class="pi pi-check"></i></span>
                        <img class="category-icons" :src="item.web_app_image">
                        <h4>{{ item.name }}</h4>
                    </div>

                    
                </div>

                <div class="form-fields-main text-center mb-0 mt-2 w-full mb-3">
                    <button-prime class="forgot-pass-btn mb-2" label="Continue" @click="change_step('details')" rounded />
                </div>
            </div>
        </div>

        <div class="grid m-0" v-if="step=='details'">
            <div class="col-12 md:col-6 lg:col-6 signIn-form-rightArea">

                <div class="welcome-text-sign">
                    <h2>Merchant Details</h2>
                    <p>Use your credentials below to request your registration as a Merchant</p>
                </div>
                
                <div class="sign-form-area" id="signIn-from">
                    <div class="form-group m-0">
                        <label class="form-label">Contact Details</label>
                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/sms.svg">
                                <inputText-prime class="form-input-fields" v-model="merchant.email" type="email" placeholder="Enter Your Email" />
                            </span>
                        </div>
                        
                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <vue-tel-input v-model="merchant.mobile" mode="international" placeholder="Write your mobile number"></vue-tel-input>
                            </span>
                        </div>
                    </div>

                    <div class="form-group m-0 mt-4">
                        <label class="form-label">Brand Details</label>
                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/name.svg">
                                <inputText-prime v-model="merchant.brand_name" class="form-input-fields" placeholder="Enter Brand Name" />
                            </span>
                        </div>
                        
                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/category.svg">
                                <inputText-prime type="number" :min="1" v-model="merchant.no_of_branches" class="form-input-fields" placeholder="No of Branches" />
                            </span>
                        </div>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/location.svg">
                                <select-prime filter v-model="merchant.selected_emirate" :options="emirates" optionLabel="name" placeholder="Select Emirate" class="form-input-fields w-full" />
                            </span>
                        </div>

                        <div class="form-fields-main mb-3">
                            <span class="p-input-icon-left">
                                <img class="inputFields-icons" src="../../assets/images/settings-icons/location.svg">
                                <inputText-prime v-model="merchant.address" class="form-input-fields" placeholder="Address" />
                            </span>
                        </div>
                    </div>

                    <div class="form-group m-0 mt-4 mb-3">
                        <label class="form-label">Trade License</label>
                        <input type="file" @change="handleFileUpload" class="hidden" id="trade-license">

                        <div class="upload-box-container">
                            <label for="trade-license" class="trade-license-label">
                                <div class="upload-box">
                                    <i class="pi pi-plus"></i>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div class="info-text-label" v-if="merchant.file_name==null">
                        <i class="pi pi-info-circle"></i>
                        <p>Upload your Trade License in pdf form</p>
                    </div>
                    <div class="info-text-label" v-if="merchant.file_name!=null">
                        <p>{{ merchant.file_name }} {{ merchant.file_size }}</p>
                    </div>
                </div>

                <div class="form-fields-main text-center mb-0 mt-2 w-full">
                    <button-prime class="forgot-pass-btn mb-2" label="Apply for Registration" @click="create_merchant" :loading="merchant.loading" rounded />
                </div>
            </div>
            
            <div class="col-12 md:col-6 lg:col-6 signIn-Info-leftArea register-Info-leftArea">
                <div class="register-right-section-inner">
                    <div class="welcome-text-sign">
                        <h2>Join Wafa and grow your <br /> Business with available <br /> featuers</h2>
                    </div>

                    <ul class="register-list-ul">
                        <li>
                            <img src="../../assets/images/daimond.svg">
                            <p>Loyalty Programs & Rewards</p>
                        </li>
                        <li>
                            <img src="../../assets/images/daimond.svg">
                            <p>Exclusive Offers Creation</p>
                        </li>
                        <li>
                            <img src="../../assets/images/daimond.svg">
                            <p>Analytical Dashboard</p>
                        </li>
                        <li>
                            <img src="../../assets/images/daimond.svg">
                            <p>Customers Details Platform</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <toast-prime />
    </div>
</template>

<script>
import Header from '../Header/HeaderView.vue'
import axios from 'axios'
// import moment from 'moment'

export default {
    components: {
      Header
    },
    data() {
      return {
        phone_number:"",
        merchant_categories : [],
        emirates : [],
        merchant : {
            selected_merchant_categories : [],
            selected_emirate : null,
            email : null,
            mobile : null,
            brand_name : null,
            no_of_branches : 1,
            address : null,
            file: null,
            file_name: null,
            file_size: null,
            loading : false,
            user_type : 'merchant',
            password : 'system_gen@2024',
            password_confirmation : 'system_gen@2024'
        },
        selectedEmirates: null,
        step : 'categories'
      }
    },
    mounted() {
        this.get_merchant_categories();
        this.get_emirates();
    },
    methods : {
        create_merchant() {
            if (this.merchant.email == null) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Contact email required!', life: 3000 });
                return false;
            }
            if (this.merchant.mobile == null || this.merchant.mobile == '') {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Contact phone required!', life: 3000 });
                return false;
            }
            if (this.merchant.brand_name == null) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Brand name required!', life: 3000 });
                return false;
            }
            if (this.merchant.no_of_branches == null || this.merchant.no_of_branches == '' || this.merchant.no_of_branches == 0) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'No. of branches required!', life: 3000 });
                return false;
            }
            if (this.merchant.selected_emirate == null) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Emirate required!', life: 3000 });
                return false;
            }
            if (this.merchant.address == null) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Address required!', life: 3000 });
                return false;
            }
            if (this.merchant.file == null) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Trade license file required!', life: 3000 });
                return false;
            }
            
            this.merchant.loading = true;
            axios.post(this.api_url+'create-user-as-merchant', 
                this.merchant,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then(response => {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: error.response.data.errors, life: 3000 });
                    } 
                }).finally(() => {
                    this.merchant.loading = false;
                });
        },
        handleFileUpload(event) {
            const file = event.target.files[0];

            if (file) {
                // Validate file type (PDF only)
                if (file.type !== 'application/pdf') {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Only PDF files are allowed!', life: 3000 });
                    return;
                }

                // Validate file size (max 5MB)
                const maxSize = 5 * 1024 * 1024; // 5MB in bytes
                if (file.size > maxSize) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'File size should not exceed 5MB!', life: 3000 });
                    return;
                }

               
                // Set file details
                this.merchant.file = file;
                this.merchant.file_name = file.name;
                this.merchant.file_size = this.formatFileSize(file.size);

                // Generate PDF thumbnail
                // this.generateThumbnail(file);
            }
        },
        formatFileSize(size) {
        if (size < 1024) {
            return size + ' Bytes';
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else {
            return (size / (1024 * 1024)).toFixed(2) + ' MB';
        }
        },
        get_merchant_categories(){
            axios.post(this.api_url+'get-merchant-categories').then(response => {
                this.merchant_categories = response.data.data;
            });
        },
        select_categories(item) {
            const index = this.merchant.selected_merchant_categories.indexOf(item.id);
            if (index === -1) {
                this.merchant.selected_merchant_categories.push(item.id);
            } else {
                this.merchant.selected_merchant_categories.splice(index, 1);
            }
        },
        change_step(step) {
            if (step == 'details') {
                if(this.merchant.selected_merchant_categories.length==0) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Category required!', life: 3000 });
                    return false;
                }
                
            }
            this.step = step;
            scrollTo(0, 0);
        },
        get_emirates() {
            axios.post(this.api_url+'get-emirates').then(response => {
                this.emirates = response.data.data;
            });
        },
    }
}
</script>
