<template>
  <Header />

  <main>
    <div class="main-content home-content about-main-content">
      <section class="main-banner-area about-wafa-main-banner-area">
        <div class="container">
          <div class="grid w-full m-0">
            <div class="col-12 md:col-6 lg:col-6 banner-left-column">
              <h1 class="section-title">Enjoy Exclusive <span>Rewards</span> & <span>Offers</span> With Wafa</h1>
              <p class="section-desc">Now you can enjoy amazing offers and rewards from your favorite merchants with just one click.</p>

              <ul class="app-btn-ul">
                <li class="app-download-btn">
                  <div class="app-icon">
                    <img src="../assets/images/apple.svg" />
                  </div>
                  <div class="app-btn-info">
                    <p>Download app on</p>
                    <h4>App Store</h4>
                  </div>
                </li>
                <li class="app-download-btn">
                  <div class="app-icon">
                    <img src="../assets/images/googleplay.svg" />
                  </div>
                  <div class="app-btn-info">
                    <p>Download app on</p>
                    <h4>Google Play</h4>
                  </div>
                </li>
              </ul>
            </div>

            <div class="col-12 md:col-6 lg:col-6 banner-right-column">
              <img src="../assets/images/aboutWafa-bg.png" />
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding app-info-area-section">
        <div class="container">
          <div class="grid w-full">
            <div class="col-12 md:col-4 lg:col-4 app-info-column">
              <h2>10+</h2>
              <p>Registered Merchants in UAE offering variety of offers and rewards</p>
            </div>

            <div class="col-12 md:col-4 lg:col-4 app-info-column">
              <h2>2000+</h2>
              <p>UAE residents and non-UAE residents loyal Customers</p>
            </div>

            <div class="col-12 md:col-4 lg:col-4 app-info-column">
              <h2>100+</h2>
              <p>Successfully managed programs and offers</p>
            </div>
          </div>
        </div> 
      </section>

      <section class="section-padding why-join-wafa">
        <div class="container">
          <h1 class="section-title">
            Wafa Key Features
            <img src="../assets/images/wafa-logo-icon.svg">
          </h1>

          <div class="col-12 p-0">
            <swiper :slides-per-view="3" :space-between="0" setWrapperSize="ture" :navigation="false"  :loop="true"
                :pagination="pagination" :modules="modules" :autoplay="{
                  delay: 3500,
                  disableOnInteraction: false,
                }" :breakpoints="{
                        '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                        '@0.75': { slidesPerView: 1, spaceBetween: 0 },
                        '@1.00': { slidesPerView: 2, spaceBetween: 0 },
                        '@1.50': { slidesPerView: 3, spaceBetween: 0 },
                }" @swiper="onSwiper" @slideChange="onSlideChange">
                <swiper-slide>
                  <div class="col-12 features-slide-one">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">All-In-One Convenience</h4>
                        <p class="info-box-description">
                          No need to juggle multiple apps. This platform consolidates all rewards and offers from various merchants into one easy-to-use app.
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12 features-slide-two">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">Exclusive Reward Programs</h4>
                        <p class="info-box-description">
                          Unlike generic loyalty apps, this platform offers rewards tailored to individual preferences, ensuring customers receive offers that truly matter to them.
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12 features-slide-three">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">Unique Loyalty Experience</h4>
                        <p class="info-box-description">
                          Unlike other loyalty apps, this platform offers a seamless, all-in-one solution for discovering and redeeming rewards.
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12 features-slide-four">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">Instant Updates</h4>
                        <p class="info-box-description">
                          Stay ahead with real-time notifications on the latest deals and offers, ensuring customers never miss out on opportunities.
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>

      <section class="about-how-it-works-section">
        <h1 class="section-title how-it-works-sec-title">
            How it Works
            <img src="../assets/images/wafa-logo-icon.svg">
        </h1>
        <section class="section-padding how-it-works-section">
          <div class="container-fluid p-0">
              <div class="grid w-full m-0">
                <swiper :slides-per-view="1" :space-between="0" setWrapperSize="ture" :navigation="false"  :loop="true"
                    :pagination="true" :effect="'fade'" :modules="modules" :autoplay="{
                      delay: 3500,
                      disableOnInteraction: false,
                    }" 
                    :breakpoints="{
                            '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                            '@0.75': { slidesPerView: 1, spaceBetween: 0 },
                            '@1.00': { slidesPerView: 1, spaceBetween: 0 },
                            '@1.50': { slidesPerView: 1, spaceBetween: 0 },
                    }" @swiper="onSwiper" @slideChange="onSlideChange">
                    <swiper-slide>
                      <div class="grid w-full m-0">
                        <div class="col-12 md:col-6 lg:col-6 how-it-works-column-info">
                          <div class="info-box-column how-works-column">
                              <h4 class="info-box-title">Buy And Earn</h4>

                              <ul>
                                <li>
                                  <div class="icon">
                                    <img src="../assets/images/wafa-logo-icon.svg">
                                  </div>
                                  <p>Buy Something from your favorite Merchant</p>
                                </li>
                                <li>
                                  <div class="icon">
                                    <img src="../assets/images/wafa-logo-icon.svg">
                                  </div>
                                  <p>Get your QR scanned or code entered by merchant</p>
                                </li>
                                <li>
                                  <div class="icon">
                                    <img src="../assets/images/wafa-logo-icon.svg">
                                  </div>
                                  <p>Earn Points or Stamps on each scan</p>
                                </li>
                              </ul>
                          </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6 p-0 how-it-works-column-info how-it-works-column-info-right">
                          <div class="image-box-area how-it-works-image1">
                            <img src="../assets/images/buy-and-earn.png">
                          </div>
                        </div>
                      </div>
                    </swiper-slide>

                    <swiper-slide>
                      <div class="grid w-full">
                        <div class="col-12 md:col-6 lg:col-6 how-it-works-column-info">
                          <div class="info-box-column how-works-column">
                              <h4 class="info-box-title">Buy a Plan with 3 Months free trial</h4>

                              <ul>
                                <li>
                                  <div class="icon">
                                    <img src="../assets/images/wafa-logo-icon.svg">
                                  </div>
                                  <p>Keep collecting stamps and points</p>
                                </li>
                                <li>
                                  <div class="icon">
                                    <img src="../assets/images/wafa-logo-icon.svg">
                                  </div>
                                  <p>You can get multiple points and stamps from multiple merchants</p>
                                </li>
                              </ul>
                          </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6 p-0 how-it-works-column-info how-it-works-column-info-right">
                          <div class="image-box-area how-it-works-image2">
                            <img src="../assets/images/coins-stamps.png">
                          </div>
                        </div>
                      </div>
                    </swiper-slide>

                    <swiper-slide>
                      <div class="grid w-full">
                        <div class="col-12 md:col-6 lg:col-6 how-it-works-column-info">
                          <div class="info-box-column how-works-column">
                              <h4 class="info-box-title">Redeem Rewards</h4>

                              <ul>
                                <li>
                                  <div class="icon">
                                    <img src="../assets/images/wafa-logo-icon.svg">
                                  </div>
                                  <p>Visit the merchant from which you have points or stamps</p>
                                </li>
                                <li>
                                  <div class="icon">
                                    <img src="../assets/images/wafa-logo-icon.svg">
                                  </div>
                                  <p>Use your collected points or stamps to redeem a reward</p>
                                </li>
                              </ul>
                          </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6 p-0 how-it-works-column-info how-it-works-column-info-right">
                          <div class="image-box-area how-it-works-image3">
                            <img src="../assets/images/Redeem-Rewards.png">
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                </swiper>
              </div>
          </div>
        </section>
      </section>

      <section class="section-padding key-features-section how-to-start-section">
        <div class="container">
          <h1 class="section-title">
            How to Start
            <img src="../assets/images/wafa-logo-icon.svg">
          </h1>
          <p>Here’s how you can become a Wafa Loyal Customer</p>
          
          <div class="grid w-full m-0 mt-5">
            <swiper :slides-per-view="1" :space-between="0" setWrapperSize="ture" :navigation="false" :loop="true"
                :pagination="true" :modules="modules" :autoplay="{
                  delay: 3500,
                  disableOnInteraction: false,
                }" :breakpoints="{
                        '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                        '@0.75': { slidesPerView: 1, spaceBetween: 0 },
                        '@1.00': { slidesPerView: 1, spaceBetween: 0 },
                        '@1.50': { slidesPerView: 1, spaceBetween: 0 },
                }" @swiper="onSwiper" @slideChange="onSlideChange">
                <swiper-slide>
                  <div class="grid m-0 w-full">
                    <div class="col-12 md:col-12 lg:col-12">
                      <h4>Register Using your email and password</h4>
                    </div>
                    <div class="col-12 md:col-12 lg:col-12 how-to-start-img">
                      <img src="../assets/images/how-start-one.png">
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="grid m-0 w-full">
                    <div class="col-12 md:col-12 lg:col-12">
                      <h4>Select Categories of your interest</h4>
                    </div>
                    <div class="col-12 md:col-12 lg:col-12 how-to-start-img">
                      <img src="../assets/images/how-start-two.png">
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="grid m-0 w-full">
                    <div class="col-12 md:col-12 lg:col-12">
                      <h4>Provide your Personal Details and Complete Registration</h4>
                    </div>
                    <div class="col-12 md:col-12 lg:col-12 how-to-start-img">
                      <img src="../assets/images/how-start-three.png">
                    </div>
                  </div>
                </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>

      <section class="section-padding why-join-wafa about-why-join-wafa">
        <div class="container">
          <h1 class="section-title">
            Why Join Wafa
            <img src="../assets/images/wafa-logo-icon.svg">
          </h1>

          <div class="col-12 md:col-12 lg:col-12 why-join-bg-img about-why-join-bg-img">
            <img src="../assets/images/join-wafa.png">
          </div>

          <div class="col-12 p-0">
            <swiper :slides-per-view="3" :space-between="0" setWrapperSize="ture" :navigation="false"  :loop="true"
                :pagination="pagination" :modules="modules" :autoplay="{
                  delay: 3500,
                  disableOnInteraction: false,
                }" :breakpoints="{
                        '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                        '@0.75': { slidesPerView: 1, spaceBetween: 0 },
                        '@1.00': { slidesPerView: 2, spaceBetween: 0 },
                        '@1.50': { slidesPerView: 3, spaceBetween: 0 },
                }" @swiper="onSwiper" @slideChange="onSlideChange">
                <swiper-slide>
                  <div class="col-12">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">Get Discount Offers</h4>
                        <p class="info-box-description">
                          Now you can get amazing discount offers from merchants providing variety of services
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">Get Exclusive Rewards</h4>
                        <p class="info-box-description">
                          Get a chance to avail discount rewards, buy 1 get 1 and even free items, visit your favorite merchant often, earn points and win amazing rewards.
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">Hustle Free All-In-One Rewards Access</h4>
                        <p class="info-box-description">
                          You can discover and access all the best rewards and offers from multiple merchants in one place, eliminating the need to check multiple apps or stores. 
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="col-12">
                    <div class="info-box-column slider-feature-column">
                      <div>
                        <div class="icon-box">
                          <img src="../assets/images/wafa-logo-icon.svg">
                        </div>
                        <h4 class="info-box-title">Instant Updates & Recommendations</h4>
                        <p class="info-box-description">
                          With Wafa, you can get instant notification updates and can easily redeem offers from your favorite stores or get recommendations on your interests
                        </p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>
      
      <section class="section-padding start-today-section">
        <div class="container">
          <h1 class="section-title">So What are you waiting for?</h1>
          <p class="section-description">Become Wafa Loyal Customer Now!</p>

          <ul class="app-btn-ul">
            <li class="app-download-btn">
              <div class="app-icon">
                <img src="../assets/images/apple.svg" />
              </div>
              <div class="app-btn-info">
                <p>Download app on</p>
                <h4>App Store</h4>
              </div>
            </li>
            <li class="app-download-btn">
              <div class="app-icon">
                <img src="../assets/images/googleplay.svg" />
              </div>
              <div class="app-btn-info">
                <p>Download app on</p>
                <h4>Google Play</h4>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </main>

  <Footer />
</template>

<script>
import Footer from './Footer/FooterView.vue'
import Header from './Header/HeaderView.vue'

// import Swiper core and required modules
import { EffectFade, Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    components: {
      Swiper,
      SwiperSlide,
      Footer,
      Header
    },
    data() {
      return {
        modules: [EffectFade, Navigation, Pagination, Autoplay, Scrollbar, A11y],
        pagination: {
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        },
      }
    },
    methods : {
      
    }
}
</script>

<script setup>
// import { ref } from "vue";
</script>

<style scoped src="../assets/css/home.css">

</style>