<template>
    <div class="signin-wrapper">
        <div class="grid m-0">
            <div class="col-12 md:col-6 lg:col-6 signIn-form-rightArea">
                <router-link to="/signin">
                    <span class="forgot-back-btn">
                        <i class="pi pi-arrow-left"></i>
                    </span>
                </router-link>
                
                <div class="logo-area">
                    <router-link to="/">
                        <img class="site-logo-spacedesign" src="../../assets/images/logo/wafa-logo.svg">
                    </router-link>
                </div>

                <div class="welcome-text-sign">
                    <h2>Change Password</h2>
                    <p>Enter your Email Address and we will send you a link to change your password</p>
                </div>

                <div class="sign-form-area" id="signIn-from">
                    <div class="form-fields-main">
                        <span class="p-input-icon-left">
                            <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.30233 22.1814H18.6977C20.7963 22.1814 22.4626 21.5773 23.6043 20.4357C24.7459 19.294 25.35 17.6277 25.35 15.5291V7.55233C25.35 5.45369 24.7459 3.78737 23.6043 2.64571C22.4626 1.50406 20.7963 0.9 18.6977 0.9H7.30233C5.20369 0.9 3.53737 1.50406 2.39571 2.64571C1.25406 3.78737 0.65 5.45369 0.65 7.55233V15.5291C0.65 17.6277 1.25406 19.294 2.39571 20.4357C3.53737 21.5773 5.20369 22.1814 7.30233 22.1814ZM2.5593 7.55233C2.5593 5.93749 2.95172 4.75702 3.72937 3.97937C4.50702 3.20172 5.68749 2.8093 7.30233 2.8093H18.6977C20.3125 2.8093 21.493 3.20172 22.2706 3.97937C23.0483 4.75702 23.4407 5.93749 23.4407 7.55233V15.5291C23.4407 17.1439 23.0483 18.3244 22.2706 19.102C21.493 19.8797 20.3125 20.2721 18.6977 20.2721H7.30233C5.68749 20.2721 4.50702 19.8797 3.72937 19.102C2.95172 18.3244 2.5593 17.1439 2.5593 15.5291V7.55233Z" fill="#8A8D90" stroke="#8A8D90" stroke-width="0.2"/>
                                <path d="M6.70343 8.86058L6.70343 8.86058L6.70408 8.8611L10.27 11.7093C10.2702 11.7094 10.2703 11.7095 10.2704 11.7096C11.0315 12.33 12.0232 12.632 12.9997 12.632C13.976 12.632 14.968 12.3302 15.718 11.7094L19.283 8.86188C19.7042 8.54189 19.7664 7.93152 19.437 7.52472C19.1167 7.10347 18.5058 7.04168 18.0991 7.37186C18.099 7.37194 18.0989 7.37202 18.0988 7.3721L14.5327 10.2204L14.5325 10.2206C14.1204 10.5513 13.5597 10.7199 12.994 10.7199C12.4283 10.7199 11.8677 10.5513 11.4556 10.2206L11.4554 10.2204L7.88933 7.3721C7.88921 7.37201 7.8891 7.37192 7.88899 7.37183C7.47934 7.0393 6.88139 7.11817 6.55214 7.52339C6.21929 7.93306 6.2981 8.53124 6.70343 8.86058Z" fill="#8A8D90" stroke="#8A8D90" stroke-width="0.2"/>
                            </svg>
                            <inputText-prime class="form-input-fields" placeholder="Enter Your Email" />
                        </span>
                    </div>
                </div>

                <div class="form-fields-main text-center mb-0 mt-2 w-full">
                    <button-prime class="forgot-pass-btn mb-2" label="Send Link" rounded />
                </div>
            </div>
            
            <div class="col-12 md:col-6 lg:col-6 signIn-Info-leftArea">
                <div class="login-img">
                    <img class="site-logo-wafaloyalty" src="../../assets/images/login-image.png">
                </div>
            </div>
        </div>
        <toast-prime />
    </div>
</template>