<template>
  <div class="reward-listing-inner active-reward-listing-inner mt-3">
  
    <div class="listing-item-container listing-item-all-container" v-if="!rewards">
        <div class="listing-box-loaders" v-for="index in 2" :key="index">
            <Skeleton-prime width="100%" height="180px"></Skeleton-prime>
            <div class="flex justify-between mt-3 mb-3">
                <Skeleton-prime class="border-1000 mr-2" width="6rem" height="2rem"></Skeleton-prime>
                <Skeleton-prime class="border-1000" width="6rem" height="2rem"></Skeleton-prime>
            </div>
            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
            <Skeleton-prime class="border-1000" width="100%" height="1rem"></Skeleton-prime>
        </div>
    </div>

    <div class="listing-item-container listing-item-all-container" v-else-if="rewards.length==0">
        <div class="not-found-data">
            <img src="../../assets/images/dummy-data-img.svg">
            <h2>No rewards available</h2>
        </div>
    </div>

    <template v-else>
        <div class="listing-item-container listing-item-all-container">
            <div class="listing-item-box" v-for="(item, index) in paginated_rewards" :key="index">
                <div class="flex-list-item reward-img-area" v-bind:style="{'background-image': 'url('+item.reward_image+')'}">
                    <div class="action-btn-dots">
                        <button-prime type="button" icon="pi pi-ellipsis-v" @click="toggle(index, $event)" aria-haspopup="true" aria-controls="overlay_menu" />
                        <menu-prime ref="menu" id="overlay_menu" class="action-menu-list" :model="item.menuItems" :popup="true" />
                    </div>
                </div>

                <div class="flex-list-item">
                    <span class="reward-title-name">
                        {{ item.name }}
                    </span>
                </div>
                
                <div class="flex-list-item justify-content-start gap-2">
                    <span class="type-badge-program reward-badge-yellow">
                        {{ item.reward_type.name }}
                    </span>

                    <span class="type-badge-program reward-badge-green">
                        {{ item.item_name }}
                    </span>
                </div>

                <div class="hr-line">
                    <hr />
                </div>

                <div class="flex-list-item">
                    <span class="flex-list-text flex-text-grey">
                        Value of Item
                    </span>

                    <span class="flex-list-text">
                        {{ item.item_value }} AED
                    </span>
                </div>

                <div class="flex-list-item">
                    <span class="flex-list-text flex-text-grey">
                        Stamps Required
                    </span>

                    <span class="flex-list-text">
                        {{ item.no_of_stamps_target }}
                    </span>
                </div>
                
                <div class="flex-list-item">
                    <span class="flex-list-text flex-text-grey">
                        Expires
                    </span>

                    <span class="flex-list-text">
                        {{ item.expiry_date }}
                    </span>
                </div>
            </div>
        </div>

        <div class="pagination-container">
            <paginator-prime v-if="rewards.length>2" :rows="rewards_rows_per_page" :totalRecords="rewards.length" :page="rewards_current_page" @page="on_rewards_page_change"></paginator-prime>
        </div>
        <div class="upgrade-plan-message-box" @click="subscriptionVisible = true">
            <h2>Upgrade your Plan for more benefits</h2>
            <p>You only have one Reward Program, Upgrade Now to add more programs</p>

            <i class="pi pi-times"></i>
        </div>
    </template>
    
</div>

    <dialog-prime class="subscription-plan-modal" v-model:visible="subscriptionVisible" modal header="New Program" :style="{ width: '36rem' }">
        
        <div class="modal-body-container">
          <div class="subscription-icon">
            <img src="../../assets/images/subscriptionIcon.svg">
          </div>
          <div class="subscription-info">
             <h2>Upgrade your Subscription Plan</h2>
             <label>Get 3 months Free Trial</label>
  
             <p>Upgrade your subscription plan to offer more offers and benefits</p>
          </div>
        </div>
  
        <div class="subscription-footer-btn w-full">
            <button-prime type="button" class="wafa-primary-outline-btn" label="Cancel" @click="subscriptionVisible = false"></button-prime>
            <button-prime type="button" class="wafa-primary-btn upgrade-btn" label="Upgrade"></button-prime>
        </div>
      </dialog-prime>
      
      
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  data() {
    return {
        rewards : null,
        rewards_rows_per_page : 2,
        rewards_current_page : 0,
        current_reward_id_to_delete : 0,
        delete_reward_modal : false,
        subscriptionVisible : false
    }
  },
  computed: {
    paginated_rewards() {
      if (!this.rewards) return [];
      const start = this.rewards_current_page * this.rewards_rows_per_page;
      const end = start + this.rewards_rows_per_page;
      return this.rewards.slice(start, end);
    },
  },
  mounted(){
    this.get_rewards();
  },
  methods : {
    get_rewards() {
      axios.post(this.api_url+'merchant/get-rewards',
        {type : 'active'},
        {
          headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
          }
        }).then(response => {
            setTimeout(() => {
                this.rewards = response.data.data;
                for (let i = 0; i < this.rewards.length; i++) {
                    this.rewards[i].expiry_date = moment(this.rewards[i].expiry_date).format("DD MMM YYYY")
                    this.rewards[i].menuItems = [
                        { label: 'Edit', icon: 'pi pi-pencil', command: () => this.editReward(this.rewards[i].id)},
                        { label: 'Delete', icon: 'pi pi-trash', command: () => this.showDeleteReward(this.rewards[i].id)}
                    ]
                }
            }, 1000);
        });
    },
    editReward(reward_id) {
      this.$router.push({
            path : 'edit-program-reward/'+reward_id
      });
    },
    showDeleteReward : function(reward_id) {
        this.current_reward_id_to_delete = reward_id;
        this.delete_reward_modal = true;
    },
    on_rewards_page_change(event) {
      this.rewards_current_page = event.page;
    },
  }
}
</script>

<style>

</style>
