<template>
    <Header />

    <div class="container m-0">
        <div class="terms-conditon-page">
            <div class="container p-0">
                <div class="grid ml-0 mr-0">
                    <div class="col-12 md:col-12 lg:col-12">
                        <h2 class="terms-and-condition-title">Terms & Conditions</h2>

                        <h3 class="small-heading">
                            1. Acceptance
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>By downloading, installing, or using the Loyalty Program App, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, you are not authorized to use the App.</li>
                            </ul>
                        </div>

                        <h3 class="small-heading">
                            2. User Accounts
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>To use certain features of the App, you may be required to create a user account.</li>
                                
                                <li>You are responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account.</li>

                                <li>You agree to notify us immediately of any unauthorized use of your account.</li>
                            </ul>
                        </div>

                        <h3 class="small-heading">
                            3. Loyalty Program
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>The Loyalty Program allows users to earn rewards for making purchases at participating merchants.</li>
                                
                                <li>Rewards may be in the form of points, discounts, or other incentives.</li>
                                
                                <li>The specific terms and conditions of the Loyalty Program, including the types of rewards, eligibility criteria, and redemption methods, may vary depending on the merchant.</li>
                            </ul>
                        </div>

                        <h3 class="small-heading">
                            4. Merchant Participation
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>Merchants may participate in the Loyalty Program by offering rewards to their customers through the App.</li>

                                <li>Merchants are responsible for ensuring that their participation in the Loyalty Program complies with all applicable laws and regulations.</li>
                            </ul>
                        </div>

                        <h3 class="small-heading">
                            5. Intellectual Property
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>The App and its contents, including but not limited to text, graphics, logos, and software, are the property of [Your Company Name] and are protected by copyright and other intellectual property laws.</li>

                                <li>You may not use the App or its contents for any commercial purpose without our prior written consent.</li>
                            </ul>
                        </div>

                        <h3 class="small-heading">
                            6. Disclaimer of Warranties
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>The App is provided on an "as is" and "as available" basis, without warranties of any kind, either express or implied.</li>

                                <li>We do not warrant that the App will be error-free, uninterrupted, or secure.</li>
                            </ul>
                        </div>

                        <h3 class="small-heading">
                            7. Limitation of Liability
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>In no event shall [Your Company Name] be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the App. </li>
                            </ul>
                        </div>

                        <h3 class="small-heading">
                            8. Changes
                        </h3>

                        <div class="para-terms-and-condition">
                            <ul>
                                <li>We may update these Terms and Conditions from time to time.</li>

                                <li>Any changes will be effective upon posting on the App.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer/>
</template>

<script>
import Header from '../Header/HeaderView.vue'
import Footer from '../Footer/FooterView.vue'

export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {

      }
    },
    mounted() {
    },
    methods : {

    }
}
</script>
