<template>

    <sidebarNav />
  
    <div class="page-header">
      <div class="header-left-area">
        <h1 class="page-title m-0">Reports</h1>
      </div>
      
      <div class="header-right-area">
      </div>
    </div>
  
    <div class="app-content content">
      <div class="container-fuild app-inner-container users-analysis-container">
        <div class="users-analysis-inner">
            <div class="inner-lsiting-header">
                <div class="back-to-report">
                    <router-link to="/reports">
                        <i class="pi pi-arrow-left"></i>
                    </router-link>
                    <span>Users Activity Analysis</span>
                </div>

                <div class="back-to-report all-reports">
                    <i class="pi pi-arrow-left" @click="showAllUserReports()"></i>
                    <span>All Reports</span>
                </div>

                <div class="reports-filter-container">
                    <div>
                        <IconField-prime>
                            <InputIcon-prime class="pi pi-search" />
                            <inputText-prime placeholder="Search User" />
                        </IconField-prime>
                    </div>

                    <button-prime class="offer-filter-btn" label="month" icon="pi pi-calendar">
                        <span class="p-button-icon p-button-icon-left pi">
                            <i class="pi pi-calendar"></i>
                        </span>
                        <span class="p-button-label">month</span>
                        <span class="p-button-icon p-button-icon-left pi">
                            <i class="pi pi-angle-down"></i>
                        </span>
                    </button-prime>
                </div>
            </div>

            <div class="analysis-listing-container" v-if="useranalysislistingScreen == 0">
                <h2 class="reports-analysis-listing-title">Descriptive Results</h2>

                <div class="reports-analysis-table-listing">
                    <table class="analysis-report-table">
                        <thead>
                            <tr>
                                <th>
                                    <span>
                                        User Contact
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Date
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Time
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Branch
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Redeem Activity
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Redeem Worth
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr @click="showSpecificUserReport()">
                                <td>
                                    <span>Myassine12@gmail.com</span>
                                </td>
                                <td>
                                    <span>12/05/2024</span>
                                </td>
                                <td>
                                    <span>01:00 pm</span>
                                </td>
                                <td>
                                    <span>Branch location here</span>
                                </td>
                                <td>
                                    <div class="redeem-activity-badges">
                                        <Chip-prime class="chip-yellow" label="20 point" image="" />
                                        <Chip-prime class="chip-green" label="Points Program" />
                                        <Chip-prime class="chip-black" label="20% off on order on Monday" image="" />
                                    </div>
                                </td>
                                <td>
                                    <span class="worth-span">20 AED</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Myassine12@gmail.com</span>
                                </td>
                                <td>
                                    <span>12/05/2024</span>
                                </td>
                                <td>
                                    <span>01:00 pm</span>
                                </td>
                                <td>
                                    <span>Branch location here</span>
                                </td>
                                <td>
                                    <div class="redeem-activity-badges">
                                        <Chip-prime class="chip-yellow" label="20 point" image="" />
                                        <Chip-prime class="chip-green" label="Points Program" />
                                        <Chip-prime class="chip-black" label="20% off on order on Monday" image="" />
                                    </div>
                                </td>
                                <td>
                                    <span class="worth-span">20 AED</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Myassine12@gmail.com</span>
                                </td>
                                <td>
                                    <span>12/05/2024</span>
                                </td>
                                <td>
                                    <span>01:00 pm</span>
                                </td>
                                <td>
                                    <span>Branch location here</span>
                                </td>
                                <td>
                                    <div class="redeem-activity-badges">
                                        <Chip-prime class="chip-yellow" label="20 point" image="" />
                                        <Chip-prime class="chip-green" label="Points Program" />
                                        <Chip-prime class="chip-black" label="Get Free Chicken Chili Dry" image="" />
                                    </div>
                                </td>
                                <td>
                                    <span class="worth-span">20 AED</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="pagination-container">
                    <paginator-prime :rows="5" :totalRecords="25"></paginator-prime>
                </div>

                <div class="w-full flex justify-content-end">
                    <button-prime class="wafa-primary-btn pl-3 pr-3" label="Download Complete Report" />
                </div>
            </div>

            <!-- Specific User Result -->
            <div class="specific-user-reult-container" v-if="specificUserReportScreen == 1">

                <div class="specific-user-info">
                    <div class="specific-user-info-img">
                        <img alt="Generic placeholder image" src="../../../assets/images/specificUser.png">
                    </div>
                    <div class="user-info-labels">
                        <h2>
                            Mostafa Yassine
                            <span>Joined 04/2024</span>
                        </h2>
                        <p>
                            <i class="pi pi-phone"></i>
                            <span>+971 234 5674351</span>
                        </p>
                        <p>
                            <i class="bi bi-envelope"></i>
                            <span>Myassine12@gmail.com</span>
                        </p>
                        <p>
                            <i class="pi pi-map-marker"></i>
                            <span>UAE</span>
                        </p>
                    </div>
                </div>

                <div class="activity-box-container mb-4">
                    <swiper 
                    :slides-per-view="5" 
                    :space-between="12" 
                    :navigation="true" 
                    :loop="true" 
                    :pagination="pagination" 
                    :modules="modules" 
                    :breakpoints="{
                        320 : { slidesPerView: 1, spaceBetween: 12 },
                        767 : { slidesPerView: 1, spaceBetween: 12 },
                        991 : { slidesPerView: 2, spaceBetween: 12 },
                        1024 : { slidesPerView: 3, spaceBetween: 12 },
                        1199 : { slidesPerView: 4, spaceBetween: 12 },
                        1440 : { slidesPerView: 5, spaceBetween: 12 },
                    }" @swiper="onSwiper" @slideChange="onSlideChange">
                        <swiper-slide>
                            <div class="activity-box-item">
                                <div class="icon-number">
                                    <div class="activity-logo">
                                        <img src="../../../assets/images/timeline-activity-icons/icon1.svg">
                                    </div>
                                    <h4 class="activity-name">10</h4>
                                </div>
                                <h4 class="activity-name">Total Rewards Redeemed</h4>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="activity-box-item">
                                <div class="icon-number">
                                    <div class="activity-logo">
                                        <img src="../../../assets/images/timeline-activity-icons/icon4.svg">
                                    </div>
                                    <h4 class="activity-name">02</h4>
                                </div>
                                <h4 class="activity-name">Total Offers Redeemed</h4>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="activity-box-item">
                                <div class="icon-number">
                                    <div class="activity-logo">
                                        <img src="../../../assets/images/timeline-activity-icons/icon4.svg">
                                    </div>
                                    <h4 class="activity-name">60</h4>
                                </div>
                                <h4 class="activity-name">Total Visits</h4>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="activity-box-item">
                                <div class="icon-number">
                                    <div class="activity-logo">
                                        <img src="../../../assets/images/timeline-activity-icons/icon3.svg">
                                    </div>
                                    <h4 class="activity-name">30</h4>
                                </div>
                                <h4 class="activity-name">Total collected stamps</h4>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="activity-box-item">
                                <div class="icon-number">
                                    <div class="activity-logo">
                                        <img src="../../../assets/images/timeline-activity-icons/icon2.svg">
                                    </div>
                                    <h4 class="activity-name">20, 000</h4>
                                </div>
                                <h4 class="activity-name">Total collected Points</h4>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>

                <h2 class="reports-analysis-listing-title">User Activity Result</h2>

                <div class="reports-analysis-table-listing">
                    <table class="analysis-report-table">
                        <thead>
                            <tr>
                                <th>
                                    <span>
                                        Date
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Time
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Branch
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Redeem Activity
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Redeem Worth
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span>12/05/2024</span>
                                </td>
                                <td>
                                    <span>01:00 pm</span>
                                </td>
                                <td>
                                    <span>Branch location here</span>
                                </td>
                                <td>
                                    <div class="redeem-activity-badges">
                                        <Chip-prime class="chip-yellow" label="20 point" image="" />
                                        <Chip-prime class="chip-green" label="Points Program" />
                                        <Chip-prime class="chip-black" label="20% off on order on Monday" image="" />
                                    </div>
                                </td>
                                <td>
                                    <span class="worth-span">20 AED</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>12/05/2024</span>
                                </td>
                                <td>
                                    <span>01:00 pm</span>
                                </td>
                                <td>
                                    <span>Branch location here</span>
                                </td>
                                <td>
                                    <div class="redeem-activity-badges">
                                        <Chip-prime class="chip-yellow" label="20 point" image="" />
                                        <Chip-prime class="chip-green" label="Points Program" />
                                        <Chip-prime class="chip-black" label="20% off on order on Monday" image="" />
                                    </div>
                                </td>
                                <td>
                                    <span class="worth-span">20 AED</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>12/05/2024</span>
                                </td>
                                <td>
                                    <span>01:00 pm</span>
                                </td>
                                <td>
                                    <span>Branch location here</span>
                                </td>
                                <td>
                                    <div class="redeem-activity-badges">
                                        <Chip-prime class="chip-yellow" label="20 point" image="" />
                                        <Chip-prime class="chip-green" label="Points Program" />
                                        <Chip-prime class="chip-black" label="Get Free Chicken Chili Dry" image="" />
                                    </div>
                                </td>
                                <td>
                                    <span class="worth-span">20 AED</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="pagination-container">
                    <paginator-prime :rows="5" :totalRecords="25"></paginator-prime>
                </div>

                <div class="w-full flex justify-content-end">
                    <button-prime class="wafa-primary-btn pl-3 pr-3" label="Download User Report" @click="downloadReportModalVisible = true" />
                </div>
            </div>
            <!-- / Specific User Result -->
        </div>
      </div>
    </div>

    <!-- Subscription Plan Modal -->
    <dialog-prime class="download-report-modal" v-model:visible="downloadReportModalVisible" modal header="New Program" :style="{ width: '34rem' }">
        
        <div class="modal-body-container">
          <div class="download-report-progress-icon">
            <CircleProgressBar  
            :value="50"  
            :max="100"
            colorUnfilled="#1ABBAC"
            rounded
            strokeWidth="8px"
            percentage>
            {{ value }}
            </CircleProgressBar>
          </div>
          <div class="download-report-info">
             <h2>Report Downloading</h2>
             <p>Please be patient until we download your report</p>
          </div>
        </div>
    </dialog-prime>
    <!-- / Subscription Plan Modal -->
  
</template>
  
<script setup>
import { ref } from "vue";

const downloadReportModalVisible = ref(false);
</script>
  
<script>
  import sidebarNav from '../../sharedViews/sidebar/sidebarNav.vue';

    // import Swiper core and required modules
    import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';

    import { Swiper, SwiperSlide } from 'swiper/vue';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import 'swiper/css/scrollbar';
  
  
  export default {
    components: {
        sidebarNav,
        Swiper,
        SwiperSlide,
    },
    data() {
      return {
        modules: [Navigation, Pagination, Autoplay, Scrollbar,],

        useranalysislistingScreen: 0,
        specificUserReportScreen: 0,
      }
    },
    methods: {
        showSpecificUserReport: function() {
            this.useranalysislistingScreen = 1
            this.specificUserReportScreen = 1
        },
        showAllUserReports: function() {
            this.specificUserReportScreen = 0
            this.useranalysislistingScreen = 0
        }
    },
    mounted(){
    } 
  }
</script>
