import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'

import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import '/node_modules/primeflex/primeflex.css';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import { CircleProgressBar } from 'circle-progress.vue';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';

const app = createApp(App);
app.component('QuillEditor', QuillEditor)

import { createRouter, createWebHistory } from 'vue-router'

// import sharedView from './components/sharedViews/sharedView.vue'
import signIn from './components/pages/signIn.vue';
import forgotPassword from './components/pages/forgotPassword.vue';

import dashboard from './components/dashboard/dashboardView.vue'
import programsRewards from './components/program/programs-rewards.vue'
import addReward from './components/program/addReward.vue'
import editReward from './components/program/editReward.vue'
import offerlisting from './components/offer/offer-listing.vue'
import addOffer from './components/offer/addOffer.vue'
import editOffer from './components/offer/editOffer.vue'
import userActivity from './components/users/users-activity.vue'
import reportsAnalysis from './components/reports/reports-analysis.vue'
import userSettings from './components/settings/user-settings.vue'
import userActivityAnalysis from './components/reports/users-activity-analysis/users-activity-analysis.vue'
import offerAnalysisReport from './components/reports/offers-analysis-report/offers-analysis-report.vue'
import rewardAnalysisReport from './components/reports/rewards-analysis-report/rewards-analysis-report.vue'
import becomeMerchant from './components/BecomeaMerchant'
import aboutWafa from './components/aboutWafa'
import subscription from './components/merchantSubscription'
import register from './components/pages/registerNow.vue'
import privacyPolicy from './components/pages/privacyPolicy.vue'
import termsconditions from './components/pages/termsConditions.vue'

//UI Components
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import SelectButton from 'primevue/selectbutton';
import Menubar from 'primevue/menubar';
import Carousel from 'primevue/carousel';
import Avatar from 'primevue/avatar';
import Message from 'primevue/message';
import Rating from 'primevue/rating';
import Paginator from 'primevue/paginator';
import Galleria from 'primevue/galleria';
import ProgressBar from 'primevue/progressbar';
import Dialog from 'primevue/dialog';
import Slider from 'primevue/slider';
import Textarea from 'primevue/textarea';
import TabPanel from 'primevue/tabpanel';
import RadioButton from 'primevue/radiobutton';
import Menu from 'primevue/menu';
import TieredMenu from 'primevue/tieredmenu';
import AutoComplete from 'primevue/autocomplete';
import FileUpload from 'primevue/fileupload';
import Breadcrumb from 'primevue/breadcrumb';
import MegaMenu from 'primevue/megamenu';
import ScrollTop from 'primevue/scrolltop';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import InputNumber from 'primevue/inputnumber';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Image from 'primevue/image';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import ScrollPanel from 'primevue/scrollpanel';
import Select from 'primevue/select';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import DatePicker from 'primevue/datepicker';
import Chip from 'primevue/chip';
import Chart from 'primevue/chart';
import Drawer from 'primevue/drawer';
// import Editor from 'primevue/editor';
import Skeleton from 'primevue/skeleton';

// app.component('Editor-prime', Editor);
app.component('Skeleton-prime', Skeleton);
app.component('DatePicker-prime', DatePicker);
app.component('Tabs-prime', Tabs);
app.component('TabList-prime', TabList);
app.component('Tab-prime', Tab);
app.component('TabPanels-prime', TabPanels);
app.component('ScrollPanel-prime', ScrollPanel);
app.component('IconField-prime', IconField);
app.component('InputIcon-prime', InputIcon);
app.component('button-prime', Button);
app.component('select-prime', Select);
app.component('inputText-prime', InputText);
app.component('checkbox-prime', Checkbox);
app.component('password-prime', Password);
app.component('selectButton-prime', SelectButton);
app.component('menubar-prime', Menubar);
app.component('carousel-prime', Carousel);
app.component('avatar-prime', Avatar);
app.component('rating-prime', Rating);
app.component('paginator-prime', Paginator);
app.component('galleria-prime', Galleria);
app.component('progressBar-prime', ProgressBar);
app.component('dialog-prime', Dialog);
app.component('slider-prime', Slider);
app.component('textarea-prime', Textarea);
app.component('TabPanel-prime', TabPanel);
app.component('accordion-prime', Accordion);
app.component('accordionTab-prime', AccordionTab);
app.component('radioButton-prime', RadioButton);
app.component('menu-prime', Menu);
app.component('megamenu-prime', MegaMenu);
app.component('tieredMenu-prime', TieredMenu);
app.component('message-prime', Message);
app.component('breadcrumb-prime', Breadcrumb);
app.component('autoComplete-prime', AutoComplete);
app.component('fileupload-prime', FileUpload);
app.component('scrolltop-prime', ScrollTop);
app.component('multiSelect-prime', MultiSelect);
app.component('InputNumber-prime', InputNumber);
app.component('overlayPanel-prime', OverlayPanel);
app.component('toast-prime', Toast);
app.component('Image-prime', Image);
app.component('Chip-prime', Chip);
app.component('CircleProgressBar', CircleProgressBar);
app.component('Chart-prime', Chart);
app.component('Drawer-prime', Drawer);

const router = createRouter({
    history: createWebHistory(),
    routes: [{
        path: '/',
        name: 'aboutWafa',
        component: aboutWafa,
        meta: {
          title: '',
          metaTags: [{
              name: '',
              content: ''
            },
            {
              property: '',
              content: ''
            }
          ]
        }
      },
      {
        path: '/become-a-merchant',
        name: 'become-a-merchant',
        component: becomeMerchant,
      },
      {
        path: '/subscription',
        name: 'subscription',
        component: subscription,
      },
      {
        path: '/register',
        name: 'register',
        component: register,
      },
      {
        path: '/signin',
        name: 'signin',
        component: signIn,
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: forgotPassword,
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: dashboard,
        meta: { requiresAuth: true },
      },
      {
        path: '/programs-rewards',
        name: 'programs-rewards',
        component: programsRewards,
        meta: { requiresAuth: true },
      },
      {
        path: '/add-program-reward/:program_id',
        name: 'add-program-reward',
        component: addReward,
        meta: { requiresAuth: true },
      },
      {
        path: '/edit-program-reward/:id',
        name: 'edit-program-reward',
        component: editReward,
        meta: { requiresAuth: true },
      },
      {
        path: '/offers',
        name: 'offers',
        component: offerlisting,
        meta: { requiresAuth: true },
      },
      {
        path: '/offers/add',
        name: 'add-offer',
        component: addOffer,
        meta: { requiresAuth: true },
      },
      {
        path: '/offers/edit/:id',
        name: 'edit-offer',
        component: editOffer,
        meta: { requiresAuth: true },
      },
      {
        path: '/user-activity',
        name: 'user-activity',
        component: userActivity,
      },
      {
        path: '/reports',
        name: 'report-analysis',
        component: reportsAnalysis,
      },
      {
        path: '/user-activity-analysis',
        name: 'user-activity-analysis',
        component: userActivityAnalysis,
      },
      {
        path: '/offers-analysis-report',
        name: 'offers-analysis-report',
        component: offerAnalysisReport,
      },
      {
        path: '/reward-analysis-report',
        name: 'reward-analysis-report',
        component: rewardAnalysisReport,
      },
      {
        path: '/settings',
        name: 'user-settings',
        component: userSettings,
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: privacyPolicy,
      },
      {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: termsconditions,
      },
    ],
    scrollBehavior(to, from) {
      // scrollBehavior (to, from, savedPosition) {
      console.log('wewewe', to, from);
      if (
        to.name == 'aboutWafa' ||
        to.name == 'signin' ||
        to.name == 'subscription' ||
        to.name == 'register' ||
        to.name == 'forgot-password' ||
        to.name == 'dashboard' ||
        to.name == 'programs-rewards' ||
        to.name == 'offers' ||
        to.name == 'user-activity' ||
        to.name == 'report-analysis' ||
        to.name == 'user-settings' ||
        to.name == 'user-activity-analysis' ||
        to.name == 'become-a-merchant' ||
        to.name == 'privacy-policy' ||
        to.name == 'terms-and-conditions' ||
        to.name == 'offers-analysis-report' 
      ) {
        // if(to.name == 'places' && from.name == 'places') {
        //   window.location.href = '/places';
        // }
        return {
          top: 0
        }
      }
    }
  });

app.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('wafa_auth_token'); // Replace this with your actual authentication logic

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next('/signin'); // Redirect to login if not authenticated
    } else {
      next(); // Allow access if authenticated
    }
  } else {
    next(); // Always allow access to routes that do not require authentication
  }
});

app.use(router);
app.use(VueTelInput);
app.use(ToastService);
app.use(CkeditorPlugin);


app.config.globalProperties.api_url = (location.host.includes('127.0.0.1') || location.host.includes('localhost')) ? 'http://wafa_backend.test/api/' : 'https://api.getwafa.com/api/';
// app.config.globalProperties.api_url = 'https://api.getwafa.com/api/';

app.mount('#app');