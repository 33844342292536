<template>
    
  <sidebarNav />

    <div class="page-header">
        <div class="header-left-area">
            <h1 class="page-title m-0">Offers</h1>
        </div>
    </div>

    <div class="app-content content">
    <div class="container-fuild listing-container offer-listing-container">
    <h2 class="listing-title">Edit Offer</h2>
    <div class="grid m-0 mt-3">
        <div class="col-12 md:col-6 lg:col-6 ps-0">
            <div class="card-border-box">
                <div class="form-group">
                    <label for="username" class="form-label">Add Image</label>
                    <form id="" class="uploader">
                        <input id="file-upload" type="file" name="fileUpload" accept="image/*" @change="handleFileUpload"/>
                        <label for="file-upload" id="file-drag">
                            <div class="upload-image-box">
                                <div class="upload-icon">
                                    <img class="" src="../../assets/Icons/upload-img.svg">
                                </div>
                                <p class="upload-box-label">Uplaod your file here.</p>
                            </div>
                        </label>
                    </form>
                </div>

                <div class="form-group uploaded-files-container">
                    <div class="uploaded-items" v-if="offer.image_url">
                        <div class="file-img">
                            <img class="" :src="offer.image_url">
                        </div>
                        <div class="file-info">
                            <p class="file-name">{{ offer.file_name }}</p>
                            <p class="file-size">{{ offer.file_size }}</p>
                        </div>
                        <span class="delete-file" @click="remove_image">
                            <img class="" src="../../assets/Icons/bin-gray.svg">  
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <label for="username" class="form-label">Offer Title</label>
                    <inputText-prime type="text" placeholder="Enter Offer Title" v-model="offer.name" />
                </div>

                <div class="form-group">
                    <label for="email" class="form-label">Value of Offer (AED)</label>
                    <InputNumber-prime v-model="offer.value"  :min="0" inputId="horizontal-buttons" showButtons buttonLayout="horizontal" fluid>
                        <template #incrementbuttonicon>
                            <span class="pi pi-plus" />
                        </template>
                        <template #decrementbuttonicon>
                            <span class="pi pi-minus" />
                        </template>
                    </InputNumber-prime>
                </div>

                <div class="form-group">
                    <label for="email" class="form-label">Redeems Allowed per User</label>
                    <InputNumber-prime v-model="offer.redeem_allow_per_user" :min="0" inputId="horizontal-buttons" showButtons buttonLayout="horizontal" fluid>
                        <template #incrementbuttonicon>
                            <span class="pi pi-plus" />
                        </template>
                        <template #decrementbuttonicon>
                            <span class="pi pi-minus" />
                        </template>
                    </InputNumber-prime>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-6 lg:col-6 pe-0">
            <div class="card-border-box">
                <div class="form-group mb-0">
                    <label for="email" class="form-label">Offer Validity Days</label>
                    <div class="validity-days-chips">
                        <template v-for="(day, index) in days" :key="index">
                            <div v-bind:class = "is_active(day.name) ? 'validity-days active':'validity-days'" @click="set_day_and_timings(day)">{{ day.name }} <i class="pi pi-times" @click="remove_day_and_timings(day, $event)"></i></div>
                        </template>
                    </div>
                </div>

                <!-- <Tabs-prime :value="offer.days_and_time_settings.length">
                    <TabList-prime>
                        <template v-for="(day, index) in offer.days_and_time_settings" :key="index">
                            <Tab-prime :value="day.id">{{ day.name.slice(0, 3) }}</Tab-prime>
                        </template>
                    </TabList-prime>
                    <TabPanels-prime>
                        <template v-for="(day, index) in offer.days_and_time_settings" :key="index">
                            <TabPanel-prime :value="day.id">
                                <div class="form-group offer-validity-time-container">
                                    <label for="username" class="form-label">Offer Validity Time for <span style="color:#1ABBAC;">{{ day.name }}</span></label>
                                    <div class="validity-radio-btn">
                                        <div class="flex items-center" v-for="item in flexible_time_or_24hr" :key="item.key">
                                            <radioButton-prime v-model="offer.start_date_time" :inputId="day.id+item.key" :name="'dynamic'+day.id+item.key" :value="item.key" />
                                            <label :for="day.id+item.key" class="ml-2 radio-btn-label">{{ item.name }}</label>
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="timepicker-field-area" v-if="day.flexible_time_or_24hr=='FT'">
                                        <div>
                                            <DatePicker-prime id="datepicker-timeonly" v-model="offer.time_start" hourFormat="12" timeOnly fluid />
                                        </div>
                                        <span>to</span>
                                        <div>
                                            <DatePicker-prime id="datepicker-timeonly" v-model="offer.time_start" hourFormat="12" timeOnly fluid />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel-prime>
                        </template>
                    </TabPanels-prime>
                </Tabs-prime> -->
                <div class="form-group offer-validity-time-container">
                    <label for="username" class="form-label">Offer Validity Time </label>
                    <div class="validity-radio-btn">
                        <div class="flex items-center" v-for="item in flexible_time_or_24hr" :key="item.key">
                            <radioButton-prime v-model="offer.flexible_time_or_24hr" :inputId="item.key" name='dynamic' :value="item.key" />
                            <label :for="item.key" class="ml-2 radio-btn-label">{{ item.name }}</label>
                        </div>
                        
                    </div>
                    
                    <div class="timepicker-field-area" v-if="offer.flexible_time_or_24hr=='FT'">
                        <div>
                            <DatePicker-prime id="datepicker-timeonly" v-model="offer.start_time" hourFormat="12" timeOnly fluid />
                        </div>
                        <span>to</span>
                        <div>
                            <DatePicker-prime id="datepicker-timeonly" v-model="offer.end_time" hourFormat="12" timeOnly fluid />
                        </div>
                    </div>
                </div>

                <div class="form-group editor-area-container">
                    <label for="username" class="form-label">Offer Details</label>
                    <ckeditor
                        v-model="offer.description"
                        :editor="editor"
                        :config="editorConfig"
                    />
                </div>

                <div class="form-group editor-area-container">
                    <label for="username" class="form-label">Terms & Conditions</label>
                    <ckeditor
                        v-model="offer.terms_and_condition"
                        :editor="editor2"
                        :config="editorConfig"
                    />
                </div>

                <div class="form-group">
                    <label for="email" class="form-label">Expiry Date</label>
                    <DatePicker-prime placeholder="Enter Expiry Date" v-model="offer.expiry_date" dateFormat="dd MM yy" @keypress="stop_writing_on_date($event)" :minDate="new Date()"/>
                </div>
            </div>
        </div>
    </div>

    <div class="grid m-0 mt-2">
        <div class="col-12 md:col-6 lg:col-6 ps-0">
            <router-link to="/offers">
                <button-prime class="cancel-btn" label="Go Back"/>
            </router-link>
        </div>

        <div class="col-12 md:col-6 lg:col-6 ps-0 gap-2 flex justify-content-end">
            <button-prime class="save-draft-btn" :loading="save_offer_loader" @click="edit_offer('drafted')" label="Save Draft" />
            <button-prime class="publish-offer-btn" :loading="save_offer_loader" @click="edit_offer('published')" label="Publish Offer" />
        </div>
    </div>
    </div>
    </div>
    <toast-prime />
</template>


  
<script>
import axios from 'axios'
import moment from 'moment'
import sidebarNav from '../sharedViews/sharedView.vue';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Underline, List, Heading, TextTransformation,Indent,
    IndentBlock, } from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';

export default {
  components: {
    sidebarNav
  },
  data() {
    return {
        editor: ClassicEditor,
        editor2: ClassicEditor,
        
        editorConfig: {
            plugins: [ Bold, Essentials, Italic, Mention, Paragraph, Undo, Underline, List, Heading, TextTransformation,Indent,
			IndentBlock, ],
            toolbar: [ 
                'undo', 'redo', 
                '|',
                'heading',
                '|', 
                'bold', 'italic', 'underline',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'outdent',
                'indent',
            ],
            // Other configuration options...
        },
        days : [
            {id : 1, name : 'Monday'}, 
            {id : 2, name : 'Tuesday'}, 
            {id : 3, name : 'Wednesday'}, 
            {id : 4, name : 'Thursday'}, 
            {id : 5, name : 'Friday'}, 
            {id : 6, name : 'Saturday'}, 
            {id : 7, name : 'Sunday'}
        ],
        flexible_time_or_24hr : [
            { name: '24 Hours', key: '24H' },
            { name: 'Flexible Time', key: 'FT' }
        ],
        save_offer_loader : false,
        offer : {
            name : null,
            value : 0,
            redeem_allow_per_user : 0,
            description : null,
            terms_and_condition : null,
            expiry_date : null,
            image_url : null,
            file_name : null,
            file_size : null,
            file : null,
            days_and_time_settings : [],
            flexible_time_or_24hr : 'FT',
            start_time : null,
            end_time : null
        }
    }
  },
  mounted(){
    this.get_offer();
  },
  methods : {
    get_offer() {
        axios.post(this.api_url+'merchant/get-offer', 
        {
            id : this.$route.params.id
        },
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
        }).then(response => {
            this.offer.name = response.data.data.name;
            this.offer.value = response.data.data.item_value;
            this.offer.redeem_allow_per_user = response.data.data.allow_per_user;
            this.offer.description = response.data.data.description;
            this.offer.terms_and_condition = response.data.data.terms_and_condition;
            this.offer.image_url = response.data.data.offer_image;
            this.offer.start_time = new Date(response.data.data.offer_days[0].start_date_time);
            this.offer.end_time = new Date(response.data.data.offer_days[0].end_date_time);
            this.offer.expiry_date = new Date(response.data.data.expiry_date);
            var offer_days = response.data.data.offer_days;
            for (let i = 0; i < offer_days.length; i++) {
                this.offer.days_and_time_settings.push({
                    start_date_time : this.offer.start_time,
                    end_date_time : this.offer.end_time,
                    id: 0,
                    name: offer_days[i].day_of_week
                })
            }
            
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            console.log('finally');
        });
    },
    edit_offer(type) {
        
        if (this.offer.name == null) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Offer name required!', life: 3000 });
            return false;
        }

        if (this.offer.value == null || this.offer.value == 0) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Value of offer required!', life: 3000 });
            return false;
        }

        if (this.offer.redeem_allow_per_user == null || this.offer.redeem_allow_per_user == 0) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Redeems Allowed per User required!', life: 3000 });
            return false;
        }

        if (this.offer.days_and_time_settings.length == 0) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Please Select the days!', life: 3000 });
            return false;
        }

        if (this.offer.start_time == null) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Please select start time!', life: 3000 });
            return false;
        }

        if (this.offer.end_time == null) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Please select end time!', life: 3000 });
            return false;
        }

        if (this.offer.description == null) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Details required!', life: 3000 });
            return false;
        }

        if (this.offer.expiry_date == null) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Expiry date required!', life: 3000 });
            return false;
        }

        // if (this.offer.file == null) {
        //     this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Image required!', life: 3000 });
        //     return false;
        // }

        this.offer.type = type;
        this.save_offer_loader = true;
        this.offer.expiry_date_backend = moment(this.offer.expiry_date).format('YYYY-MM-DD');
        this.offer.start_date_time = this.offer.expiry_date_backend+' '+moment(this.offer.start_time).format('HH:mm:ss');
        this.offer.end_date_time = this.offer.expiry_date_backend+' '+moment(this.offer.end_time).format('HH:mm:ss');
        this.offer.id = this.$route.params.id;
        
        for (let i = 0; i < this.offer.days_and_time_settings.length; i++) {
            this.offer.days_and_time_settings[i].start_date_time = this.offer.start_date_time;
            this.offer.days_and_time_settings[i].end_date_time = this.offer.end_date_time;
        }

        axios.post(this.api_url+'merchant/update-offer', 
        this.offer,
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token'),
            'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.save_offer_loader = false;
        });
    },
    remove_image() {
        this.offer.image_url = null;
        this.offer.file_name = null;
        this.offer.file_size = null;
        this.offer.file = null;
    },
    is_active(name) {
        return this.offer.days_and_time_settings.some(setting => setting.name === name);
    },
    set_day_and_timings(day) {

        let key = 'name';
        let index = this.offer.days_and_time_settings.findIndex(obj => obj[key] === day.name);

        if (index !== -1) {
            // this.offer.days_and_time_settings.splice(index, 1);
            console.log('index', index)
        } else {
            this.offer.days_and_time_settings.push({
                id: day.id,
                name: day.name,
                start_date_time : null,
                end_date_time : null
            });
        }
        
    },
    remove_day_and_timings(day, event) {

        event.stopPropagation()
        let key = 'name';
        let index = this.offer.days_and_time_settings.findIndex(obj => obj[key] === day.name);

        if (index !== -1) {
            this.offer.days_and_time_settings.splice(index, 1);
        } else {
            console.log('index', index)
        }
       
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        // Validate file type
        const validTypes = ['image/png', 'image/jpeg'];
        if (!validTypes.includes(file.type)) {
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Only PNG and JPG formats are allowed!', life: 3000 });
            return false;
        }

        // Validate file size (max 2MB)
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes
        if (file.size > maxSize) {
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'File size should not exceed 2MB!', life: 3000 });
            return false;
        }

        this.offer.file_name = file.name; // Get the file name
        this.offer.file_size = this.formatFileSize(file.size);// Get the file size
        this.offer.file = file;

        const reader = new FileReader();
        reader.onload = (e) => {
          this.offer.image_url = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    formatFileSize(size) {
      if (size < 1024) {
        return size + ' Bytes';
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + ' KB';
      } else {
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
      }
    },
    stop_writing_on_date(eve) {
        eve.preventDefault();
    },
  }
}
</script>

<style>

</style>
  