<template>
    <Header />

    <main>
        <div class="main-content home-content main-merchant-subscription">
            <div class="container mb-6">
                <div class="pricing-info-container">
                    <h2 class="pricing-info-title">Simple Plans, <span>Free</span> To Try</h2>

                    <div class="grid group pricing-container">
                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                                <div class="pricing-icon-box">
                                <img class="" src="../assets/images/pricing-icons/free.svg">
                                </div>
                                <h2>WAFA FREEMIUM</h2>
                                <h3>
                                0<span class="small">AED</span>
                                </h3>
                                <p>/month</p>
                                <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                    No. of Offers
                                    <p class="m-0">1</p>
                                </li>
                                <li>
                                    No. of Programs
                                    <i class="pi pi-times-circle"></i>
                                </li>
                                <li>
                                    No. of Branches
                                    <p class="m-0">1</p>
                                </li>
                                <li>
                                    Analytical Dashboard
                                    <i class="pi pi-times-circle"></i>
                                </li>
                                <li>
                                    Customer Data Platform
                                    <i class="pi pi-times-circle"></i>
                                </li>
                                </ul>
                                
                                <div class="pricing-btn"></div>

                                <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>

                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                                <div class="pricing-icon-box">
                                <img class="" src="../assets/images/pricing-icons/free-1.svg">
                                </div>
                                <h2>WAFA BASIC</h2>
                                <h3>
                                99<span class="small">AED</span>
                                </h3>
                                <p>/month</p>
                                <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                    No. of Offers
                                    <p class="m-0">2</p>
                                </li>
                                <li>
                                    No. of Programs
                                    <p class="m-0">1</p>
                                </li>
                                <li>
                                    No. of Branches
                                    <p class="m-0">1</p>
                                </li>
                                <li>
                                    Analytical Dashboard
                                    <i class="pi pi-times-circle"></i>
                                </li>
                                <li>
                                    Customer Data Platform
                                    <i class="pi pi-times-circle"></i>
                                </li>
                                </ul>
                                
                                <div class="pricing-btn">
                                <button-prime class="wafa-primary-outline-btn" label="Get Started" />
                                </div>

                                <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>

                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                                <div class="pricing-icon-box">
                                <img class="" src="../assets/images/pricing-icons/free-2.svg">
                                </div>
                                <h2>WAFA PLUS</h2>
                                <h3>
                                199<span class="small">AED</span>
                                </h3>
                                <p>/month</p>
                                <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                    No. of Offers
                                    <p class="m-0">5</p>
                                </li>
                                <li>
                                    No. of Programs
                                    <p class="m-0">2</p>
                                </li>
                                <li>
                                    No. of Branches
                                    <p class="m-0">5</p>
                                </li>
                                <li>
                                    Analytical Dashboard
                                    <i class="pi pi-plus-circle"></i>
                                </li>
                                <li>
                                    Customer Data Platform
                                    <i class="pi pi-plus-circle"></i>
                                </li>
                                </ul>
                                
                                <div class="pricing-btn">
                                <button-prime class="wafa-primary-outline-btn" label="Get Started" />
                                </div>

                                <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>

                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                                <div class="pricing-icon-box">
                                <img class="" src="../assets/images/pricing-icons/free-3.svg">
                                </div>
                                <h2>WAFA ENTERPRISE</h2>
                                <h3>
                                399<span class="small">AED</span>
                                </h3>
                                <p>/month</p>
                                <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                    No. of Offers
                                    <p class="m-0">Unlimited</p>
                                </li>
                                <li>
                                    No. of Programs
                                    <p class="m-0">Unlimited</p>
                                </li>
                                <li>
                                    No. of Branches
                                    <p class="m-0">Unlimited</p>
                                </li>
                                <li>
                                    Analytical Dashboard
                                    <i class="pi pi-plus-circle"></i>
                                </li>
                                <li>
                                    Customer Data Platform
                                    <i class="pi pi-plus-circle"></i>
                                </li>
                                </ul>
                                
                                <div class="pricing-btn">
                                <button-prime class="wafa-primary-outline-btn" label="Get Started" />
                                </div>

                                <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <Footer />
</template>

<script>
import Footer from './Footer/FooterView.vue'
import Header from './Header/HeaderView.vue'

export default {
    components: {
      Footer,
      Header
    },
    data() {
      return {
      }
    },
    methods : {
      
    }
}
</script>

<script setup>
// import { ref } from "vue";
</script>

<style scoped src="../assets/css/home.css">
</style>