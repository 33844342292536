<template>
    <sidebarNav />

<div class="page-header">
  <div class="header-left-area">
    <h1 class="page-title m-0">Programs and Rewards</h1>
  </div>
  
  <div class="header-right-area">
    <button-prime class="new-program" label="New Program" icon="pi pi-plus" @click="add_program_modal = true" />
  </div>
</div>

<div class="app-content content">
    <div class="container-fuild listing-container">
        <h2 class="listing-title">Edit Reward</h2>

        <div class="grid m-0 mt-3">
            <div class="col-12 md:col-6 lg:col-6 ps-0">
                <div class="card-border-box">
                    <div class="form-group">
                        <label for="username" class="form-label">Add Image</label>
                        <form id="" class="uploader">
                        <input id="file-upload" type="file" name="fileUpload" accept="image/*" @change="handleFileUpload" />
                        <label for="file-upload" id="file-drag">
                            <div class="upload-image-box">
                                <div class="upload-icon">
                                    <img class="" src="../../assets/Icons/upload-img.svg">
                                </div>
                                <p class="upload-box-label">Uplaod your file here.</p>
                            </div>
                        </label>
                    </form>
                    </div>

                    <div class="form-group uploaded-files-container">
                        <div class="uploaded-items" v-if="reward.image_url">
                            <div class="file-img" >
                                <img class="" :src="reward.image_url" alt="Image Preview">
                            </div>
                            <div class="file-info">
                                <p class="file-name">{{ reward.file_name }}</p>
                                <p class="file-size">{{ reward.file_size }}</p>
                            </div>
                            <!-- <span class="delete-file" @click="remove_image">
                                <img class="" src="../../assets/Icons/bin-gray.svg">  
                            </span> -->
                        </div>

                        
                    </div>
                </div>
            </div>

            <div class="col-12 md:col-6 lg:col-6 pe-0">
                <div class="card-border-box">
                    <div class="form-group">
                        <label for="username" class="form-label">Reward Title</label>
                        <inputText-prime type="text" v-model="reward.title" placeholder="Enter Reward Title" />
                    </div>

                    <div class="form-group">
                        <label for="username" class="form-label">Reward Type</label>
                        <select-prime v-model="reward.reward_type" :options="reward_types" optionLabel="name" placeholder="Select" class="w-full" />
                    </div>

                    <div class="form-group">
                        <label for="username" class="form-label">Item Name</label>
                        <inputText-prime type="text" v-model="reward.item_name" placeholder="Enter Name of Reward Item" />
                    </div>

                    <div class="form-group">
                        <label for="email" class="form-label">Value of Item (AED)</label>
                        <InputNumber-prime v-model="reward.item_value" :min="0" inputId="horizontal-buttons" showButtons buttonLayout="horizontal" fluid>
                            <template #incrementbuttonicon>
                                <span class="pi pi-plus" />
                            </template>
                            <template #decrementbuttonicon>
                                <span class="pi pi-minus" />
                            </template>
                        </InputNumber-prime>
                    </div>

                    <div class="form-group">
                        <label for="email" class="form-label">No of Stamps to get Reward</label>
                        <InputNumber-prime v-model="reward.no_of_stamps" :min="0" inputId="horizontal-buttons" showButtons buttonLayout="horizontal" fluid>
                            <template #incrementbuttonicon>
                                <span class="pi pi-plus" />
                            </template>
                            <template #decrementbuttonicon>
                                <span class="pi pi-minus" />
                            </template>
                        </InputNumber-prime>
                    </div>

                    <div class="form-group">
                        <label for="email" class="form-label">Expiry Date</label>
                        <DatePicker-prime v-model="reward.expiry_date" placeholder="Enter Expiry Date" dateFormat="dd MM yy" @keypress="stop_writing_on_date($event)" :minDate="new Date()" />
                    </div>
                </div>
            </div>
        </div>

        <div class="grid m-0 mt-2">
            <div class="col-12 md:col-6 lg:col-6 ps-0">
                <router-link to="/programs-rewards">
                <button-prime class="cancel-btn" label="Go Back" />
                </router-link>
            </div>

            <div class="col-12 md:col-6 lg:col-6 ps-0 gap-2 flex justify-content-end">
                <button-prime class="save-draft-btn" :loading="save_reward_loader" @click="edit_reward('drafted')" label="Save Draft" />
                <button-prime class="publish-offer-btn" :loading="save_reward_loader" @click="edit_reward('published')" label="Publish Offer" />
            </div>
        </div>
    </div>
</div>
<toast-prime />
</template>


<script>
import sidebarNav from '../sharedViews/sharedView.vue';
import axios from 'axios'
import moment from 'moment'
export default {
  components: {
    sidebarNav
  },
  data() {
    return {
      save_reward_loader : false,
      reward_types : [],
      reward : {
        id : 0,
        title : null,
        reward_type : null,
        item_name : null,
        item_value : 0,
        no_of_stamps : 1,
        expiry_date : null,
        image_url : null,
        file_name : null,
        file_size : null,
        file : null,
      },
      
    }
  },
  mounted() {
    this.get_reward_types();
    this.get_reward();
  },
  methods: {
    get_reward() {
        axios.post(this.api_url+'merchant/get-reward', 
        {
            id : this.$route.params.id
        },
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
        }).then(response => {
            this.reward.title = response.data.data.name;
            this.reward.item_value = response.data.data.item_value;
            this.reward.item_name = response.data.data.item_name;
            this.reward.no_of_stamps = response.data.data.no_of_stamps_target;
            this.reward.image_url = response.data.data.reward_image;
            this.reward.expiry_date = new Date(response.data.data.expiry_date);
            this.reward.reward_type = this.reward_types.find(item => item.id === response.data.data.reward_type_id);
            
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            console.log('finally');
        });
    },
    remove_image() {
        this.reward.image_url = null;
        this.reward.file_name = null;
        this.reward.file_size = null;
        this.reward.file = null;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        // Validate file type
        const validTypes = ['image/png', 'image/jpeg'];
        if (!validTypes.includes(file.type)) {
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Only PNG and JPG formats are allowed!', life: 3000 });
            return false;
        }

        // Validate file size (max 2MB)
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes
        if (file.size > maxSize) {
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'File size should not exceed 2MB!', life: 3000 });
            return false;
        }

        this.reward.file_name = file.name; // Get the file name
        this.reward.file_size = this.formatFileSize(file.size);// Get the file size
        this.reward.file = file;

        const reader = new FileReader();
        reader.onload = (e) => {
          this.reward.image_url = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    formatFileSize(size) {
      if (size < 1024) {
        return size + ' Bytes';
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + ' KB';
      } else {
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
      }
    },
    edit_reward(type) {
        if (this.reward.title == null) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Reward title required!', life: 3000 });
            return false;
        }
        if (this.reward.reward_type == null) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Reward type required!', life: 3000 });
            return false;
        }
        if (this.reward.item_name == null) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Item name required!', life: 3000 });
            return false;
        }
        if (this.reward.expiry_date == null) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Expiry date required!', life: 3000 });
            return false;
        }

        // if (this.reward.file == null) {
        //     this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Image required!', life: 3000 });
        //     return false;
        // }

        this.reward.type = type;
        this.save_reward_loader = true;
        this.reward.expiry_date_backend = moment(this.reward.expiry_date).format('YYYY-MM-DD');
        this.reward.id = this.$route.params.id;
        axios.post(this.api_url+'merchant/update-reward', 
        this.reward,
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token'),
            'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });
            // this.get_programs();
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.save_reward_loader = false;
        });
    },
    get_reward_types() {
      axios.post(this.api_url+'get-reward-types').then(response => {
        this.reward_types = response.data.data;
      });
    },
    stop_writing_on_date(eve) {
        eve.preventDefault();
    },
  }
}
</script>

<style>

</style>
  