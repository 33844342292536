<template>

    <sidebarNav />

    <div class="page-header">
      <div class="header-left-area">
        <h1 class="page-title m-0">Reports</h1>
      </div>
      
      <div class="header-right-area"></div>
    </div>

    <div class="app-content content">
      <div class="container-fuild all-reports-container">
        <div class="grid m-0">
          <div class="col-12 md:col-4 lg:col-4">
            <div class="reports-cards">
              <div class="cards-info-icons">
                <button-prime class="cards-info-icons-btn btn-info-icon-blue">
                  <img src="../../assets/images/reports-analysis-icons/reward-analysis-icon1.svg">
                </button-prime>
                <button-prime class="cards-info-icons-btn btn-info-icon-green">
                  <img src="../../assets/images/reports-analysis-icons/reward-analysis-icon2.svg">
                </button-prime>
              </div>
              <h2 class="cards-info-title">Rewards Analysis Report</h2>
              <p class="cards-info-description">Includes statistical analysis reports of rewards, including Discounts, Free Items and Buy 1 Get 1 Free</p>

              <router-link to="/reward-analysis-report"> 
                <button-prime class="wafa-primary-outline-btn view-detail-btn" label="View Details" />
              </router-link>
            </div>
          </div>

          <div class="col-12 md:col-4 lg:col-4">
            <div class="reports-cards">
              <div class="cards-info-icons">
                <button-prime class="cards-info-icons-btn btn-info-icon-blue">
                  <img src="../../assets/images/reports-analysis-icons/offer-analysis-icon1.svg">
                </button-prime>
                <button-prime class="cards-info-icons-btn btn-info-icon-green">
                  <img src="../../assets/images/reports-analysis-icons/offer-analysis-icon2.svg">
                </button-prime>
              </div>
              <h2 class="cards-info-title">Offers Analysis Report</h2>
              <p class="cards-info-description">Includes statistical analysis report of discounts given on offers and investments on behalf of merchant to give the offers</p>

              <router-link to="/offers-analysis-report"> 
                <button-prime class="wafa-primary-outline-btn view-detail-btn" label="View Details" />
              </router-link>
            </div>
          </div>

          <div class="col-12 md:col-4 lg:col-4">
            <div class="reports-cards">
              <div class="cards-info-icons">
                <button-prime class="cards-info-icons-btn btn-info-icon-blue">
                  <img src="../../assets/images/reports-analysis-icons/user-activity-analysis-icon1.svg">
                </button-prime>
                <button-prime class="cards-info-icons-btn btn-info-icon-green">
                  <img src="../../assets/images/reports-analysis-icons/user-activity-analysis-icon2.svg">
                </button-prime>
              </div>
              <h2 class="cards-info-title">Users Activity Analysis Report</h2>
              <p class="cards-info-description">Includes statistical analysis reports of rewards, including Discounts, Free Items and Buy 1 Get 1 Free</p>

              <router-link to="/user-activity-analysis">
                <button-prime class="wafa-primary-outline-btn view-detail-btn" label="View Details" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
import sidebarNav from '../sharedViews/sharedView.vue';

export default {
  components: {
    sidebarNav,
  },
  data() {
    return {
    }
  },
  methods: {

  } 
}
</script>

<style>

</style>
  