<template>

    <sidebarNav />
  
    <div class="page-header">
      <div class="header-left-area">
        <h1 class="page-title m-0">Reports</h1>
      </div>
      
      <div class="header-right-area">
      </div>
    </div>
  
    <div class="app-content content">
        <div class="container-fuild app-inner-container reward-analysis-statistics-container">
            <div class="offer-analysis-inner">
                <div class="inner-lsiting-header">
                    <div class="back-to-report">
                        <router-link to="/reports">
                            <i class="pi pi-arrow-left"></i>
                        </router-link>
                        <span>Rewards Analysis</span>
                    </div>

                    <div class="back-to-report all-reports">
                        <i class="pi pi-arrow-left" @click="showAllUserReports()"></i>
                        <span>All Reports</span>
                    </div>
                    
                    <div class="reports-filter-container reward-reports-filter-container">
                        <div class="form-fields-main filter-MWY-dropdown monthFilter mb-0">
                            <button-prime icon="pi pi-chevron-left" @click="prevMonth" />
                                <span class="monthYear-label">{{ monthNames[currentMonth] }} {{ currentYear }}</span>
                            <button-prime icon="pi pi-chevron-right" @click="nextMonth" />
                        </div>

                        <button-prime class="offer-filter-btn" label="Filter by" icon="pi pi-sliders-h" @click="rewardReportFilterModalVisible = true" />
                    </div>
                </div>

                <div class="reports-statistics-charts-container">
                    <h1 class="reports-statistics-title">Overall Results </h1>

                    <div class="grid m-0 mt-3" v-for="(reportItem, index) in reward_report_data" :key="index">
                        <div class="col-12 md:col-12 lg:col-12">
                            <div class="charts-title-label-info">
                                <h2>{{ reward_report_data[index].reward_type }}</h2>
                                <p>
                                    <i class="pi pi-arrow-up"></i>
                                    {{  reward_report_data[index].message_status }}
                                </p>
                            </div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-6">
                            <div class="charts-info-numbers">
                                <div class="info-number-item">
                                    900
                                </div>
                                <div class="info-number-item">
                                    15,000 <span>AED</span>
                                </div>
                                <div class="info-number-item">
                                    500
                                </div>
                            </div>

                            <div class="charts-container">
                                <Chart-prime type="bar" :data="setChartData(reportItem)" :options="setChartOptions()" />
                            </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6">
                            <h2 class="chart-title-text">No of Discounts Given</h2>
                            <div class="pie-charts-container">
                                <Chart-prime type="doughnut" :data="setPieChartData(reportItem)" :options="setPieChartOptions()" class="w-full" />
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>

        <div class="container-fuild app-inner-container reward-analysis-container">
            <div class="offer-analysis-inner">
                <div class="analysis-listing-container mt-0">
                    <h2 class="reports-analysis-listing-title">Descriptive Results</h2>

                    <div class="reports-analysis-table-listing offer-analysis-reports-table-listing">
                        <table class="analysis-report-table">
                            <thead>
                                <tr>
                                    <th>
                                        <span>
                                            Date
                                        </span>
                                    </th>
                                    <th v-for="item in reward_types" :key="item" :style="{ display: filter_reward_type != null && item.id !== filter_reward_type.id && filter_reward_type.id != 0 ? 'none' : '' }">
                                        <span>
                                            {{item.name}}
                                        </span>
                                    </th>
                                    <th>
                                        <span>
                                            Items Worth
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in paginated_descriptive_results" :key="item">
                                    <td>
                                        <span>{{ item.formatted_date }}</span>
                                    </td>
                                    <td class="offer-badge-table-data" v-for="reward_type in item.reward_types" :key="reward_type">
                                        <div class="redeem-activity-badges" v-for="reward in reward_type.rewards" :key="reward">
                                            <Chip-prime class="chip-black" :label="reward.name" image="" />
                                            <span>X{{ reward.redeemed_rewards_count }}</span>
                                        </div>
                                    </td>
                                    
                                    <td>
                                        <span class="worth-span">{{ item.item_value }} AED</span>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>

                    <div class="pagination-container">
                        <paginator-prime v-if="descriptive_results.length>10" :rows="descriptive_results_rows_per_page" :totalRecords="descriptive_results.length" :page="descriptive_results_current_page" @page="on_descriptive_results_page_change"></paginator-prime>
                    </div>

                    <!-- <div class="w-full flex justify-content-end">
                        <button-prime class="wafa-primary-btn pl-3 pr-3" label="Download Complete Report" />
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <!-- Filter Report Modal -->
    <dialog-prime class="fields-option-modal" v-model:visible="rewardReportFilterModalVisible" modal header="Filter Results" :style="{ width: '25rem' }">
        <!-- <div class="form-group">
            <label class="form-label">Select Program</label>
            <selectButton-prime v-model="selectedProgramType" :options="programTypes" aria-labelledby="basic" />
        </div> -->
        
        <div class="form-group select-reward-category">
            <label class="form-label">Select Reward Category</label>
            <selectButton-prime @change="select_reward_type" v-model="filter_reward_type" :options="filter_reward_types" aria-labelledby="basic" optionLabel="name"/>
            
        </div>
        
        <div class="form-group">
            <label class="form-label">Select Specific Reward</label>
            <select-prime v-model="filter_reward" :options="rewards_list_changed" optionLabel="name" placeholder="Select" class="w-full" />
        </div>

        <div class="form-group flex justify-content-center w-full mt-4 mb-2">
            <button-prime type="button" class="add-program-popup" label="Filter" @click="this.get_reward_analysis_report_data(); rewardReportFilterModalVisible = false;"></button-prime>
        </div>
    </dialog-prime>
    <!-- / Filter Report Modal -->
  
</template>
  
<script setup>
import { ref } from "vue";
const rewardReportFilterModalVisible = ref(false);


// const selectedProgramType = ref('5 Stamps');
// const programTypes = ref(['5 Stamps', '100 Points']);

// const selectedRewardCategory = ref('All');
// const rewardCategory = ref(['All', 'Free Item', 'BOGO Deal', 'Discount']);
</script>
  
<script>
import sidebarNav from '../../sharedViews/sidebar/sidebarNav.vue';
import axios from 'axios'
export default {
    components: {
        sidebarNav,
    },
    data() {
        return {
            reward_types : [],
            rewards_list : [],
            rewards_list_changed : [],
            filter_reward_types : [],
            filter_reward : null,
            filter_reward_type : { id : 0, name: "All", arabic_name: null },
            reward_report_data : [],
            descriptive_results : [],
            descriptive_results_rows_per_page : 10,
            descriptive_results_current_page : 0,
            chartData: null,
            chartOptions: null,

            freeItemChartData: null,
            freeItemChartOptions: null,

            bogoDealsChartData: null,
            bogoDealsChartOptions: null,

            pieChartData: null,
            pieChartOptions: null,

            currentYear: new Date().getFullYear(), // Initialize with the current year
            currentMonth: new Date().getMonth(), // Initialize with the current month
            monthNames: [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ],
        }
    },
    mounted(){
        
        this.get_reward_types();
        this.get_reward_analysis_report_data();
        this.get_merchant_reward_list();
        
    },
    computed: {
        paginated_descriptive_results() {
        if (!this.descriptive_results) return [];
        const start = this.descriptive_results_current_page * this.descriptive_results_rows_per_page;
        const end = start + this.descriptive_results_rows_per_page;
        return this.descriptive_results.slice(start, end);
        },
    },
    methods: {
        select_reward_type(event) {
            console.log('Option selected:', event.value, this.filter_reward_type);
            console.log('this.filter_reward_type', this.filter_reward_type);
            if (this.filter_reward_type != null) {
                console.log('hello')
                if (this.filter_reward_type.id == 0) {
                    this.rewards_list_changed = this.rewards_list;
                } else {
                    this.rewards_list_changed = this.rewards_list.filter(reward => reward.reward_type_id == this.filter_reward_type.id);
                }
                
            }
            
        },
        on_descriptive_results_page_change(event) {
            this.descriptive_results_current_page = event.page;
        },
        get_reward_types() {
            axios.post(this.api_url+'get-reward-types').then(response => {
                this.reward_types = response.data.data;

                this.filter_reward_types = [...this.reward_types];
                this.filter_reward_types.unshift({ id: 0, name: "All", arabic_name: null });

            
            });
        },
        get_reward_analysis_report_data() {
            axios.post(this.api_url+'merchant/reward-analysis-report',
            {
                year : this.currentYear,
                month : this.currentMonth+1,
                filter_reward_type : this.filter_reward_type == null ? 0 : this.filter_reward_type.id
            },
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
            }).then(response => {
            
                
                this.reward_report_data = response.data.data.chart_data;
                this.descriptive_results = response.data.data.descriptive_results;
                
                
               
            });
        },
        get_merchant_reward_list() {
            axios.post(this.api_url+'merchant/get-all-rewards-for-merchant',
            {
            },
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
            }).then(response => {
            
                this.rewards_list = response.data.data;
                this.rewards_list.unshift({ id: 0, name: "All", reward_type_id: 0 });

                this.rewards_list_changed = [...this.rewards_list];
                
            });
        },
        prevMonth() {
            if (this.currentMonth === 0) {
                this.currentMonth = 11; // Go to December
                this.currentYear -= 1; // Decrease year
            } else {
                this.currentMonth -= 1; // Previous month
            }
            this.get_reward_analysis_report_data();
        },
        nextMonth() {
            if (this.currentMonth === 11) {
                this.currentMonth = 0; // Go to January
                this.currentYear += 1; // Increase year
            } else {
                this.currentMonth += 1; // Next month
            }
            this.get_reward_analysis_report_data();
        },

        setChartData(reportItem) {
            const weekLabels = Object.keys(reportItem.weeks); // ['week 1', 'week 2', 'week 3', 'week 4', 'week 5']
            const discountValues = weekLabels.map(week => reportItem.weeks[week].total_value); // Extract total_value for each week

            return {
                labels: weekLabels, // Set labels dynamically from week keys
                datasets: [
                    {
                        label: 'Given Discount Amount',
                        data: discountValues, // Set data dynamically from total_value
                        backgroundColor: '#1ABBAC',
                        borderColor: '#1ABBAC',
                        borderWidth: 0,
                        borderRadius: 50,
                    },
                ]
            };
        },

        // Bar Chart Options
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--p-text-color');

            return {
                plugins: {
                    legend: {
                        labels: {
                            color: textColor,
                            padding: 25,
                            font: {
                                size: 13,
                                family: '"Montserrat", sans-serif'
                            },
                            boxWidth: 11,
                            boxHeight: 11,
                            borderRadius: 3,
                            useBorderRadius: 3,
                        },
                        position: 'bottom',
                    }
                },
                scales: {
                    x: {
                        border: {
                            display: false
                        },
                        ticks: {
                            color: 'textColorSecondary',
                            font: {
                                size: 12,
                                family: '"Montserrat", sans-serif'
                            },
                            padding: 10,
                            backdropPadding: 0,
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        border: {
                            display: false
                        },
                        beginAtZero: true,
                        ticks: {
                            display: false,
                        },
                        grid: {
                            display: false
                        }
                    }
                }
            };
        },

        // Pie Chart Data
        setPieChartData(reportItem) {
            const weekLabels = Object.keys(reportItem.weeks); // ['week 1', 'week 2', 'week 3', 'week 4', 'week 5']
            const totalCounts = weekLabels.map(week => reportItem.weeks[week].total_count); // Extract total_count for each week

            return {
                labels: weekLabels, // Set labels dynamically from week keys
                datasets: [
                    {
                        data: totalCounts, // Set data dynamically from total_count
                        backgroundColor: ['#0089B5', '#33A1C4', '#66B8D3', '#99D0E1', '#CCE7F0'],
                        hoverBackgroundColor: ['#0089B5b8', '#33A1C4b8', '#66B8D3b8', '#99D0E1b8', '#CCE7F0b8'],
                    }
                ],
            };

        },

        // Pie Chart Options
        setPieChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--p-text-color');

            return {
                plugins: {
                    legend: {
                        labels: {
                            color: textColor,
                            padding: 16,
                            font: {
                                size: 13,
                                family: '"Montserrat", sans-serif'
                            },
                            boxWidth: 15,
                            boxHeight: 15,
                            borderRadius: 3,
                            useBorderRadius: 3,
                        },
                        position: 'right',
                    }
                }
            };
        }

        
    },
    watch: {

    },
}
</script>
