<template>
    <div class="program-listing-inner">
      <h2 class="listing-title">Programs</h2>

      <div class="listing-item-container"  v-if="programs==null">
        <div class="listing-box-loaders" v-for="index in 3" :key="index">
            <div class="flex justify-between mt-3 mb-3">
                <Skeleton-prime class="border-1000 mr-2" width="6rem" height="2rem"></Skeleton-prime>
            </div>
            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
            <Skeleton-prime class="border-1000 mb-3" width="100%" height="1rem"></Skeleton-prime>
            <Skeleton-prime class="border-1000" width="100%" height="1rem"></Skeleton-prime>
        </div>
      </div>

      <div class="not-found-data" v-if="programs!=null && programs.length==0">
        <img src="../../assets/images/no-program.svg">
        <h2>No program available</h2>
      </div>

      <div v-if="programs!=null && programs.length>0" class="listing-item-container">
        <div v-for="program in programs" :key="program.id" class="listing-item-box">
          <div class="flex-list-item">
            <span class="type-badge-program">
              {{ program.program_type.name }} based
            </span>

            <div class="created-date-action-btn">
              <span>Created</span> <span>{{ program.created_at }}</span>
            </div>
          </div>

          <div class="flex-list-item">
            <span class="flex-list-text flex-text-grey">
              Stamps on each scan 
            </span>

            <span class="flex-list-text">
              {{ program.no_of_stamps }}
            </span>
          </div>

          <div class="flex-list-item">
            <span class="flex-list-text flex-text-grey">
              No of Rewards 
            </span>

            <span class="flex-list-text">
              {{ program.rewards.length }}
            </span>
          </div>

          <div class="flex-list-item new-reward-btn">
            <!-- <button-prime class="new-reward" label="New Reward" icon="pi pi-plus" @click="$emit('show_add_reward_screen', 1);" /> -->
            <router-link :to="'/add-program-reward/'+program.id">
             <button-prime class="new-reward" label="New Reward" icon="pi pi-plus" />
            </router-link>
          </div>
        </div>

        
        
      </div>
    </div>

</template>

<script>

// import axios from 'axios'
// import moment from 'moment'
export default {
  props: {
    programs: {
      type: [Array, null],
      default: null
    },
  },
  data() {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    
  } 
}
</script>

<style>

</style>
  