<template>
    <div class="grid m-0 mt-0">
        <div class="col-12 md:col-6 lg:col-6 ps-0 align-items-center flex">
            <button-prime class="back-offer-listing-btn" label="Cancel" @click="$emit('hide_offer_detail', 0)">
                <i class="pi pi-arrow-left"></i>
            </button-prime>
        </div>

        <div class="col-12 md:col-6 lg:col-6 ps-0 gap-2 flex justify-content-end">
            <router-link :to="'offers/edit/'+offer_details.id">
            <button-prime class="edit-offer-btn" label="Edit" />
            </router-link>
        </div>
    </div>

    <div class="grid m-0 mt-3">
        <div class="col-12 md:col-6 lg:col-6 ps-0">
            <div class="card-border-box offer-detail-card">
                <div class="listing-item-box p-0 border-transparent">
                        <div class="flex-list-item offer-img-area" v-bind:style="{'background-image': 'url('+offer_details.offer_image+')'}"></div>

                        <div class="flex-list-item">
                            <span class="offer-title-name">
                            {{ offer_details.name }}
                            </span>
                        </div>
                        
                        <div class="flex-list-item justify-content-start gap-2">
                            <span class="type-badge-program offer-badge-green">
                                {{ offer_details.offer_days[0].start_time }} to {{ offer_details.offer_days[0].end_time }}
                            </span>
                        </div>

                        <div class="hr-line">
                            <hr />
                        </div>

                        <div class="flex-list-item">
                            <span class="flex-list-text flex-text-grey">
                            Redeems per user
                            </span>

                            <span class="flex-list-text">
                                {{ offer_details.allow_per_user }}
                            </span>
                        </div>

                        <div class="flex-list-item">
                            <span class="flex-list-text flex-text-grey">
                            Total Redeems
                            </span>

                            <span class="flex-list-text">
                                -
                            </span>
                        </div>
                        
                        <div class="flex-list-item">
                            <span class="flex-list-text flex-text-grey">
                                Expires
                            </span>

                            <span class="flex-list-text">
                                {{ offer_details.expiry_date }}
                            </span>
                        </div>
                    </div>
            </div>
        </div>

        <div class="col-12 md:col-6 lg:col-6 pe-0">
            <div class="card-border-box offer-detail-card">
                <h2>Offer Details</h2>
                
                <div class="detail-list" v-html="offer_details.description">
                    
                </div>

                <h2>Terms & Conditions</h2>
                
                <div class="detail-list" v-html="offer_details.terms_and_condition">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// import { ref } from "vue";

</script>
  
<script>
export default {
    props: {
        offer_details: Array,
    },
    data() {
        return {

        }
    },
    mounted() {
        
    },
}
</script>

<style>

</style>
  