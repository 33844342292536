<template>

    <sidebarNav />
  
    <div class="page-header">
      <div class="header-left-area">
        <h1 class="page-title m-0">Reports</h1>
      </div>
      
      <div class="header-right-area">
      </div>
    </div>
  
    <div class="app-content content">
        <div class="container-fuild app-inner-container offers-analysis-statistics-container">
            <div class="offer-analysis-inner">
                <div class="inner-lsiting-header">
                    <div class="back-to-report">
                        <router-link to="/reports">
                            <i class="pi pi-arrow-left"></i>
                        </router-link>
                        <span>Offers Analysis</span>
                    </div>

                   
                    <div class="reports-filter-container offers-reports-filter-container">
                       
                        <div class="form-fields-main filter-MWY-dropdown monthFilter mb-0">
                            <button-prime icon="pi pi-chevron-left" @click="prevMonth" />
                                <span class="monthYear-label">{{ monthNames[currentMonth] }} {{ currentYear }}</span>
                            <button-prime icon="pi pi-chevron-right" @click="nextMonth" />
                        </div>

                        <button-prime class="offer-filter-btn" label="Filter by" icon="pi pi-sliders-h" @click="offerReportFilterModalVisible = true" />
                    </div>
                </div>

                <div class="reports-statistics-charts-container">
                    <h1 class="reports-statistics-title">Overall Results</h1>

                    <div class="grid m-0">
                        <div class="col-12 md:col-12 lg:col-12">
                            <div class="charts-title-label-info">
                                <h2>Offers Redeem Results</h2>
                                <p>
                                    <i class="pi pi-arrow-up"></i>
                                    {{  offer_report_data.message_status }}
                                </p>
                            </div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-6">
                            <div class="charts-info-numbers">
                                <div class="info-number-item">
                                    900
                                </div>
                                <div class="info-number-item">
                                    15,000 <span>AED</span>
                                </div>
                                <div class="info-number-item">
                                    500
                                </div>
                            </div>

                            <div class="charts-container">
                                <Chart-prime type="bar" :data="chartData" :options="chartOptions" />
                            </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6">
                            <h2 class="chart-title-text">Offers Redeemed based on Time</h2>
                            <div class="pie-charts-container">
                                <Chart-prime type="pie" :data="pieChartData" :options="pieChartOptions" class="w-full" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fuild app-inner-container offers-analysis-container">
            <div class="offer-analysis-inner">
                <div class="analysis-listing-container mt-0">
                    <h2 class="reports-analysis-listing-title">Descriptive Results</h2>

                    <div class="reports-analysis-table-listing offer-analysis-reports-table-listing">
                        <table class="analysis-report-table">
                            <thead>
                                <tr>
                                    <th>
                                        <span>
                                            Date
                                        </span>
                                    </th>
                                    <th>
                                        <span>
                                            Offers
                                        </span>
                                    </th>
                                    <th>
                                        <span>
                                            Items Worth
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="descriptive_results.length>0">
                                <tr v-for="item in descriptive_results" :key="item">
                                    <td>
                                        <span>{{ moment(item.date).format('MM/DD/YYYY')}}</span>
                                    </td>
                                    <td class="offer-badge-table-data">
                                        <div class="redeem-activity-badges" v-for="offer in item.offers" :key="offer">
                                            <Chip-prime class="chip-black" :label="offer.name" image="" />
                                            <span>X{{ offer.offer_count }}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="worth-span">{{ item.items_worth }} AED</span>
                                    </td>
                                </tr>
                                
                            </tbody>
                            <tbody v-else>
                                <tr colspan="3">
                                    <span>No records found!</span>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="pagination-container">
                        <paginator-prime v-if="descriptive_results.length>10" :rows="descriptive_results_rows_per_page" :totalRecords="descriptive_results.length" :page="descriptive_results_current_page" @page="on_descriptive_results_page_change"></paginator-prime>
                    </div>

                    <!-- <div class="w-full flex justify-content-end">
                        <button-prime class="wafa-primary-btn pl-3 pr-3" label="Download Complete Report" />
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <!-- Filter Report Modal -->
    <dialog-prime class="fields-option-modal" v-model:visible="offerReportFilterModalVisible" modal header="Filter Results" :style="{ width: '25rem' }">
        <div class="form-group">
            <label class="form-label">Select Offer Time</label>
            <selectButton-prime v-model="selected_time_slot" optionLabel="name" :options="time_slots" aria-labelledby="basic" />
        </div>
        
        <!-- <div class="form-group">
            <label class="form-label">Select Specific offer</label>
            <select-prime v-model="selectedspecificOffer" :options="specificOffer" optionLabel="name" placeholder="Select" class="w-full" />
        </div> -->

        <div class="form-group flex justify-content-center w-full mt-4 mb-2">
            <button-prime type="button" class="add-program-popup" label="Filter" @click="get_offer_analysis_report_data(); offerReportFilterModalVisible = false;"></button-prime>
        </div>
    </dialog-prime>
    <!-- / Filter Report Modal -->
  
</template>
  
<script setup>
import { ref } from "vue";
const offerReportFilterModalVisible = ref(false);

// const selectedspecificOffer = ref();
// const specificOffer = ref([
//     { name: 'All'},
//     { name: '20% off on order only'},
// ]);



</script>
  
<script>
  import sidebarNav from '../../sharedViews/sidebar/sidebarNav.vue';
  import axios from 'axios'
  import moment from 'moment'

  export default {
    components: {
        sidebarNav,
    },
    data() {
      return {
        offer_report_data : [],
        descriptive_results : [],
        descriptive_results_rows_per_page : 10,
        descriptive_results_current_page : 0,
        chartData: null,
        chartOptions: null,
        pieChartData: null,
        pieChartOptions: null,
        currentYear: new Date().getFullYear(), // Initialize with the current year
        currentMonth: new Date().getMonth(), // Initialize with the current month
        monthNames: [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ],
        selected_time_slot : null,
        time_slots: [
            
            {
                name: 'Morning',
               
            },
            {
                name: 'Afternoon',
               
            },
            {
                name: 'Evening',
               
            },
            {
                name: 'Night',
               
            }
            
        ],

      }
    },
    mounted(){
        this.get_offer_analysis_report_data();
    },
    computed: {
        paginated_descriptive_results() {
        if (!this.descriptive_results) return [];
        const start = this.descriptive_results_current_page * this.descriptive_results_rows_per_page;
        const end = start + this.descriptive_results_rows_per_page;
        return this.descriptive_results.slice(start, end);
        },
    },
    methods: {
        on_descriptive_results_page_change(event) {
            this.descriptive_results_current_page = event.page;
        },
        formatDateTime(timestamp) {
            return new Date(timestamp).toLocaleString();  // Convert to readable date-time
        },
        get_offer_analysis_report_data() {
            axios.post(this.api_url+'merchant/offer-analysis-report',
            {
                year : this.currentYear,
                month : this.currentMonth+1,
                time_slot : this.selected_time_slot
            },
            {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
            }
            }).then(response => {
                this.offer_report_data = response.data.data.chart_data;
                this.descriptive_results = response.data.data.descriptive_results;
                this.chartData = this.setChartData();
                this.chartOptions = this.setChartOptions();
                this.pieChartData = this.setPieChartData();
                this.pieChartOptions = this.setPieChartOptions();
            });
        },
        prevMonth() {
            if (this.currentMonth === 0) {
                this.currentMonth = 11; // Go to December
                this.currentYear -= 1; // Decrease year
            } else {
                this.currentMonth -= 1; // Previous month
            }
            this.get_offer_analysis_report_data();
        },
        nextMonth() {
            if (this.currentMonth === 11) {
                this.currentMonth = 0; // Go to January
                this.currentYear += 1; // Increase year
            } else {
                this.currentMonth += 1; // Next month
            }
            this.get_offer_analysis_report_data();
        },
        
        setChartData() {
            const weekLabels = Object.keys(this.offer_report_data.bar); 
            const total_sum = weekLabels.map(week => this.offer_report_data.bar[week].total_value);
            const total_count = weekLabels.map(week => this.offer_report_data.bar[week].total_count);
            
            return {
                
                labels: weekLabels,
                datasets: [
                    {
                        label: 'No of Offers Redeemed',
                        data: total_count,
                        backgroundColor: '#C5E474',
                        borderColor: '#C5E474',
                        borderWidth: 0,
                        borderRadius: 50,
                    },
                    {
                        label: 'Investment on behalf of merchant',
                        data: total_sum,
                        backgroundColor: '#1ABBAC',
                        borderColor: '#1ABBAC',
                        borderWidth: 0,
                        borderRadius: 50,
                    },
                    // {
                    //     label: 'Unique Customers who redeemed offers',
                    //     data: [200, 350, 340, 358, 300],
                    //     backgroundColor: '#0089B5',
                    //     borderColor: '#0089B5',
                    //     borderWidth: 0,
                    //     borderRadius: 50,
                    // }
                ]
            }
        },

        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--p-text-color');

            return {
                plugins: {
                    legend: {
                        labels: {
                            color: textColor,
                            padding: 25,
                            font: {
                                size: 13,
                                family: '"Montserrat", sans-serif'
                            },
                            boxWidth: 11,
                            boxHeight: 11,
                            borderRadius: 3,
                            useBorderRadius: 3,
                        },
                        position: 'bottom',
                    }
                },
                scales: {
                    x: {
                        border: {
                            display: false
                        },
                        ticks: {
                            color: 'textColorSecondary',
                            font: {
                                size: 12,
                                family: '"Montserrat", sans-serif'
                            },
                            padding: 10,
                            backdropPadding: 0,
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        border: {
                            display: false
                        },
                        beginAtZero: true,
                        ticks: {
                            display: false,
                        },
                        grid: {
                            display: false
                        }
                    }
                }
            };
        },

        // Pie Chart 
        setPieChartData() {
            const weekLabels = Object.keys(this.offer_report_data.pie); 
            const total_count = weekLabels.map(slot => this.offer_report_data.pie[slot].total_count);
            
            return {
                labels: weekLabels,
                datasets: [
                    {
                        data: total_count,
                        backgroundColor: ['#2FD3F7', '#FEC020', '#01042D', '#283e87'],
                        hoverBackgroundColor: ['#2FD3F7b8', '#FEC020b8', '#01042Db8', '#667296']
                    }
                ]
            };
        },

        setPieChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--p-text-color');

            return {
                plugins: {
                    legend: {
                        labels: {
                            color: textColor,
                            padding: 16,
                            font: {
                                size: 13,
                                family: '"Montserrat", sans-serif'
                            },
                            boxWidth: 15,
                            boxHeight: 15,
                            borderRadius: 3,
                            useBorderRadius: 3,
                        },
                        position: 'right',
                    }
                }
            }
        },
    },
  }
</script>
