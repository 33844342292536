<template>
    <header class="header-container">
        <div class="container">
            <div class="grid header-grid m-0">
                <div class="header-left">
                    <router-link to="/">
                        <div class="logo">
                            <img class="site-logo-spaceDesign" src="../../assets/images/logo/wafa-logo.svg">
                        </div>
                    </router-link>

                    <div class="header-left-menu">
                      <ul class="header-left-menuUl header-left-menuUlFirst">
                        <router-link to="/">
                          <li class="header-left-menuItems header-left-menuItemsServices">
                              <p> About Wafa </p>
                          </li>
                        </router-link>
                        
                        <router-link to="/become-a-merchant">
                          <li class="header-left-menuItems header-left-menuItemsServices">
                              <p> Become a Merchant </p>
                          </li>
                        </router-link>

                        <router-link to="/subscription">
                          <li class="header-left-menuItems header-left-menuItemsServices">
                              <p> Merchant Subscriptions </p>
                          </li>
                        </router-link>
                      </ul>
                    </div>
                </div>

                <div class="header-right header-right-desktop">
                    <ul class="header-left-menuUl header-left-menuUlBtn">
                        <router-link to="/signin">
                          <li class="header-left-menuItems header-left-menuItemsServices header-btn login-btn">
                              <p class="header-btn-label"> Login </p>
                          </li>
                        </router-link>
                        
                        <router-link to="/register">
                            <li class="header-left-menuItems header-left-menuItemsServices header-btn register-btn">
                                <p class="header-btn-label"> Register Now </p>
                            </li>
                        </router-link>
                    </ul>

                    <ul class="header-left-menuUl mobile-bar-icon">
                        <div class="bars-icon-header mobile-bar-icon" @click="mobileSidebar = true">
                            <i class="pi pi-bars" style="font-size: 1rem"></i>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </header>

    <Drawer-prime v-model:visible="mobileSidebar" class="mobile-sidebar" header="" position="right">
        <div class="sidebar-container">
                <div class="grid header-grid m-0">
                    <div class="header-left">
                        <div>
                            <router-link to="/">
                                <div class="logo">
                                    <img class="site-logo-mobile" src="../../assets/images/logo/wafa-logo.svg">
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="mobile-items">
                    <ul class="header-item-menuUl">
                        <router-link to="/">
                            <li class="header-menuItems-li">
                                <p>About Wafa</p>
                            </li>
                        </router-link>

                        <router-link to="/become-a-merchant">
                            <li class="header-menuItems-li">
                                <p>Become a Merchant</p>
                            </li>
                        </router-link>

                        <router-link to="/subscription">
                            <li class="header-menuItems-li">
                                <p>Merchant Subscriptions</p>
                            </li>
                        </router-link>
                    </ul>
                </div>

                <!-- <div class="footer-social-icon">
                    <a href="#">
                        <img class="" src="../../assets/images/facebook.svg">
                    </a>
                    <a href="#">
                        <img class="" src="../../assets/images/instagram.svg">
                    </a>
                    <a href="#">
                        <img class="" src="../../assets/images/x.svg">
                    </a>
                    <a href="#">
                        <img class="" src="../../assets/images/linkedin.svg">
                    </a>
                </div> -->
            </div>
    </Drawer-prime>
</template>

<script>
export default {
    components: {
    },
    data() {
      return {
        mobileSidebar: false,
      }
    },
    methods : {
      
    }
}
</script>

<style scoped src="../../assets/css/home.css">

</style>