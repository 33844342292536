<template>
  <footer class="footer-section">
    <div class="container">
      <div class="footer-content pt-5 pb-5">
        <div class="grid m-0">

          <!-- <div class="col-12 md:col-12 lg:col-12 mb-50">
            <div class="footer-widget">
              <div class="footer-logo">
                <a>
                  <img class="site-logo-spaceDesign" src="../../assets/images/logo/logo.png">
                </a>
              </div>
            </div>
          </div> -->

          <div class="col-12 md:col-5 lg:col-5 mb-50">
            <div class="footer-widget">
              <div class="footer-text">
                <div class="footer-widget">
                  <div class="footer-logo">
                    <a>
                      <img class="site-logo-spaceDesign" src="../../assets/images/wafa-footer-logo.svg">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-3 lg:col-3 mb-30 footer-list-item-pt">
            <div class="footer-widget pt-4">
              <ul>
                <li>
                  <router-link to="/">
                    Join Community
                  </router-link>
                </li>
                <li>
                  <router-link to="/">
                    FAQ
                  </router-link>
                </li>
                <li>
                  <router-link to="/terms-and-conditions">
                    Terms & Conditions
                  </router-link>
                </li>
                <li>
                  <router-link to="/privacy-policy">
                    Privacy Policy
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-12 md:col-4 lg:col-4 mb-30 footer-list-item-pt">
            <div class="footer-widget">
              <div class="footer-widget-heading">
                <h3>Apply Now on Wafa</h3>
                <p>Elevate your Business</p>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-12 lg:col-12 mb-50 mt-3 copyright-footer-section">
            <p class="copyright-text">Copyright © 2024 Wafa. All rights reserved.</p>
            <div class="footer-widget social-widget-footer">
              <div class="footer-social-icon">
                <a href="#">
                  <img class="" src="../../assets/images/facebook.svg">
                </a>
                <a href="#">
                  <img class="" src="../../assets/images/instagram.svg">
                </a>
                <a href="#">
                  <img class="" src="../../assets/images/x.svg">
                </a>
                <a href="#">
                  <img class="" src="../../assets/images/linkedin.svg">
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </footer>
</template>

<style>
.footer-section {
  min-height: 300px;
  position: relative;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #01042D;
}

.footer-help-box {
  background: #FFAC00;
  min-height: 170px;
  width: 450px;
  border-radius: 45px;
  margin: auto;
  margin-top: -120px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.1rem;
}

ul {
  margin: 0px;
  padding: 0px;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #fff;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #fff;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo img {
  max-width: 150px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #fff;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon {
    margin-top: 0.6rem;
    display: inline-flex;
    column-gap: 16px;
}

.footer-social-icon a {
  color: #000;
  font-size: 16px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.footer-social-icon a img {
    width: 25px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3B5998;
}

.twitter-bg {
  background: #55ACEE;
}

.google-bg {
  background: #DD4B39;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 14px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #000;
  display: none;
}

.footer-widget ul li {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  font-size: 14px;
}

.footer-widget ul li a:hover {
  color: #1ABBAC !important;
}

.footer-widget ul li a {
  color: #C4CDD5;
  text-transform: capitalize;
  background: transparent;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: #fff;
  padding: 13px 20px;
  border: 1px solid #fff;
  top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #202020;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}

.copyright-text p a {
  color: #fff;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #fff;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.app-download-icon-footer a img.site-logo-amaken {
  width: 160px;
}

.app-download-icon-footer {
  display: flex;
  align-items: center;
}

.footer-text {
  padding-right: 6rem;
}

.footer-section p {
  color: #C4CDD5;
  font-size: 14px;
}

.footer-widget-heading p {
    font-size: 14px;
    color: #C4CDD5;
}

.copyright-footer-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>