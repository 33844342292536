<template>

  <sidebarNav />

  <div class="page-header">
    <div class="header-left-area">
      <h1 class="page-title m-0">Settings</h1>
    </div>
    
    <div class="header-right-area">
      <h2 class="haeder-user-name">
        Azka Mazza
      </h2>
      <div class="user-profile-img-circle">
        <img src="../../assets/images/default.png">
      </div>
    </div>
  </div>

  <div class="app-content content">
    <div class="container-fuild listing-container users-setting-container">
      <div class="user-activity-inner mt-1">
 
          <Tabs-prime value="0">
              <TabList-prime class="account-info-tablist">
                  <Tab-prime class="account-information-tab" value="0">Account Information</Tab-prime>
                  <Tab-prime value="1">Subscriptions</Tab-prime>

                  <span class="user-header-btn">
                      <button-prime class="setting-save-btn" label="Save" :loading="setting.loading" @click="save_setting"/>
                  </span>
              </TabList-prime>

              <TabPanels-prime>
                  <TabPanel-prime value="0">
                      <div class="grid m-0 mt-3">
                          <div class="col-12 md:col-12 lg:col-12">
                            <div class="upload-user-profile-bg-container" v-if="setting.cover_image_url">
                              <input id="file-upload-cover" type="file" name="fileUpload1" @change="handleFileUpload($event, 'cover')" accept="image/*" />
                              <label for="file-upload-cover" id="file-drag">
                                <div class="upload-user-profile-bg" v-bind:style="{'background-image': 'url('+setting.cover_image_url+')'}">
                                  
                                </div>
                              </label>
                            </div>

                            <div v-else class="upload-user-profile-bg-container">
                              <input id="file-upload-cover" type="file" name="fileUpload1" @change="handleFileUpload($event, 'cover')" accept="image/*" />
                              <label for="file-upload-cover" id="file-drag">
                                <div class="upload-user-profile-bg">
                                  <p>Add Cover Picture here</p>
                                </div>
                              </label>
                            </div>
                            
                            <div class="upload-user-profile-img-container">
                              <input id="file-upload-logo" type="file" name="fileUpload2" @change="handleFileUpload($event, 'logo')" accept="image/*" />
                              <label for="file-upload-logo" id="file-drag">
                                <div class="upload-user-profile-img">
                                  <img :src="setting.logo_image_url" v-if="setting.logo_image_url" alt="Image Preview">
                                  <img src="../../assets/images/default.png" v-else>
                                  <i class="pi pi-camera"></i>
                                </div>
                              </label>
                            </div>

                        

                          </div>
                      </div>
                      
                      <div class="grid m-0 mt-3">
                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Brand Name</label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/name.svg">
                                    <inputText-prime v-model="setting.brand_name" class="form-input-fields" placeholder="Enter Brand Name" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Email Address</label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/sms.svg">
                                    <inputText-prime v-model="setting.email" class="form-input-fields" type="email" placeholder="Enter Your Email" />
                                </span>
                            </div>
                          </div>
                          
                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Phone Number</label>
                                <span class="p-input-icon-left">
                                    <vue-tel-input v-model="setting.phone" mode="international" placeholder="Write your mobile number"></vue-tel-input>
                                </span>
                            </div>
                          </div>
                          
                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Service Category</label>
                                <span class="p-input-icon-left">
                                  <img class="inputFields-icons" src="../../assets/images/settings-icons/category.svg">
                                  <multiSelect-prime v-model="setting.selected_merchant_categories" :options="merchant_categories" optionLabel="name" filter placeholder="Select Categories" :maxSelectedLabels="5" class="w-full" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Location</label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/location.svg">
                                    <inputText-prime v-model="setting.address" class="form-input-fields" placeholder="Enter Branch Location here" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <label>Emirate</label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/location.svg">
                                    <select-prime v-model="setting.emirate" :options="emirates" optionLabel="name" placeholder="Select Emirate" class="form-input-fields w-full" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-12 lg:col-12">
                            <div class="form-fields-main mb-0">
                              <label>Embeded Map Html</label>
                              <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2990.274257380938!2d-70.56068388481569!3d41.45496659976631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e52963ac45bbcb%3A0xf05e8d125e82af10!2sDos%20Mas!5e0!3m2!1sen!2sus!4v1671220374408!5m2!1sen!2sus" width="100%" height="330" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
                              <textarea-prime v-model="setting.embeded_map_html" autoResize rows="12" cols="30" class="form-input-fields" />
                            </div>
                          </div>
                      </div>

                      <div class="grid m-0 mt-0">
                          <div class="col-12 md:col-12 lg:col-12">
                            <h2 class="user-setting-title">Change Password</h2>
                          </div>
                          
                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/Lock.svg">
                                    <password-prime v-model="password.old_password" class="form-input-fields" toggleMask :feedback="false" placeholder="Enter old password" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/Lock.svg">
                                    <password-prime v-model="password.new_password" class="form-input-fields" toggleMask :feedback="false" placeholder="Enter new password" />
                                </span>
                            </div>
                          </div>

                          <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main mb-0">
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/settings-icons/Lock.svg">
                                    <password-prime v-model="password.new_password_confirmation" class="form-input-fields" toggleMask :feedback="false" placeholder="Confirm new password" />
                                </span>
                            </div>
                          </div>
                          
                          <div class="col-12 md:col-12 lg:col-12">
                            <button-prime :loading="password.loading" @click="change_password" class="wafa-primary-btn change-password-btn" label="Change Password" />
                          </div>
                      </div>
                  </TabPanel-prime>

                  <TabPanel-prime value="1">
                    <div class="pricing-info-container">
                      <h2 class="pricing-info-title">Simple Plans, <span>Free</span> To Try</h2>
                      <p class="pricing-info-label">Get 3 months <span>Free Trial</span> on all Plans</p>

                      <div class="grid group pricing-container">
                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                              <div class="pricing-icon-box">
                                <img class="" src="../../assets/images/pricing-icons/free.svg">
                              </div>
                              <h2>WAFA FREEMIUM</h2>
                              <h3>
                                0<span class="small">AED</span>
                              </h3>
                              <p>/month</p>
                              <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                  No. of Offers
                                  <p class="m-0">1</p>
                                </li>
                                <li>
                                  No. of Programs
                                  <i class="pi pi-times-circle"></i>
                                </li>
                                <li>
                                  No. of Branches
                                  <p class="m-0">1</p>
                                </li>
                                <li>
                                  Analytical Dashboard
                                  <i class="pi pi-times-circle"></i>
                                </li>
                                <li>
                                  Customer Data Platform
                                  <i class="pi pi-times-circle"></i>
                                </li>
                              </ul>
                              
                              <div class="pricing-btn"></div>

                              <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>

                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                              <div class="pricing-icon-box">
                                <img class="" src="../../assets/images/pricing-icons/free-1.svg">
                              </div>
                              <h2>WAFA BASIC</h2>
                              <h3>
                                99<span class="small">AED</span>
                              </h3>
                              <p>/month</p>
                              <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                  No. of Offers
                                  <p class="m-0">2</p>
                                </li>
                                <li>
                                  No. of Programs
                                  <p class="m-0">1</p>
                                </li>
                                <li>
                                  No. of Branches
                                  <p class="m-0">1</p>
                                </li>
                                <li>
                                  Analytical Dashboard
                                  <i class="pi pi-times-circle"></i>
                                </li>
                                <li>
                                  Customer Data Platform
                                  <i class="pi pi-times-circle"></i>
                                </li>
                              </ul>
                              
                              <div class="pricing-btn">
                                <button-prime class="wafa-primary-outline-btn" label="Get Started" />
                              </div>

                              <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>

                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                              <div class="pricing-icon-box">
                                <img class="" src="../../assets/images/pricing-icons/free-2.svg">
                              </div>
                              <h2>WAFA PLUS</h2>
                              <h3>
                                199<span class="small">AED</span>
                              </h3>
                              <p>/month</p>
                              <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                  No. of Offers
                                  <p class="m-0">5</p>
                                </li>
                                <li>
                                  No. of Programs
                                  <p class="m-0">2</p>
                                </li>
                                <li>
                                  No. of Branches
                                  <p class="m-0">5</p>
                                </li>
                                <li>
                                  Analytical Dashboard
                                  <i class="pi pi-plus-circle"></i>
                                </li>
                                <li>
                                  Customer Data Platform
                                  <i class="pi pi-plus-circle"></i>
                                </li>
                              </ul>
                              
                              <div class="pricing-btn">
                                <button-prime class="wafa-primary-outline-btn" label="Get Started" />
                              </div>

                              <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>

                        <div class="grid-1-5 col-12 md:col-3 lg:col-3">
                            <div class="pricing-inner-area">
                              <div class="pricing-icon-box">
                                <img class="" src="../../assets/images/pricing-icons/free-3.svg">
                              </div>
                              <h2>WAFA ENTERPRISE</h2>
                              <h3>
                                399<span class="small">AED</span>
                              </h3>
                              <p>/month</p>
                              <ul>
                                <!-- <li>Annual Billing</li> -->
                                <li>
                                  No. of Offers
                                  <p class="m-0">Unlimited</p>
                                </li>
                                <li>
                                  No. of Programs
                                  <p class="m-0">Unlimited</p>
                                </li>
                                <li>
                                  No. of Branches
                                  <p class="m-0">Unlimited</p>
                                </li>
                                <li>
                                  Analytical Dashboard
                                  <i class="pi pi-plus-circle"></i>
                                </li>
                                <li>
                                  Customer Data Platform
                                  <i class="pi pi-plus-circle"></i>
                                </li>
                              </ul>
                              
                              <div class="pricing-btn">
                                <button-prime class="wafa-primary-outline-btn" label="Get Started" />
                              </div>

                              <p class="pricing-footer-text">Here come some simple terms and conditions for subscription</p>
                            </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel-prime>
              </TabPanels-prime>
          </Tabs-prime>
      </div>
    </div>
  </div>
  <toast-prime />
</template>


<script>
import sidebarNav from '../sharedViews/sharedView.vue';
import axios from 'axios'
// import moment from 'moment'

export default {
  components: {
      sidebarNav,
  },
  data() {
    return {
      signup_phone:"",
      emirates : [],
      merchant_categories : [],
      merchant : null,
      setting : {
        brand_name : null,
        email : null,
        phone : null,
        selected_merchant_categories : [],
        address : null,
        emirate : null,
        embeded_map_html : null,
        cover_image_url : null,
        cover_file_name : null,
        cover_file_size : null,
        cover_file : null,
        logo_image_url : null,
        logo_file_name : null,
        logo_file_size : null,
        logo_file : null,
        loading : false
      },
      password : {
        old_password : null,
        new_password : null,
        new_password_confirmation : null,
        loading : false
      }
    }
  },
  methods: {
    change_password() {
      this.password.loading = true;
      axios.post(this.api_url+'merchant/change-merchat-password', 
        this.password,
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token'),
            }
        }).then(response => {
         
          this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });

        }).catch(error => {
          if (error.response.status == 401) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.response.data.errors, life: 3000 });
              } 
        }).finally(() => {
          this.password.loading = false;
        });
    },
    get_merchant_details() {
      axios.post(this.api_url+'merchant/get-merchant-details', 
        {},
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token'),
            }
        }).then(response => {
          var data = response.data.data;
          this.setting.brand_name = data.brand_name;
          this.setting.email = data.email;
          this.setting.phone = data.phone;
          this.setting.address = data.address;
          this.setting.embeded_map_html = data.embeded_map_html;
          this.setting.emirate = this.emirates.find(item => item.id === data.city_id);
          this.setting.selected_merchant_categories = data.service_categories;

          var result = this.setting.selected_merchant_categories.map(item => {
              return this.merchant_categories.find(category => category.id === item.merchant_category_id);
          });

          this.setting.selected_merchant_categories = result;
          this.setting.cover_image_url = data.cover_photo;
          this.setting.logo_image_url = data.logo_photo;

        }).catch(error => {
            console.log(error);
        }).finally(() => {
          this.setting.loading = false;
        });
    },
    save_setting() {
      if (this.setting.brand_name == null) {
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Brand name required!', life: 3000 });
        return false;
      }
      if (this.setting.email == null) {
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Contact email required!', life: 3000 });
        return false;
      }
      if (this.setting.phone == null) {
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Contact phone required!', life: 3000 });
        return false;
      }
      if (this.setting.selected_merchant_categories.length == 0) {
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Service categories required!', life: 3000 });
        return false;
      }
      if (this.setting.address == null) {
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Address required!', life: 3000 });
        return false;
      }
      if (this.setting.emirate == null) {
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Emirate required!', life: 3000 });
        return false;
      }
      this.setting.loading = true;
      axios.post(this.api_url+'merchant/update-merchant', 
        {
          brand_name : this.setting.brand_name,
          email : this.setting.email,
          phone : this.setting.phone,
          emirate : this.setting.emirate.id, 
          address : this.setting.address,
          embeded_map_html : this.setting.embeded_map_html,
          cover : this.setting.cover_file,
          logo : this.setting.logo_file,
          categories : this.setting.selected_merchant_categories
        },
        {
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token'),
            'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });
        }).catch(error => {
            console.log(error);
        }).finally(() => {
          this.setting.loading = false;
        });
    },
    get_emirates() {
      axios.post(this.api_url+'get-emirates').then(response => {
        this.emirates = response.data.data;
      });
    },
    get_merchant_categories() {
      axios.post(this.api_url+'get-merchant-categories').then(response => {
        this.merchant_categories = response.data.data;
      });
    },
    handleFileUpload(event, type) {
      const file = event.target.files[0];
      if (file) {
        // Validate file type
        const validTypes = ['image/png', 'image/jpeg'];
        if (!validTypes.includes(file.type)) {
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Only PNG and JPG formats are allowed!', life: 3000 });
            return false;
        }

        // Validate file size (max 2MB)
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes
        if (file.size > maxSize) {
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'File size should not exceed 2MB!', life: 3000 });
            return false;
        }

        if (type == 'cover') {
          this.setting.cover_file_name = file.name; // Get the file name
          this.setting.cover_file_size = this.formatFileSize(file.size);// Get the file size
          this.setting.cover_file = file;
        } else if (type == 'logo') {
          this.setting.logo_file_name = file.name; // Get the file name
          this.setting.logo_file_size = this.formatFileSize(file.size);// Get the file size
          this.setting.logo_file = file;
        }
        

        const reader = new FileReader();
        console.log('type',type);
        reader.onload = (e) => {
          console.log('typqe',type);
          if (type == 'cover') {
            this.setting.cover_image_url = e.target.result;  
          } else if (type == 'logo') {
            this.setting.logo_image_url = e.target.result;
            console.log('this.setting.logo_image_url',this.setting.logo_image_url)
          }
        
        };
        reader.readAsDataURL(file);
      }
    },
    formatFileSize(size) {
      if (size < 1024) {
        return size + ' Bytes';
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + ' KB';
      } else {
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
      }
    },
  },
  mounted() {
    this.get_emirates();
    this.get_merchant_categories();
    this.get_merchant_details();
  } 
}
</script>

<style>

</style>
