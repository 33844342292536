<template>

    <sidebarNav />

    <div class="page-header">
      <div class="header-left-area">
        <h1 class="page-title m-0">Programs and Rewards</h1>
      </div>
      
      <div class="header-right-area">
        <button-prime class="new-program" label="New Program" icon="pi pi-plus" @click="add_program_modal = true" />
      </div>
    </div>

    <div class="app-content content">
      <div class="container-fuild listing-container">
        <!-- Programs listing -->
        <programlisting @show_add_reward_screen="showAddReward" :programs="programs" v-if="show_program_listing == 1" />
        <!-- / Programs listing -->

        <!-- Rewards listing -->
        <rewardlisting v-if="show_reward_listing == 1"/>
        <!-- / Rewards listing -->

         <!-- Add reward -->
         <addReward @hide_add_reward_screen="hideAddReward" v-if="show_add_reward == 1" />
        <!-- / Add reward -->

        <div class="upgrade-plan-message-box" @click="subscriptionVisible = true">
          <h2>Upgrade your Plan for more benefits</h2>
          <p>You only have one Reward Program, Upgrade Now to add more programs</p>

          <i class="pi pi-times"></i>
        </div>
      </div>
    </div>

    <!-- Add New Program Modal -->
    <dialog-prime class="fields-option-modal" v-model:visible="add_program_modal" modal header="New Program" :style="{ width: '25rem' }">
        <div class="form-group">
            <label class="form-label">Program Type</label>
            <select-prime v-model="program.program_type" :options="program_types" optionLabel="name" placeholder="Select" class="w-full" />
        </div>

        <div class="form-group">
            <label class="form-label">No of Stamps on each scan</label>
            <InputNumber-prime v-model="program.no_of_stamps" inputId="horizontal-buttons" :min="1" :max="1" showButtons buttonLayout="horizontal" fluid>
                <template #incrementbuttonicon>
                    <span class="pi pi-plus" />
                </template>
                <template #decrementbuttonicon>
                    <span class="pi pi-minus" />
                </template>
            </InputNumber-prime>
        </div>

        <div class="form-group flex justify-content-center w-full mt-4 mb-2">
            <button-prime type="button" class="add-program-popup" :loading="add_program_loader" label="Add Program" @click="create_program"></button-prime>
        </div>
    </dialog-prime>
    <!-- / Add New Program Modal -->

    <!-- Subscription Plan Modal -->
    <dialog-prime class="subscription-plan-modal" v-model:visible="subscriptionVisible" modal header="New Program" :style="{ width: '36rem' }">
        
      <div class="modal-body-container">
        <div class="subscription-icon">
          <img src="../../assets/images/subscriptionIcon.svg">
        </div>
        <div class="subscription-info">
           <h2>Upgrade your Subscription Plan</h2>
           <label>Get 3 months Free Trial</label>

           <p>Upgrade your subscription plan to add multiple programs and give more benefits</p>
        </div>
      </div>

      <div class="subscription-footer-btn w-full">
          <button-prime type="button" class="wafa-primary-outline-btn" label="Cancel" @click="subscriptionVisible = false"></button-prime>
          <button-prime type="button" class="wafa-primary-btn upgrade-btn" label="Upgrade"></button-prime>
      </div>
    </dialog-prime>
    <!-- / Subscription Plan Modal -->
    <toast-prime />
</template>



<script setup>
import { ref } from "vue";

const subscriptionVisible = ref(false);



</script>
  
<script>
import sidebarNav from '../sharedViews/sharedView.vue';
import programlisting from './program-listing.vue';
import rewardlisting from './reward-listing.vue';
import addReward from './addReward.vue'
import axios from 'axios'
import moment from 'moment'

export default {
  components: {
    sidebarNav,
    programlisting,
    rewardlisting,
    addReward
  },
  data() {
    return {
      show_program_listing: 1,
      show_reward_listing: 1,
      show_add_reward: 0,
      program_types : [],
      programs : null,
      program : {
        program_type : null,
        no_of_stamps : 1
      },
      add_program_loader : false,
      add_program_modal : false
    }
  },
  mounted() {
    this.get_program_types();
    this.get_programs();
  },
  methods: {
    get_programs() {
      axios.post(this.api_url+'merchant/get-programs',
        {},
        {
          headers: {
            'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
          }
        }).then(response => {
          this.programs = response.data.data;
          for (let i = 0; i < this.programs.length; i++) {
            this.programs[i].created_at = moment(this.programs[i].created_at).format("DD MMM YYYY")
          }
        });
    },
    create_program() {
      if (this.program.program_type == null) {
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Program type required!', life: 3000 });
        return false;
      }
      this.add_program_loader = true;
      axios.post(this.api_url+'merchant/create-program', 
      {
        program_type : this.program.program_type.id,
        no_of_stamps : this.program.no_of_stamps
      },
      {
        headers: {
          'Authorization': 'Bearer '+localStorage.getItem('wafa_auth_token')
        }
      }).then(response => {
        this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.data.message, life: 3000 });
        this.get_programs();
      }).catch(error => {
          console.log(error);
      }).finally(() => {
        this.add_program_loader = false;
      });

    },
    get_program_types() {
      axios.post(this.api_url+'get-program-types').then(response => {
        this.program_types = response.data.data;
      });
    },
    showAddReward(value){
      this.show_add_reward = value;
      this.show_program_listing = 0;
      this.show_reward_listing = 0;
    },

    hideAddReward(value){
      this.show_add_reward = value;
      this.show_program_listing = 1;
      this.show_reward_listing = 1;
    },
  } 
}
</script>

<style>

</style>
  